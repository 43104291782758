import React, {useEffect} from "react";
import TypographyTranslate from "../TypographyTranslate/TypographyTranslate";
import {Refresh} from "@material-ui/icons";
import {
    asyncConfirmationLink,
    reselectConfirmationCond
} from "../../controllers/confirmation-route/redux-store/confirmation-slicer/confirmation-slicer";
import __translate from "../../helper/locale/locale";
import {useDispatch, useSelector} from "react-redux";
import KDWSnackbar from "../KDWSnackbar/KDWSnackbar";
import {makeStyles} from "@material-ui/core";

const styles = makeStyles((t) => ({
    success: {
        display: "flex",
        marginLeft: -4,
        color: "#008A71",
        cursor: "pointer"},
    confirmError: {
        color: "#6F6F6F",
        '& span:first-child': {
            color: '#FABB06'
        }
    },
}));

const EmailConfirmError: React.FC<any> = ({...props}) => {

    const localClasses = styles();
    const dispatch = useDispatch();
    const condition = useSelector(reselectConfirmationCond)
    let t = null as any;
    const send = () => {
        if (condition.inProgress) return;
        if (t != null) clearTimeout(t);
        t = setTimeout(() => {
            dispatch(asyncConfirmationLink((r: any) => {
                props.onSend && props.onSend(r);
                if (!r.result) setError(r.data?.message || __translate("syatem.error", "Произошла ошибка"));
            }))
        }, 500);
    };
    useEffect(() => {
        if (props.send) send();
    }, [props.send]);
    const [error, setError] = React.useState(false as any);

    return (<>
        <KDWSnackbar isOpen={!!error} severity="error" handleOpen={setError} message={error}/>
        <div className={localClasses.confirmError}>
            <TypographyTranslate value="profile.email_not_confirmed" ruVal="Почта не подтверждена."/>{" "}
            <TypographyTranslate value={"profile.email_not_confirmed_desc" + (props.keySuf ? props.keySuf : "")}
                                 ruVal="Почта не подтверждена. Для завершения регистрации, пожалуйста, зайдите на указанную Вами электронную почту и подтвердите ее. "/>
        </div>
        {!props.hideResend && <div className={localClasses.success} id="success" onClick={send}>
            <Refresh/><TypographyTranslate value="profile.resend_email_link" ruVal="Отправить повторно"/>
        </div>}
    </>)
};
export default EmailConfirmError;
