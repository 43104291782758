import { configureStore, combineReducers } from "@reduxjs/toolkit";
import registration from "controllers/registration-route/redux-store/registration-slicer/registration-slicer";
import confirmation from "controllers/confirmation-route/redux-store/confirmation-slicer/confirmation-slicer";
import resetPassword from "controllers/reset-password-route/redux-store/reset-password/reset-password";
import constants from "controllers/constants-route/redux-store/constants/constants";
import profile from "controllers/profile-route/redux-store/profile/profile";
import profilePost from "controllers/profile-route-post/redux-store/profile-post/profile-post";
import token from "controllers/token/redux-store/token/token";
import tokenAdmin from "controllers/token/redux-store/token-admin/token-admin";
import tokenRevoke from "controllers/token-revoke/redux-store/token-revoke/token-revoke";
import geonamesCities from "controllers/geolocation-route/redux-store/cities/cities";
import geonamesCountries from "controllers/geolocation-route/redux-store/countries/countries";
import geonamesRegions from "controllers/geolocation-route/redux-store/regions/regions";
import geonamesSearchAll from "controllers/geolocation-route/redux-store/search-all/search-all";
import payInvoice from "controllers/invoice-route/redux-store/pay-invoice/pay-invoice";
import adminUsers from "controllers/admin-users/redux-store/admin-users/admin-users";
import adminDiagram from "controllers/admin-dashboard/store";
import adminCovidUsers from "controllers/admin-users/redux-store/admin-users/admin-covid-users";
import userPost from "controllers/admin-user-post/redux-store/admin-user-post/admin-user-post";
import userDelete from "controllers/admin-user-delete/redux-store/admin-user-delete/admin-user-delete";
import userGet from "controllers/admin-user-get/redux-store/admin-user-get/admin-user-get";
import userPut from "controllers/admin-user-put/redux-store/admin-user-put/admin-user-put";
import priceGet from "controllers/admin-price-get/redux-store/admin-price-get/admin-price-get";
import pricePost from "controllers/admin-price-post/redux-store/admin-price-post/admin-price-post";
import promoGet from "controllers/admin-promo-get/redux-store/admin-promo-get/admin-promo-get";
import promoPost from "controllers/admin-promo-post/redux-store/admin-promo-post/admin-promo-post";
import promoDelete from "controllers/admin-promo-delete/redux-store/admin-promo-delete/admin-promo-delete";
import reducer_Countries from "controllers/countries/countries";
import reducer_Cities from "controllers/cities/cities";
import frontContentLocale from "controllers/locale/redux-store/locale/locale";
import changeReportState from "controllers/admin-user-put/redux-store/admin-user-put/change-report-state";
import visits from "controllers/visits/redux-store/visits/visits";
import social from "controllers/social-auth";
import contacts from "../controllers/contacts/store";
import posts from "../controllers/posts/store";
import chats from "../controllers/chats/store";
import expo_reg from "../controllers/registration-expo-route/store";
import startups from "../controllers/startups/store";
import filter_Users from "controllers/admin-users/redux-store/filter-users/filter-users";
import reducer_Post_UserEvents from "controllers/event-controller/redux-store/user-event/user-event";
import reducer_UserEvents_DELETE from "controllers/event-controller/redux-store/user-event-delete/user-event-delete";
import reducer_UserEvents_GET from "controllers/event-controller/redux-store/user-events-GET/user-events-GET";
import CompaniesStore from 'controllers/companies/index';
import employees from 'controllers/employees/employee-redux'

import themeReducer from "redux-store/theme/theme";
import { AdminExpoStore } from "controllers/admin-expo/store";
import { AdminEventStore } from "controllers/admin-event/store";
import { AdminSpeakerStore } from "controllers/admin-speaker/store";
import { slicer_Speaker_Report } from "controllers/admin-users/redux-store/speaker/report/report";
import { slicer_User_Speaker_Report } from "controllers/articles/redux";
import { enableMapSet } from "immer";

enableMapSet();

export const store = configureStore({
    reducer: {
        theme: themeReducer,
        registration: registration,
        confirmation: confirmation,
        resetPassword: resetPassword,
        changeReportState: changeReportState,
        constants: constants,
        profile: profile,
        profilePost: profilePost,
        token: token,
        tokenAdmin: tokenAdmin,
        speaker: combineReducers({
            GET_report: slicer_User_Speaker_Report.reducer,
            report: slicer_Speaker_Report.reducer,
        }),
        tokenRevoke: tokenRevoke,
        payInvoice: payInvoice,
        geonames: combineReducers({
            cities: geonamesCities,
            countries: geonamesCountries,
            regions: geonamesRegions,
            searchAll: geonamesSearchAll,
        }),
        postUserEvents: reducer_Post_UserEvents,
        UserEvents_DELETE: reducer_UserEvents_DELETE,
        UserEvents_GET: reducer_UserEvents_GET,
        frontContentLocale: frontContentLocale,
        adminUsers: adminUsers,
        adminCovidUsers: adminCovidUsers,
        userPost: userPost,
        userDelete: userDelete,
        userGet: userGet,
        userPut: userPut,
        priceGet: priceGet,
        pricePost: pricePost,
        promoGet: promoGet,
        promoPost: promoPost,
        promoDelete: promoDelete,
        adminExpo: AdminExpoStore,
        adminEvent: AdminEventStore,
        adminSpeaker: AdminSpeakerStore,
        filterUsers: filter_Users,
        countries: reducer_Countries,
        cities: reducer_Cities,
        visits,
        social,
        adminDiagram,
        contacts,
        posts,
        chats,
        expo_reg,
        startups,
        //
        companies: CompaniesStore,
        employees,

    },
});

export type RootState = ReturnType<typeof store.getState>;

export type CustomDispatch = typeof store["dispatch"];
