import React from "react";
import {Typography} from "@material-ui/core";
import ButtonDialog from "components/Buttons/ButtonDialog";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";
import __translate from "../../helper/locale/locale";

const PhotoInfoShowDialog: React.FunctionComponent<any> = ({...props}) => {
    return (<ButtonDialog
            buttonChildren={<TypographyTranslate value={"profile.photo_min_size_label"} ruVal="200КБ"/>}
            cancelButtonChildren={<TypographyTranslate value="site.close" ruVal="Закрыть"/>}
            maxWidth={"sm"}
            buttonProps={{
                variant: "text",
                color: "primary",
            }}
            applyButtonProps={{
                style: {display: "none"},
            }}
            cancelButtonProps={{}}>
            <Typography variant={"body2"} style={{textAlign: "left", color: "#000"}}>
                <div dangerouslySetInnerHTML={{ __html:__translate("profile.photo_min_size_description") }}></div>
            </Typography>
        </ButtonDialog>
    )
};
export default PhotoInfoShowDialog;
