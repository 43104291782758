import React, {useState} from "react";
import Asynchronous from "../../Asynchronous/Asynchronous";
import __translate from "../../../helper/locale/locale";
import { useSelector } from "react-redux";
import { reselectGeonamesCountriesCondition } from "../../../controllers/geolocation-route/redux-store/countries/countries";
import { reselectConstants } from "../../../controllers/constants-route/redux-store/constants/constants";
import __locale from "../../../helper/location/location";
import { LangEnum } from "../../../controllers/admin-speaker/enums";
import KdwRawTextField from "../Inputs/KdwRawTextField";

type Props = {
    error?: string;
    disabled?: boolean;
    required?: boolean;
    errorText?: string;
    placeholder?: string;
    onSelect: (event: any) => void;
    value: any;
    name?: string;
};

export const ucFirst = (v: string) => {
    return !v || v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
};
const CountriesSelect: React.FC<Props> = ({
    error = "",
    errorText = "",
    placeholder = "",
    required = false,
    disabled = false,
    onSelect,
    name = "country",
    value,
}) => {
    const conditionCountry = useSelector(reselectGeonamesCountriesCondition);
    const lists = useSelector(reselectConstants);
    // const listCountries = useSelector(reselectGeonamesCountriesList);
    const [listCountries, setCountries] = React.useState([]);
    const localizedItem = (v:any) => {
        return {
            code: v.code,
            name: ((__locale() === LangEnum.RU ? v.name_ru : v.name_en)).toLowerCase(),
            name_en: v.name_en.toLowerCase() ,
            name_ru: v.name_ru.toLowerCase(),
        }
    } ;
    const updateCountries = (name: string) => {
        name = (name||"").toLowerCase();
        setCountries(
            [{name_ru:"Россия", name_en:"Russia", code: "RU"}].map(localizedItem).concat(lists.countries
                .map(localizedItem)
                .filter((v: any) => {
                    return (
                        v.code !== "RU" && (
                            !name ||
                            v.name_ru.indexOf(name) !== -1 ||
                            v.name_en.indexOf(name) !== -1
                        )
                    );
                }))
                .map((v: any) => {
                    return { code: v.code, name: ucFirst(v.name)  };
                }),
        );
    };
    const  selectByText = () => {
        let selected = false;
        let count = [] as any[];
        lists?.countries?.map(localizedItem).forEach((v: any) => {
            const l = (displayValue||"").toLowerCase();
            if (v.name_en === l || v.name_ru === l) {
                setDisplay(ucFirst(v.name_en));
                onSelect({ name, value: { name: v?.code } });
                selected = true;
            }
            if ((v.name_ru||"").indexOf(l) === 0||(v.name_en||"").indexOf(l) === 0) count.push(v);
        });
        if (count.length == 1) {
            onSelect({ name, value: { name: count[0]?.code } });
            setDisplay(ucFirst(count[0].name));
            selected = true;
        }
        if (!selected) {
            onSelect({ name, value: { name: null } });
            setDisplay('')
        }
    };
    React.useEffect(() => {
        lists?.countries?.forEach((v: any) => {
            if (v.code === value) setDisplay(ucFirst(v.name || (__locale() === LangEnum.RU ? v.name_ru : v.name_en)));
        });
        if (lists && lists.countries) updateCountries("");
    }, [lists]);
    const [displayValue, setDisplay] = React.useState(value);
    const ref = React.useRef<any>();

    const [edited, setEdited] = useState(undefined as any);
    const err = !!error || edited !== undefined && (required && !displayValue);
    return (
        <Asynchronous
            required={required}
            error={err && ' ' || error}
            name={name}
            value={displayValue}
            label={!placeholder && __translate("signup.country", "Страна") || ""}
            placeholder={placeholder && __translate("signup.country", "Страна")}
            isLoading={conditionCountry.inProgress}
            options={listCountries}
            disabled={disabled}
            onOptionClick={(name, value) => {
                setDisplay(value ? value.name : "");
                return onSelect({ name, value: { name: value?.code } });
            }}
            onClose={(e:any) => {
                updateCountries(displayValue);
                e.type === 'blur' && selectByText();
            }}
            onChange={e => {
                let o = e.target.value;
                setEdited(o);
                setDisplay(o);
                if (ref.current) clearTimeout(ref.current);
                ref.current = setTimeout(() => {
                    updateCountries(o);
                    setDisplay(o);
                }, 200);
            }}
        />
    );
};
export default CountriesSelect;
