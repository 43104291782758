import React, {useState} from "react";
import {FormHelperText, Grid, Tooltip, Typography} from "@material-ui/core";
import {reselect_Profile_entity} from "controllers/profile-route/redux-store/profile/profile";
import {useSelector} from "react-redux";
import __translate from "helper/locale/locale";
import {useStyles} from "pages/KdwUserPage";
import FileField from "components/FIleUploadButton/FileField";
import KdwCheckBox from "components/Form/Inputs/KdwCheckBox";
import Loader from "components/Loader/Loader";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import {MANAGER_ROLES} from "constants/constants";
import TranslatesByKeySelect from "./SelectFields/TranslatesByKeySelect";
import CitiesSelect from "./SelectFields/CitiesSelect";
import {UserFields} from "../../controllers/registration-route/form/HackathonUsers";
import KdwGreenButton from "../Buttons/KdwGreenButton";
import {reselectStartupCondition} from "../../controllers/startups/store";
import KDWSnackbar, {CondSnackbar} from "../KDWSnackbar/KDWSnackbar";
import ReportStatusBar from "../FormSpeaker/ReportStatusBar";
import {reselectConstants} from "../../controllers/constants-route/redux-store/constants/constants";

const CAN_SAVE_DRAFT = false;

export const STATE_DRAFT = 0;
export const STATE_SUBMITTED = 1;
export const STATE_WORK = 2;
export const STARTUP_CHECKED = 3;
export const STARTUP_REJECTED = 4;
export const STATE_APPROVED = 8;
export const STARTUP_EDIT = 12;
const StartupForm = (props: any) => {
    const classes = useStyles();
    const profile = useSelector(reselect_Profile_entity);
    const [dirty, updateDirty] = useState<Array<string>>([]);
    const [startup, setStartup] = useState(props?.startup as any);
    const [users, setUsers] = useState(props.startup?.team || [{}])
    const [ar, setAR] = useState(props.startup?.acceleration_result || [{}])

    const validator = props.validator || ((n:string) => '');
    const condition = useSelector(reselectStartupCondition);
    const list = useSelector(reselectConstants);

    const handleOnChange = ({target: {checked, name, value}}: any) => {
        const nd = [...dirty, name];
        updateDirty(nd);
        let d = {...startup, [name]: checked || value} as any;
        setStartup(d);
        props.onChange && props.onChange(d, nd)
    };
    const isManager = profile?.user_type === "manager";
    const isAdmin = "admin".indexOf(profile?.user_type) !== -1;
    const reportsCurator = isAdmin || isManager && (0 < (profile?.role & MANAGER_ROLES.STARTUP_EDITOR));
    const canEdit = !props.disabled &&(!startup.id || startup?.status?.state == STARTUP_EDIT || startup?.status?.state < STATE_SUBMITTED || !!reportsCurator);

    const onChangeUser = (e: any, i: any) => {
        const tmp = users.map((v2:any, i2:number) => i == i2 ? {...v2, [e.target.name]: e.target.value} : v2);
        handleOnChange({target: {name: 'team', value: tmp}})
        return setUsers([...tmp])
    };
    const onChangeAR = (e: any, i: any) => {
        const tmp = ar.map((v2:any, i2:number) => i == i2 ? {...v2, [e.target.name]: e.target.value} : v2);
        handleOnChange({target: {name: 'acceleration_result', value: tmp}})
        return setAR([...tmp])
    };
    let a = null;
    return (
        <Grid item xs={12} sm={props.sm || 6} key={startup.id} className={classes.whiteCard}>
            <ReportStatusBar item={startup} states={list.user.startup_states}/>
            <br/>
            <CondSnackbar cond={condition}/>
            {props.loading ? <Loader/> : <>
                <KdwRawTextField
                    required={true}
                    name="title"
                    labelKey="startup"
                    onChange={handleOnChange}
                    value={startup?.title}
                    validator={validator}
                    disabled={!canEdit}
                /><br/>
                <KdwRawTextField
                    name="brand"
                    labelKey="startup"
                    onChange={handleOnChange}
                    value={startup?.brand}
                    validator={validator}
                    disabled={!canEdit}
                /><br/>
                <KdwRawTextField
                    name="site"
                    labelKey="startup"
                    onChange={handleOnChange}
                    value={startup?.site}
                    validator={validator}
                    disabled={!canEdit}
                /><br/>
                <KdwRawTextField
                    required={true}
                    name="company"
                    labelKey="startup"
                    onChange={handleOnChange}
                    value={startup?.company}
                    validator={validator}
                    disabled={!canEdit}
                /><br/>
                <KdwRawTextField
                    inputProps={{type: "number"}}
                    required={true}
                    name="inn"
                    labelKey="startup"
                    onChange={handleOnChange}
                    value={startup?.inn}
                    validator={validator}
                    disabled={!canEdit}
                /><br/>
                <Grid item xs={12} sm={12}>
                    <KdwRawTextField
                        inputProps={{multiline: true, rows: 10, autoComplete: "off"}}
                        required
                        name="description"
                        labelKey="startup"
                        validator={validator}
                        helperText={(startup?.description?.length || 0) + '/400'}
                        onChange={handleOnChange}
                        value={startup?.description}
                        variant="outlined"
                        disabled={!canEdit}
                        fullWidth
                        className={classes.input}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <KdwRawTextField
                        inputProps={{multiline: true, rows: 10, autoComplete: "off"}}
                        required
                        name="reason"
                        labelKey="startup"
                        validator={validator}
                        helperText={(startup?.description?.length || 0) + '/400'}
                        onChange={handleOnChange}
                        value={startup?.reason}
                        variant="outlined"
                        disabled={!canEdit}
                        fullWidth
                        className={classes.input}
                    />
                </Grid>
                <KdwRawTextField
                    required={true}
                    label={__translate("startup.release_state", "Стадия развития стартапа")}
                    validator={validator}
                >
                    <TranslatesByKeySelect
                        onChange={handleOnChange}
                        value={startup?.release_state}
                        value_index={true}
                        name="release_state"
                        disabled={!canEdit}
                        error={validator('release_state')}
                        key_part="startup_release_state_"
                        label={__translate("startup.release_state", "Стадия развития стартапа")}
                        placeholder={__translate("startup.release_state_hint", "Стадия развития стартапа")}
                    />
                </KdwRawTextField><br/>
                <KdwRawTextField
                    required={true}
                    label={__translate(`startup.business_file`, "Бизнес модель")}
                >
                    <Grid container style={{gap:10,alignItems:"center"}}>

                        <FileField
                            value={startup?.business_file}
                            isDisabled={!canEdit}
                            label={__translate(`startup.load_file`, 'Загрузить')}
                            hideActions={!canEdit}
                            name="business_file"
                            onChange={handleOnChange}/>

                        <Tooltip placement="bottom-start" title={<Typography>{__translate(`startup.business_file_desc`, "") || ''}</Typography>}>
                            <Typography style={{cursor: "pointer"}}>{__translate(`site.more`, "Подробнее") || ''}</Typography>
                        </Tooltip>
                    </Grid>

                    <FormHelperText style={{color: "red"}}>{validator("business_file")}</FormHelperText>
                </KdwRawTextField>
                <br/>
                <KdwRawTextField
                    inputProps={{type: "number"}}
                    required
                    name="proceeds2022"
                    labelKey="startup"
                    validator={validator}
                    onChange={handleOnChange}
                    value={startup?.proceeds2022}
                    disabled={!canEdit}
                />
                <br/>
                <KdwRawTextField
                    inputProps={{type: "number"}}
                    required
                    name="proceeds"
                    labelKey="startup"
                    validator={validator}
                    onChange={handleOnChange}
                    value={startup?.proceeds}
                    disabled={!canEdit}
                /><br/>
                <Grid item xs={12} sm={12}>
                    <KdwRawTextField
                        inputProps={{multiline: true, rows: 10, autoComplete: "off"}}
                        required
                        name="sector"
                        labelKey="startup"
                        validator={validator}
                        helperText={(startup?.sector?.length || 0) + '/400'}
                        onChange={handleOnChange}
                        value={startup?.sector}
                        variant="outlined"
                        disabled={!canEdit}
                        fullWidth
                        className={classes.input}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <KdwRawTextField
                        inputProps={{multiline: true, rows: 10, autoComplete: "off"}}
                        required
                        name="target"
                        labelKey="startup"
                        validator={validator}
                        helperText={(startup?.target?.length || 0) + '/400'}
                        onChange={handleOnChange}
                        value={startup?.target}
                        variant="outlined"
                        disabled={!canEdit}
                        fullWidth
                        className={classes.input}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <KdwRawTextField
                        inputProps={{multiline: true, rows: 10, autoComplete: "off"}}
                        required
                        name="competitor"
                        labelKey="startup"
                        validator={validator}
                        helperText={(startup?.competitor?.length || 0) + '/400'}
                        onChange={handleOnChange}
                        value={startup?.competitor}
                        variant="outlined"
                        disabled={!canEdit}
                        fullWidth
                        className={classes.input}
                    />
                </Grid>

                <KdwRawTextField>
                    <KdwCheckBox
                        name="has_own"
                        value={startup?.has_own}
                        disabled={!canEdit}
                        onChange={handleOnChange}
                        labelText={__translate(`startup.has_own`, "Наличие интеллектуальной собственности",)}
                        errorText={validator("has_own")}
                    />
                </KdwRawTextField>

                {startup?.has_own && <KdwRawTextField
                    label={a = __translate("startup.own_type", "Вид интеллектуальной собственности")}
                    name="own_type"
                    validator={validator}
                >
                    <TranslatesByKeySelect
                        onChange={handleOnChange}
                        value={startup?.own_type}
                        value_index={true}
                        disabled={!canEdit}
                        name="own_type"
                        key_part="startup_own_type_"
                        label={__translate("startup.own_type_hint", a)}
                    />
                </KdwRawTextField> || ''}
                <KdwRawTextField>
                    <KdwCheckBox
                        name="acceleration"
                        value={startup?.acceleration}
                        onChange={handleOnChange}
                        labelText={__translate(`startup.acceleration`, "Участие в программах акселерации",)}
                        errorText={validator("acceleration")}
                        disabled={!canEdit}
                    />
                </KdwRawTextField>
                {startup?.acceleration &&
                    <KdwRawTextField
                        required={true}
                        name="acceleration_result"
                        labelKey="startup"
                    >
                        {ar.map((v:any, i:number) => <>
                            <UserFields fields={v}
                                        disabled={!!!canEdit}
                                        condition={condition}
                                        transKey="startup_acceleration_result"
                                        inputs={['title', 'result']}
                                        key={'acceleration_result.' + i}
                                        prefix={'acceleration_result.' + i + '.'}
                                        onChange={(e: any) => onChangeAR(e, i)}/>
                        </>)}
                        <br/>
                        {canEdit && ar.length < 5
                            && ar.filter((v:any)=> !v.title || !v.result).length == 0
                            && <KdwGreenButton onClick={() => setAR([...ar, {}])}
                                               label={__translate("signup.add_startup_ar", "Добавить")}/>}

                    </KdwRawTextField>
                ||''}<br/>

                <KdwRawTextField
                    label={__translate("startup.city", "В каком городе находится ключевая команда стартапа")}
                    required={true}
                >
                    <CitiesSelect
                        onSelect={target => {
                            target.value = target?.value?.name || target?.value
                            handleOnChange({target})
                        }}
                        onChange={handleOnChange}
                        error={validator("city") || !startup.city}
                        name={"city"}
                        value={startup?.city || ""}
                        country={"RU"}
                        placeholder={__translate("signup.city", "Населенный пункт")}
                        disabled={!canEdit}
                    />
                </KdwRawTextField><br/>

                <KdwRawTextField
                    inputProps={{type: "number"}}
                    required
                    name="amount"
                    labelKey="startup"
                    validator={validator}
                    onChange={handleOnChange}
                    value={startup?.amount}
                    disabled={!canEdit}
                />
                <KdwRawTextField>
                    <KdwCheckBox
                        name="has_investors"
                        value={startup?.has_investors}
                        onChange={handleOnChange}
                        labelText={__translate(`startup.has_investors`, "Наличие соинвестора",)}
                        errorText={validator("has_investors")}
                        disabled={!canEdit}
                    />
                </KdwRawTextField>

                {startup?.has_investors && <KdwRawTextField
                    required
                    name="self_amount"
                    labelKey="startup"
                    validator={validator}
                    onChange={handleOnChange}
                    value={startup?.self_amount}
                    disabled={!canEdit}
                /> ||''}<br/>
                <KdwRawTextField
                    required={true}
                    label={__translate(`startup.pdf_file`, "Презентация")}
                >
                    <FileField
                        isDisabled={!canEdit}
                        value={startup?.pdf_file}
                        label={__translate(`startup.load_file`, 'Загрузить')}
                        name="pdf_file"
                        onChange={handleOnChange}
                    />
                    <FormHelperText style={{color: "red"}}>{validator("pdf_file")}</FormHelperText>
                </KdwRawTextField>
                <KdwRawTextField
                    required={true}
                    label={__translate(`startup.team`, "Команда проекта")}
                >
                    {users.map((v:any, i:number) => <>
                        <UserFields fields={v}
                                 disabled={!!!canEdit}
                                 condition={condition}
                                 transKey="startup_team"
                                 inputs={['name', 'email']}
                                 key={'team.' + i}
                                 prefix={'team.' + i + '.'}
                                 onChange={(e: any) => onChangeUser(e, i)}/>
                    </>)}
                    <br/>
                    {canEdit && users.length < 5
                        && users.filter((v:any)=> !v.name || !v.email).length == 0
                        && <KdwGreenButton onClick={() => setUsers([...users, {}])}
                             label={__translate("signup.add_startup_user", "Добавить участника")}/>}

                </KdwRawTextField>

            </>}
        </Grid>
    );
};
export default StartupForm;
