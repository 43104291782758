import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import {
    apiAdminEventIndex,
    apiAdminEventMessages,
    apiDeleteAdminEventMessage,
} from "controllers/admin-event/admin-event-get-list/api/admin-event-get-list";
import { Event } from "controllers/admin-event/interfaces/admin-event";
import {makeClearPaginator, Paginator} from "helper/redux";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type EventFilter = {
    name?: string;
    forum_themes?: [];
    types?: [];
    with_scene?: string;
    place?: string;
    scene?: string;
    min_date?: any;
    max_date?: any;
    with_stream_link?: boolean;
    order_field?: string;
    order_rule?: string;
    cache?: number;
};

const eventFilterInitial: EventFilter = {
    name: "",
    forum_themes: [],
    types: [],
    with_scene: "",
    place: "",
    scene: "",
    min_date: "",
    max_date: "",
    with_stream_link: null,
    order_field: "",
    order_rule: "",
};

export const slicerAdminEventIndex = createSlice({
    name: "admin-event-index",
    initialState: {
        paginator: makeClearPaginator(),
        list: [] as Array<Event>,
        dynamicList: [] as Array<Event>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
        filter: eventFilterInitial,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onPagesClear: state => {
            state.dynamicList = [];
        },
        onPagesSuccess: (state, action) => {
            state.dynamicList = [...state.dynamicList, ...action.payload.data];
            state.condition = {
                ...state.condition,
                inProgress: false,
                response: action.payload,
                notice: {
                    severity: "success",
                    visibility: false,
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<Event>;
                current_page: number;
                message: string;
                result: boolean;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.current_page,
                first_page_url: action.payload.first_page_url,
                from: action.payload.from,
                last_page: action.payload.last_page,
                last_page_url: action.payload.last_page_url,
                next_page_url: action.payload.next_page_url,
                path: action.payload.path,
                per_page: action.payload.per_page,
                prev_page_url: action.payload.prev_page_url,
                to: action.payload.to,
                total: action.payload.total,
            };
            state.list = action.payload.data.map(event => {
                return {
                    ...event,
                    date: event.date,
                };
            });
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.filter);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
        resetEventFilter: state => {
            state.filter = eventFilterInitial;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onChange,
    onPagesSuccess,
    onPagesClear,
    resetEventFilter,
} = slicerAdminEventIndex.actions;

export const asyncAdminEventIndex = (
    page: number,
    filterParams?: EventFilter,
    exportXLS?: boolean,
    exportTranslationXLS?: boolean,
    callback?: (result: any) => void,
    per_page?: number,
) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiAdminEventIndex(page, filterParams, exportXLS, exportTranslationXLS, per_page).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncAdminEventAllByPage = (
    page: number,
    filterParams?: EventFilter,
    per_page?: number,
    callback?: (result: any) => void,
) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiAdminEventIndex(page, filterParams, undefined, undefined, per_page).catch(e => {
        console.log(`${e.name}: ${e.message}`);
    });
    if (result) {
        dispatch(result.result ? onPagesSuccess(result) : onResponseFail(result));
        if (result.last_page > page) {
            dispatch(asyncAdminEventAllByPage(page + 1, filterParams, per_page, callback));
        } else {
            if (callback) {
                callback(result);
            }
        }
    } else {
        dispatch(onNoResponse());
    }
};
export const asyncAdminEventMessages = (
    event: number,
    page: number,
    callback?: (result: any) => void,
    per_page?: number,
) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiAdminEventMessages(event, page, per_page).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        if (!result.result) dispatch(onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncDeleteAdminEventMessage = (event: number, msg_id: number, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(onRequest());
    const result = await apiDeleteAdminEventMessage(event, msg_id).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        if (!result.result) dispatch(onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectAdminEvent = (state: RootState): Array<any> => {
    return state.adminEvent.index.list;
};
export const reselectAdminEventPages = (state: RootState): Array<any> => {
    return state.adminEvent.index.dynamicList;
};
export const reselectAdminEventPaginator = (state: RootState): Paginator => {
    return state.adminEvent.index.paginator;
};
export const reselectAdminEventCondition = (state: RootState): Response => {
    return state.adminEvent.index.condition;
};

export const reselectAdminEventFilter = (state: RootState): EventFilter => {
    return state.adminEvent.index.filter;
};

export default slicerAdminEventIndex.reducer;
