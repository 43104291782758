import React from "react";
import UDFileUploadButton from "./input";
import __translate from "../../helper/locale/locale";
import {ReactComponent as PDFIcon} from "../appicons/pdf-icon.svg";
import FormHelperText from "@material-ui/core/FormHelperText";
import {FormControl} from "@material-ui/core";
import {fileValueChanger} from "../../helper/blob/blob";


type Props = {
    value: any;
    onChange: (e: any) => void;
    onFileDelete?: () => void;
    isDisabled?: boolean;
    required?: boolean;
    pdf?: boolean;
    name?: string;
    label?: string;
    accept?: string;
    hideActions?: boolean;
};
const FileField = (props: Props) => {
    const { value, onChange, isDisabled, required, accept =  "application/pdf, application/vnd.ms-powerpoint, " +
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" } = props;

    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "center"}}>
                {props.pdf !== false && (accept?.indexOf('pdf') >= 0) && <PDFIcon/>}
                {!isDisabled && <UDFileUploadButton
                    disabled={isDisabled}
                    handleFile={(file:any) => fileValueChanger(file, props.name, onChange)}
                    accept={accept}
                    name={props.name}
                    label={props?.label}
                    hideActions={props.hideActions}
                    value={value?.file}
                    green={true}
                />}
                {value?.file && (
                    <a target="_blank" style={{ margin: "0 1em" }} href={value.file}>
                        {value?.name || __translate("file.download", "Загрузить")}
                    </a>
                )}
                {required && !value?.file &&
                    <FormControl required error={false} component="fieldset" style={{marginLeft:5}}>
                        <FormHelperText>{__translate("signup.file_required", "Необходимо загрузить файл (не менеe 200КБ)")}</FormHelperText>
                    </FormControl>}
            </div>
        </React.Fragment>
    );
};
export default FileField;
