import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiUser, User } from "controllers/admin-users/api/admin-users/admin-users";
import { Response } from "./admin-users";
import {makeClearPaginator, Paginator} from "helper/redux";

export const slicerAdminCovidUsers = createSlice({
    name: "admin-covid-users",
    initialState: {
        paginator: makeClearPaginator(),
        list: [] as Array<User>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<User>;
                current_page: number;
                message: string;
                result: boolean;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.current_page,
                first_page_url: action.payload.first_page_url,
                from: action.payload.from,
                last_page: action.payload.last_page,
                last_page_url: action.payload.last_page_url,
                next_page_url: action.payload.next_page_url,
                path: action.payload.path,
                per_page: action.payload.per_page,
                prev_page_url: action.payload.prev_page_url,
                to: action.payload.to,
                total: action.payload.total,
            };
            state.list = action.payload.data;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
} = slicerAdminCovidUsers.actions;

export const asyncFilterUsers = (page: number, filter: any, callback?: (result: any) => void) => async (
    dispatch: Dispatch,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const result = await apiUser({page,  filter: {...filter}, exportXLS:false }).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectAdminCovidUsers = (state: RootState): Array<User> => {
    return state.adminCovidUsers.list;
};
export const reselectAdminCovidUsersPaginator = (state: RootState): Paginator => {
    return state.adminCovidUsers.paginator;
};
export const reselectAdminCovidUsersCondition = (state: RootState): Response => {
    return state.adminCovidUsers.condition;
};

export default slicerAdminCovidUsers.reducer;
