import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { downloadBlob } from "../../../../helper/blob/blob";

/**
 * @route api/profile
 * @get
 */
export const apiRouteProfile = async () => {
    const response = await kyRequest.get(`${BACKEND_HOST}/profile`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
export const participationPayForm = async (order?: Number) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/participant/pay/` + (order || ""), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
export const apiParticipationForm = async (form?: any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/participant/form/` + (form?.id || ""), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
export const getDoc = async (docName = 'certificate.png', params = null as any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/user/docs/` + docName.split('.')[0] + '?'
            + (new URLSearchParams(params ||{})).toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return new Promise((res,rej) => response.blob().then(blob => {
        downloadBlob(blob, docName, '_blank');
        res(blob);
    }).catch(e => {console.log(e); rej(e);}));
};
