import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import {useStyles} from "pages/KdwUserPage";
import {useValidator} from "helper/validation/validator";
import FormWithDirtyValidator from "../../Form/FormWithDirtyValidator";
import ExponentCompanyForm from "./ExponentCompanyForm";
import ExponentCompanyUserForm from "./ExponentCompanyUserForm";
import KdwGreenButton from "../../Buttons/KdwGreenButton";

function ExponentRequestForm(props: any) {
    const {item, user, errors, onError} = props;
    const [e1,setE1] = useState(false);
    const [e2,setE2] = useState(false);
    useEffect(() => {
        onError && onError(e1 || e2)
    }, [e1, e2]);
    const classes = useStyles();
    const addedClasses = useFormStyles();
    return (
        <>
            <Grid container spacing={2} className={addedClasses.raw}>
                <div className={classes.grayBlockTitle}>
                    <TypographyTranslate value="company.company_data" ruVal="Информация о компании"/>
                </div>
                <ExponentCompanyForm item={item} {...{onError, errors}}
                    onFormError={setE1}
                    onChange={(e:any) => {e.target.model = 'company'; props.onChange(e);}}
                />
                <div className={classes.grayBlockTitle}>
                    <TypographyTranslate value="company.user_data" ruVal="Контактная информация"/>
                </div>
                <ExponentCompanyUserForm item={user} {...{onError, errors}}
                    onFormError={setE2}
                    prefix={props.userPrefix}
                    onChange={(e:any) => {e.target.model = 'user'; props.onChange(e);}}
                />
            </Grid>
        </>
    );
}

export default ExponentRequestForm;
const useFormStyles = makeStyles(theme => ({
    raw: {
        ["& > div"]: {
            marginBottom: "5px",
        }
    }
}));
