import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import {KDW_D, RegistrationRole as RR} from "constants/constants";
import RoleCard from "components/Cards/RoleCard";
import KdwCheckBox from "components/Form/Inputs/KdwCheckBox";
import {ECONOM_FEATURES, PREMIUM_FEATURES, SPEAKER_FEATURES} from "constants/site/roleInfo";
import __locale from "helper/location/location";
import {useSelector} from "react-redux";
import {reselect_Profile_entity} from "controllers/profile-route/redux-store/profile/profile";
import {Grid} from "@material-ui/core";
import {useStyles} from "../../../KdwUserPage";
import {CurrentRoleCard} from "../Participations/CurrentRolePage";

const makeStyles1 = makeStyles((theme) => ({
    other: {
        backgroundColor: "#fff",
        display: "flex",
        flexGrow: 1,
        height: "40px",
        borderRadius: "10px",
        padding: "0 20px",
        color: "#00615F",
        "& .MuiTypography-body1" : {
            lineHeight: "20px !important",
            fontSize: "18px !important",
        },
        [theme.breakpoints.down(900)]: {
            height: "auto",
        },
    },
    activeOther:{
        background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
        color: "#fff !important",
        borderRadius: "20px",
    },
    cards: {
        width: "100%",
        display: "flex",
        paddingTop: "20px",
        [theme.breakpoints.down(800)]: {
            flexDirection:"column"
        },
        "&>div" : {
            padding:"10px 10px"
        }
    }
}));

const ROLE_FEATURES = {
    [RR.PARTICIPANT_ECONOM] : ECONOM_FEATURES,
    [RR.PARTICIPANT_PREMIUM] : PREMIUM_FEATURES,
    [RR.SPEAKER] : SPEAKER_FEATURES,
    [RR.ORGANIZER] : [],
    [RR.VOLUNTEER] : [],
} as any;
export const getFeatures = (role: number) => {return ROLE_FEATURES?.[role]?.[__locale()]?.slice(0, 3) as any;};
function UserRolesBar(props: any) {
    const { handleRoleChange, roleValue, setRole, onClose} = props;
    const classes = useStyles();
    const localClasses = makeStyles1();
    const dataProfile = useSelector(reselect_Profile_entity);
    const roleCardProps = {features: getFeatures, current: roleValue, onClick:setRole, hover:true, dates:true};
    const primaryRoles = {[RR.PARTICIPANT_ECONOM]:setRole, [RR.SPEAKER]:setRole, [RR.PARTICIPANT_PREMIUM] : setRole} as any;
    const secondaryRoles =  {[RR.ORGANIZER]:setRole, [RR.VOLUNTEER]:setRole, [RR.MEDIA] : setRole} as any;
    // const secondaryRoles = [RR.ORGANIZER,RR.VOLUNTEER];
    const [other, setOther] = React.useState(roleValue > RR.SPEAKER);
    return (
        <>
            <div className={classes.grayBlockTitle} style={{width:"100%"}}> <span>{__translate("signup.select_role", "Выберите категорию участия")}</span></div>
            {!other && <div className={localClasses.cards}>
                {Object.keys(primaryRoles).map((v, k) => (<Grid item sm={12} md={4}>
                    {v !== `${dataProfile?.participation_data?.role}`
                        ? <RoleCard role={Number.parseInt(v)} {...roleCardProps} {...{onClick: primaryRoles?.[v]}}
                            free={v !== (''+RR.PARTICIPANT_PREMIUM)}/>
                        : <CurrentRoleCard onClick={null}/>
                    }</Grid>))}
            </div>}
            {!Object.keys(secondaryRoles).length ? '' : <>
                {other && <Grid container className={localClasses.cards}>
                    {Object.keys(secondaryRoles).map((v, k) => (<Grid item sm={12} md={4}>
                        {v !== `${dataProfile?.participation_data?.role}`
                            ?
                            <RoleCard role={Number.parseInt(v)} {...roleCardProps} {...{onClick: secondaryRoles?.[v]}}/>
                            : <CurrentRoleCard onClick={null}/>}
                    </Grid>))}
                </Grid>}
                <div style={{display:"flex", gap:"18px", paddingTop: "20px", opacity: !other ? 0.5 : 1}}>
                    <div className={localClasses.other + " " + (other && localClasses.activeOther)}>
                        <KdwCheckBox onChange={(e) => setOther(e.target.checked)} value={other} name="other" labelText={__translate("role.other_label", "Другой вариант участия (организатор, волонтер)")}/>
                    </div>
                </div>
            </>}
        </>
    );
}

export default UserRolesBar;
