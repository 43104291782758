import React from "react";
import {FormControl, FormHelperText, Grid, GridSize, InputLabel, MenuItem, Select} from "@material-ui/core";
import __translate from "../../../helper/locale/locale";
import {LangEnum} from "../../../controllers/admin-expo/enums";
import {useDispatch} from "react-redux";
import {selectByLang} from "../../FormEvent/FormEvent";
import __locale from "../../../helper/location/location";
import {asyncVisitsSearch} from "../../../controllers/visits/redux-store/visits/visits";

type Props = {
    error?: string;
    disabled?: boolean;
    onChange: (event: any) => void;
    value: any;
    xs?: GridSize;
    name: string;
    type?: any;
    multiple?: boolean;
    label?: string;
    placeholder?: string;
};

const VisitsSelect: React.FC<Props> = ({
    error = "",
    disabled = false,
    onChange,
    xs = 12,
    name,
    value,
    type = undefined,
    multiple = false,
    label = null,
    placeholder = null,
}) => {
    const reduxDispatch = useDispatch();
    const lang = __locale();
    const [key] = React.useState({ key_part: "hackathon_names" });
    const [hackathons, setHackathons] = React.useState([]);

    React.useEffect(() => {
        fetchPage();
    }, []);

    const fetchPage = () => {
        reduxDispatch(
            asyncVisitsSearch(
                type !== undefined ? {type} : {},
                (response: any) => {
                    if (response.result) {
                        setHackathons(response.data);
                    }
                },
            ),
        );
    };
    return (
        <Grid item xs={xs}>
            <FormControl error={!!error} fullWidth variant="outlined">
                {!!label && (
                    <InputLabel required id="hackathon-select-label">
                        {label}
                    </InputLabel>
                )}
                <Select
                    labelId="hackathon-select-label"
                    id="hackathon-select-1"
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder || label}
                    label={label}
                    value={value}
                    multiple={multiple}
                    onChange={onChange}>
                    {hackathons.map(hackathon => (
                        <MenuItem value={hackathon.id}>
                            {selectByLang(hackathon.name, lang as LangEnum, "")}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Grid>
    );
};

export default VisitsSelect;
