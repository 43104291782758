import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { User } from "controllers/admin-users/api/admin-users/admin-users";
import {
    apiRouteChangeReportState,
    ChangeReportState,
} from "../../../../admin-user-put/api/admin-user-put/change-report-state";
import {postUserArticlesApi} from "controllers/articles/api"

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};


export const slicer_Speaker_Report = createSlice({
    name: "speaker-report",
    initialState: {
        entity: {
            amount: 5500,
        },
        list: [] as Array<User>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        on_Change_speaker_report: (state, action: PayloadAction<{ name: string; value: any; checked?: boolean }>) => {
            const { name, value } = action.payload;
            switch (name) {
                case "amount": {
                    state.entity.amount = value;
                    break;
                }
                default: {
                    break;
                }
            }
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<User>;
                current_page: number;
                message: string;
                result: boolean;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.list = action.payload.data;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    on_Change_speaker_report,
} = slicer_Speaker_Report.actions;

export const asyncReportAdminUpdate = (reportData: ChangeReportState, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const result = await postUserArticlesApi(reportData).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    console.log("==>");
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselect_Speaker_Report_condition = (state: RootState) => {
    return state.speaker.report.condition;
};

export const reselect_Speaker_Report_entity = (state: RootState) => {
    return state.speaker.report.entity;
};

export default slicer_Speaker_Report.reducer;
