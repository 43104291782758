import React, {useEffect, useState} from "react";
import {Dialog, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "../../../../helper/locale/locale";
import KdwGreenButton from "../../../../components/Buttons/KdwGreenButton";
import {
    on_Response_Success,
    reselect_Profile_condition,
    reselect_Profile_entity
} from "../../../../controllers/profile-route/redux-store/profile/profile";
import TypographyTranslate from "../../../../components/TypographyTranslate/TypographyTranslate";
import {asyncConfirmationLink} from "../../../../controllers/confirmation-route/redux-store/confirmation-slicer/confirmation-slicer";
import {useDispatch, useSelector} from "react-redux";
import {apiRouteProfile} from "../../../../controllers/profile-route/api/profile/profile";
import {useHistory, useParams} from "react-router-dom";

const styles = makeStyles(theme => ({
    root: {
        background: "rgba(244, 244, 244, 0.8)",
        backdropFilter: "blur(2px)",
    },
    paper: {
        alignItems: "center",
        background: "#ffffff",
        borderRadius: "21px",
        padding: theme.spacing(4),
    },
    title: {
        '& .MuiTypography-h6' : {
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
            ["-webkit-background-clip"]: "text",
            ["-webkit-text-fill-color"]: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            fontWeight: 700,
            fontSize: "32px !important",
            lineHeight: "35px !important",
            fontStyle: "normal"
        },
    },
    content: {
        transition: "all 0.5s ease",
        marginLeft: 260,
        [theme.breakpoints.down(958)]: {
            marginLeft: 0,
            padding: "0 1em",
        },
    },
    btn: {
        width: 250,
    }
}));

function NeedEmailConfirm(props: any) {
    const {onClose, onConfirmed} = props;
    const keySuf = props.keySuf || "";
    const classes = styles();
    const dispatch = useDispatch();
    const profileCondition = useSelector(reselect_Profile_condition);
    const profile = useSelector(reselect_Profile_entity);
    const [canResend, setCan] = useState(true);
    const history = useHistory();
    const params = useParams<{ ru: string }>();
    const [load, setLoad] = useState(false);
    const resend = () => {
        setCan(false);
        dispatch(asyncConfirmationLink(r => {
            setTimeout(() => setCan(true), 10000);
        }));
    };
    useEffect( props.sendOpen ? resend : () => {}, []);
    return (
        <>
            <Dialog
                classes={classes}
                open={true}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}>
                <DialogTitle id="form-dialog-title" className={classes.title}>{__translate("profile.need_confirm" + keySuf, keySuf ? null : "Подтвердите адрес email!")}</DialogTitle>
                <DialogContent style={{width: "95%", padding: "4px"}}>
                    <p dangerouslySetInnerHTML={{ __html:__translate("profile.need_confirm_text" + keySuf + props.role,keySuf ? null : "Мы отправили ссылку для подтверждения ? , пожалуйста, подтвердите почту.")
                            .replace('?', profile?.email ? `на <span style="font-weight: 700">${profile?.email}</span>` : '')}}/>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px"}}>
                        {!keySuf ? <>
                            <KdwGreenButton
                                disabled={load}
                                label=""
                                onClick={() => {
                                    if (onConfirmed) {
                                        setLoad(true)
                                        return apiRouteProfile().then((r) => {
                                            let c;
                                            onConfirmed(c = !!r?.data?.email_confirmed_at)
                                            if (c) dispatch(on_Response_Success(r))
                                        }).finally(() => setLoad(false));
                                    }
                                    onClose && onClose();
                            }}><TypographyTranslate value="profile.i_email_confirm" ruVal="Я подтвердил"/></KdwGreenButton>
                                <KdwGreenButton
                                    label=""
                                    onClick={() => {
                                        history.push("/user/personal-data");
                                        onClose && onClose();
                                    }}>
                                    <TypographyTranslate value="profile.change_email" ruVal="Указать другую почту"/>
                                </KdwGreenButton>
                            {canResend && <KdwGreenButton
                                disabled={!canResend}
                                label=""
                                onClick={resend}><TypographyTranslate value="profile.resend_email_link" ruVal="Отправить повторно"/></KdwGreenButton>}
                        </>
                            : (<KdwGreenButton
                            className={classes.btn}
                            label={__translate("system.ok", "Хорошо")}
                            onClick={() => {onClose && onClose();}}/>)}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default NeedEmailConfirm;
