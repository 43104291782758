import React from "react";
import __translate from "helper/locale/locale";
import {FormHelperText} from "@material-ui/core";
import DateTextField from "./Inputs/DateTextField";
import Box from "@material-ui/core/Box";
import FileField from "../FIleUploadButton/FileField";
import TranslatesByKeySelect from "./SelectFields/TranslatesByKeySelect";
import KdwRawTextField from "./Inputs/KdwRawTextField";
import __locale from "../../helper/location/location";


export const docsFilled = (profile:(name?:string) => any, strict = false) => (
    !strict || (profile()?.doc_state || profile('doc_number') &&
    profile('doc_number2') &&
    profile('doc_date') &&
    profile('doc_org') &&
    profile('doc_photo'))
)
function UserDocForm(props: any) {
    const {profile, onChange = (e:any) => {}, validator = (n:string) => {}, disabled=false, required=true} = props;
    let doc_validator = (n: string) => validator('doc.' + n);
    let isRU = profile('country') === 'RU';
    const dcoErrors = Object.keys(validator('errors')||{}).join(',').indexOf('doc')!=-1;
    return (
        <>
                {(profile()?.doc_state < 1 || props.onlyDoc || dcoErrors) && <>
                    <KdwRawTextField
                        validator={doc_validator}
                        onChange={onChange}
                        disabled={disabled}
                        name="doc_number"
                        label={isRU ? __translate("signup.doc_number_ru", "Серия паспорта") : __translate("signup.doc_number", "Серия паспорта")}
                        value={profile('doc_number')}
                        type={isRU ? "number" : "text"}
                        required={required}
                    />
                    <KdwRawTextField
                        validator={doc_validator}
                        onChange={onChange}
                        disabled={disabled}
                        name="doc_number2"
                        type={isRU ? "number" : "text"}
                        label={__translate("signup.doc_number2", "Номер паспорта")}
                        value={profile('doc_number2')}
                        required={required}
                    />
                    <KdwRawTextField
                        validator={doc_validator}
                        onChange={onChange}
                        disabled={disabled}
                        name="doc_org"
                        label={__translate("signup.doc_org", "Орган выдавший документ")}
                        value={profile('doc_org')}
                        required={required}
                    />
                    <KdwRawTextField
                        required={required}
                        label={__translate("signup.doc_date", "Дата выдачи")}
                    >
                        <DateTextField
                            onChange={onChange}
                            disabled={disabled}
                            helperText={doc_validator('doc_date')}
                            error={!!doc_validator('doc_date')}
                            name={"doc_date"}
                            required={required}
                            value={profile("doc_date")}
                            placeholder={__translate("signup.doc_date", "Дата выдачи")}
                        />
                    </KdwRawTextField>
                    <KdwRawTextField
                        label={__translate("profile.doc_photo").concat(' ')}
                        required
                    >
                        <Box marginBottom={2}>
                            <Box display="flex" alignItems="center" marginBottom={2}>
                                <FileField
                                    pdf={false}
                                    accept={"image/png,image/jpg,image/jpeg,application/pdf"}
                                    value={profile('doc_photo')}
                                    name="doc_photo"
                                    isDisabled={disabled}
                                    required={required}
                                    onChange={onChange}/>
                            </Box>
                            {validator("doc.photo") && (
                                <FormHelperText error>{validator("doc.photo")}</FormHelperText>
                            )}
                        </Box>
                    </KdwRawTextField>
                </>}
                {!props.onlyDoc && <>
                    <KdwRawTextField
                        label={__translate("signup.target", "Цель посещения")}
                    >
                        <TranslatesByKeySelect
                            onChange={onChange}
                            value={profile('target') || profile().options?.target}
                            name="target"
                            label=''
                            placeholder={__translate("signup.target", "Цель посещения")}
                            key_part="target_type_"
                            value_index={true}
                        />
                    </KdwRawTextField>
                    <KdwRawTextField
                        label={__translate("signup.source", "Откуда вы узнали о форуме")}
                    >
                        <TranslatesByKeySelect
                            onChange={onChange}
                            value={profile('source')}
                            name="source"
                            label=''
                            placeholder={__translate("signup.source", "Откуда вы узнали о форуме")}
                            key_part="source_type_"
                            value_index={true}
                        />
                    </KdwRawTextField>
                    <KdwRawTextField
                        label={__translate("signup.order", "Принимаете ли вы решение о закупке")}
                    >
                        <TranslatesByKeySelect
                            onChange={onChange}
                            value={profile('order') || profile().options?.order}
                            name="order"
                            label=''
                            placeholder={__translate("signup.order", "Принимаете ли вы решение о закупке")}
                            key_part="order_type_"
                            value_index={true}
                        />
                    </KdwRawTextField>
                </>}
        </>
    );
}

export default UserDocForm;
