import React, {useState} from "react";
import {FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";
import {AttachFile} from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import {on_Change_FilterUsers} from "../../../controllers/admin-users/redux-store/filter-users/filter-users";
import {useDispatch} from "react-redux";
import {
    asyncCheckCredential, asyncCheckInn
} from "../../../controllers/registration-route/redux-store/registration-slicer/registration-slicer";
import {helpValidateNumberLength, useValidator} from "../../../helper/validation/validator";
import {helpValidateEmail} from "../../../helper/email/email";
import {NavLink, useParams} from "react-router-dom";
import __translate from "../../../helper/locale/locale";
import {helpValidateNumber} from "../../../helper/phone-number/phone-number";
import {
    apiCheckInn,
    TypeRequestRegistration
} from "../../../controllers/registration-route/api/registration/registration";
import Check from "../../appicons/Check";
import Loader from "../../Loader/Loader";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";
import KdwTextFieldGreenBottomLabel from "../KdwTextFieldGreenBottomLabel";
import {Company} from "../../../controllers/companies/redux";
import __locale from "../../../helper/location/location";
import { LangEnum } from "../../../controllers/admin-expo/enums";

type Props = {
    validator: (n:string) => any,
    handleOnChange: (e:any) => {},
    onComplete: (e:Company) => void,
    value:string,
    field?:string,
    disabled?:boolean,
    classes?:any,
};
const InnTextField: React.FC<Props> = ({
    validator,
    handleOnChange,
    onComplete,
    value,
    classes = {},
    disabled= false,
    field = 'inn',
}) => {

    const [timer, setTimer] = useState(null as any);
    const d = useDispatch();
    const on_Change = (e: any) => {
       handleOnChange(e);
       if (__locale() !== LangEnum.RU) return;
       setComp('');
        if (timer != null) {
            clearTimeout(timer)
            setTimer(null)
        }
        setTimer(setTimeout(() => {
            if (helpValidateNumberLength(e.target.value, '10')) d(asyncCheckInn(e.target.value, (r) => {
                setComp(r?.response?.data?.[0] || null)
                clearTimeout(timer)
                handleOnChange(e);
                setTimer(null)
            }));
            else setTimer(null)

        }, 1000))
    };
    const [comp, setComp] = useState(null as any);
    return <>
        <TextField
            key={field}
            type={field}
            name={field}
            label={''}
            helperText={validator(field)}
            error={!!validator(field)}
            onChange={(e) => {
                on_Change(e);
            }}
            value={value}
            disabled={disabled}
            variant="outlined"
            fullWidth
            autoComplete="off"
            className={classes?.input}
            InputProps={{
                endAdornment: (
                    timer !== null ? <Loader size={20}/> : null
                ),
            }}
        />

        {comp && <>
            <br/>
            <br/>
            <div style={{cursor:"pointer"}} onClick={(e) => onComplete(comp as Company)}><KdwTextFieldGreenBottomLabel>
                <TypographyTranslate value="site.company_inn_result" ruVal="Подставить данные компании " afterValue={' ' + comp.short_name}/>
            </KdwTextFieldGreenBottomLabel></div>
        </>}
    </>;
};
export default InnTextField;
