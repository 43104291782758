import React, {useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import TypographyTranslate from "../TypographyTranslate/TypographyTranslate";
import {ReactComponent as ArrowRight} from "../../components/appicons/arrow-right.svg";
import {ReactComponent as Ask} from "../../components/appicons/ask.svg";
import {ReactComponent as Teacher} from "../../components/appicons/teacher.svg";
import {ReactComponent as Book} from "../../components/appicons/book.svg";
import {ReactComponent as KDWick} from "../../components/appicons/kdwick.svg";
import KdwGreenButton from "../Buttons/KdwGreenButton";
import __translate from "../../helper/locale/locale";
import {useHistory, useParams} from "react-router-dom";
import __locale from "../../helper/location/location";
import {selectByLang} from "../FormEvent/FormEvent";
import {ARTICLE_FORMATTING_LINK, PUBLICATIONS_CONTENT} from "../../constants/site/publications";
import {LangEnum} from "../../controllers/admin-expo/enums";
import LinkWrapper from "../Site/LinkWrapper/LinkWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        articleCard: {
            flexDirection: "row",
            padding: "0 30px",
            [theme.breakpoints.up(800)]: {
                padding: "0 70px",
            },
            position: "relative",
            borderRadius: "20px",
            background: "linear-gradient(181.52deg, rgba(227, 223, 223, 0.5) 15.9%, rgba(244, 244, 244, 0.5) 98.71%)",
        },

        ask: {
            position: "absolute",
            left: "-50px",
        },
        imageContainer: {
            position: "absolute",
            bottom: "0",
            left: "5px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        title: {
            position: "relative",
            marginTop: 20,
            maxWidth: "calc(100% - 350px)",
            '& span': {
                fontSize: "16px",
                color: "#6F6F6F"
            }
        },
        editTitle: {
            '& span': {
                // cursor: "pointer",
            },
            cursor: "pointer",
            gap: 10,
            display: "flex",
            alignItems: "center",
            width: "100%",
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
            ["-webkit-background-clip"]: "text",
            ["-webkit-text-fill-color"]: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            fontWeight: 400,
        },
        btnContainer: {
            maxWidth: "max-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            '& button': {
                marginTop: "-30px",
                zIndex: 100,
            },
            [theme.breakpoints.down(800)]: {
                opacity: 0.2,
                alignItems: "flex-end",
                '& button': {
                    display: "none"
                }
            },
        }
    }),
);

const desc = "Международный форум Kazan Digital Week – 2023 " +
    "приглашает авторов к публикации статей в сборнике материалов форума " +
    "Статьи принимаются в соответствии с тематическими направлениями";
const AddArticleCard = ({...props}) => {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams<{ ru: string }>();
    const toAdd = () => {
        history.push('/user/articles');
    };
    const toRules = () => {
        window.open(selectByLang(ARTICLE_FORMATTING_LINK.link, lang as LangEnum, ""), '_blank')
    };
    const [show, setShow] = useState(true);
    const lang = __locale();
    return (
        <div className={classes.articleCard}>
            <Grid container className={classes.imageContainer}>
                <Book/>
                <Grid container className={classes.btnContainer}>
                    <KDWick/>
                    {props?.addMode && <KdwGreenButton onClick={toRules}
                                                       label={selectByLang(ARTICLE_FORMATTING_LINK.desc, lang as LangEnum, "")}/>}
                    {!props?.addMode &&
                    <KdwGreenButton onClick={toAdd} label={__translate("participation.add_article", "Подать статью")}/>}
                </Grid>
                <Teacher style={{marginTop: "-60px", marginLeft: "-20px"}}/>
            </Grid>
            <Grid container style={{position: "relative", display: "flex", flexDirection: "column", marginTop: "20px"}}
                  onClick={() => props?.onClick && props?.onClick()}>
                <div className={classes.title}><TypographyTranslate value="participation.article_desc" ruVal={desc}/>
                </div>
                <div className={classes.title}>
                    <Ask className={classes.ask}/>
                    {!show && <>
                        <Typography component="span">
                            {selectByLang(PUBLICATIONS_CONTENT, lang as LangEnum, "")[0]}
                        </Typography>
                        <div className={classes.editTitle} onClick={toAdd}><TypographyTranslate
                            value="participation.add_article" ruVal='"Подать статью"'/><ArrowRight/></div>
                    </>}
                    {show && (<>
                        <Typography component="span">
                            {selectByLang(PUBLICATIONS_CONTENT, lang as LangEnum, "")[2]}
                        </Typography>
                        {/*<Link href="https://vestnikncbgd.ru/ru/" target="_blank">*/}
                        {/*    <img alt={"Vestnik NTsBZhD"} src={NCBGDImage} />*/}
                        {/*</Link>*/}
                        {/*<div className={classes.editTitle} onClick={() => setShow(true)}>*/}
                        {/*    <LinkWrapper link="https://vestnikncbgd.ru">https://vestnikncbgd.ru</LinkWrapper>*/}
                        {/*</div>*/}
                    </>)}
                </div>

            </Grid>
        </div>
    );
};
export default AddArticleCard
