import React, {useState} from "react";
import __translate, {__transliterate} from "../../helper/locale/locale";
import __locale, {__localeStorage} from "helper/location/location";
import {LangEnum} from "controllers/admin-speaker/enums";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import OnlineSelect from "components/Form/SelectFields/OnlineSelect";
import CropImageUploadButton from "../FIleUploadButton/ImageUploadButton/CropImageUploadButton";
import {FormHelperText, InputLabel} from "@material-ui/core";
import {getFileFromUrl} from "controllers/profile-route-post/api/profile-post/profile-post";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";
import Grid from "@material-ui/core/Grid";
import {PARTICIPATION_STATE, RegistrationRole} from "constants/constants";
import KdwCheckBox from "./Inputs/KdwCheckBox";
import TranslatesByKeySelect from "./SelectFields/TranslatesByKeySelect";
import WorkInfoForm from "./WorkInfoForm";

type Props = {
    profile: (event?: any) => any;
    validator: (event: any) => any;
    onChange: (event: any) => any;
    work?:boolean;
    online?:boolean;
};
export const speakerFilled = (user: any) => {

    const pd = user?.participation_data;
    let nowork = `${pd?.work_type }` != '2';
    return (
        user && pd &&
        (__localeStorage() === LangEnum.EN
            || pd.surname_en
            && pd.name_en
            && (nowork || pd.place_of_work_en)
            && (nowork || pd.work_position_en)
            && (pd.online !== null || (pd.role &RegistrationRole.PARTICIPANT_PREMIUM) >0)
        )
        && (nowork || pd.work_position)
        && user?.photo
    );
};

export const speakerCanChangeData = (user: any) => {
    const pd = user?.participation_data;
    const role = pd?.role || user?.role;
    return (pd?.status?.state <= PARTICIPATION_STATE.CANCELED
        || role !== RegistrationRole.SPEAKER && role != RegistrationRole?.PARTICIPANT_PREMIUM
        || pd?.reports?.length == 0);
};

const FormSpeakerRole: React.FC<Props> = ({validator, profile, onChange, work = true, online=true}) => {
    const update = (field: string) => {
        const v = __transliterate(profile(field) || "");
        if (v !== profile(`${field}_en`))
            setTimeout(() => onChange({target: {name: `${field}_en`, value: v}}), 1000);
        return v || "";
    };

    const disabled = !speakerCanChangeData(profile());

    return (
        <React.Fragment>
            {__locale() !== LangEnum.EN && (
                <>
                    <KdwRawTextField
                        required
                        validator={validator}
                        onChange={onChange}
                        name="surname_en"
                        disabled={disabled}
                        label={__translate("signup.surname_en", "Фамилия на английском")}
                        value={profile("surname_en") || update("surname")}
                    />
                    <KdwRawTextField
                        required
                        validator={validator}
                        onChange={onChange}
                        name="name_en"
                        disabled={disabled}
                        label={__translate("signup.name_en", "Имя на английском")}
                        value={profile("name_en") || update("name")}
                    />
                </>)}

            {work && <WorkInfoForm profile={profile} validator={validator} onChange={onChange} en_fields={true}/>}

            <KdwRawTextField
                name="academic_degree"
                disabled={disabled}
                label={__translate("signup.degree", "Ученая степень")}
                inputProps={{placeholder: __translate("signup.degree_placeholder", "Если  вы доктор технических наук, просим писать  д.т.н")}}
                validator={validator}
                onChange={onChange}
                value={profile("academic_degree")}
            />
            <KdwRawTextField label="">
                <div style={{cursor:"pointer"}} onClick={() => {window.open(`/upload/doc/degree_rules_${__locale()}.pdf`, "_blank")}}>{__translate("profile.degree_rules", "Посмотреть все сокращения")}</div>
            </KdwRawTextField>
            {online && <KdwRawTextField
                label={__translate("signup.online", "Тип участия")}
                validator={validator}
                name="online"
                required>
                <KdwCheckBox
                    value={profile("online") == false}
                    name="online"
                    disabled={disabled || profile("online") == false}
                    onChange={(e) => onChange({target: {name: "online", value: false}})}
                    labelText={__translate("signup.online_no", "Очное")}
                />
                <KdwCheckBox
                    value={profile("online") == true}
                    name="online"
                    disabled={disabled || profile("online") == true}
                    onChange={(e) => onChange({target: {name: "online", value: true}})}
                    labelText={__translate("signup.online_yes", "Онлайн")}
                    errorText={validator('online')}
                />
            </KdwRawTextField>}
        </React.Fragment>
    );
};

export default FormSpeakerRole;
