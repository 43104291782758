import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { format } from "date-fns";

export const apiStatDataAction = "apiStatData";
export const apiStatByDatesDataAction = "apiStatByDatesData";
export const apiStatByLocationDataAction = "apiStatByLocationData";
export const apiStatByMonthDataAction = "apiStatByMonthData";
export const apiStatPaysByRoleAction = "apiStatPaysByRole";
export const apiStatCountsAction = "apiStatCounts";
export const apiStatPayUsersByRoleAction = "apiStatPayUsersByRole";

const getParams = (params: any) => {
    let r = { ...params } as any;
    if (params?.startDate) r.startDate =(params.startDate);
    if (params?.endDate) r.endDate =    (params.endDate  );
    return r;
};
/**
 * @route api/user
 * @delete
 * @post
 */
export const apiStatData = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram?` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};
export const apiStatByDatesData = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram/by-dates?` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};
export const apiStatByLocationData = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram/by-location?` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};

export const apiStatByMonthData = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram/by-month?` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};
export const apiStatPaysByRole = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram/pays-by-role?` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};
export const apiStatPayUsersByRole = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram/pays-by-role/${params.role}?` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};
export const apiStatCounts = async (params: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/diagram/counts` + new URLSearchParams(getParams(params)).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response);
};

export default {
    apiStatData,
    apiStatByDatesData,
    apiStatByLocationData,
    apiStatByMonthData,
    apiStatPaysByRole,
    apiStatPayUsersByRole,
    apiStatCounts,
} as any;
