import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import {Grid} from "@material-ui/core";
import {useSelector} from "react-redux";
import {
    reselect_Constants_Roles,
    reselectConstants
} from "../../controllers/constants-route/redux-store/constants/constants";
import {RegistrationRole} from "../../constants/constants";
import Check from "../appicons/Check";
import TypographyTranslate from "../TypographyTranslate/TypographyTranslate";
import {format} from "date-fns";

export const colorsByRole = {
    [RegistrationRole.PARTICIPANT_PREMIUM] : '#5fd3b3',
    [RegistrationRole.PARTICIPANT_ECONOM] : '#EEEEEE',
    [RegistrationRole.ORGANIZER] : '#a41522',
    [RegistrationRole.VOLUNTEER] : '#dd2b1c',
    [RegistrationRole.SPEAKER] : '#c4c4c5',
    [RegistrationRole.EXPONENT] : '#809bd4',
};

const roleTranslate = (role:number, fk:string, def: string) => {
    const suf = "_" + role;
    let ft = __translate(fk + suf);
    if (ft == (fk + suf)) ft = __translate(fk, def); //если нет такого первода используем стандартный
    return ft;
}
export const roleIsOpen = (dates:any, role:any, open_dates?: any) => {
    const now = new Date().getTime() / 1000;
    if (open_dates && open_dates[role] && (open_dates[role] > now)) return null;
    return  dates?.[role] > now &&  format(dates?.[role] * 1000, 'dd.MM')|| null;
}
export const roleOpenDate = (open_dates:any, role:any) => {
    if (!open_dates?.[role] || open_dates?.[role] < (new Date().getTime()/1000)) return null;
    return  format((open_dates?.[role])  * 1000, 'dd.MM')|| null;
}
const RoleCard = ({role = 0 as number, features = (r: number, s = false) =>[] as Array<string>, free = true, disabled = false, ...props}) => {
    const classes = useStyles();
    const roles = useSelector(reselect_Constants_Roles);
    const active = role === props.current;
    const className = classes.roleCard + " " + (active && (classes.activeRoleCard)) + " "
        + (!props.small ? '' : (classes.small + ' '))+ props.className + ' ' + (classes as any)?.[props.className] ;
    const list = features(role);
    const {dates, open_dates} = useSelector(reselectConstants);
    const openDate = roleOpenDate(open_dates, role);
    const closeDate = roleIsOpen(dates, role, open_dates);
    const regOpen = !disabled && closeDate && props.onClick;
    const onClick = () => {regOpen && props.onClick(role);};
    return (
        <div className={className + (" " + (props.abs && classes.absCard))} onClick={onClick}>
            {regOpen && !props.hideCheck && <Grid container className={classes.checkContainer}>
                {active && <Check className={classes.check} style={{color: "white"}}/>}
            </Grid>}
            <Grid container>
                {!props.small && <Grid item xs={3}  className={classes.iconContainer} style={{backgroundColor:props?.color || colorsByRole[role] || "#d9d9d9"}}>
                    <div className={classes.icon}>{props.icon_child}</div>
                </Grid>}
                <Grid item sm={12} md={9}>
                    <div className={classes.title + ( !active && (role === RegistrationRole.PARTICIPANT_PREMIUM)  ? " " + classes.titleMain : "") + " " + (active && classes.activeTitle)}>
                        {props?.label || roles && roles?.[role]}
                        {props.subtitle_child}
                    </div>
                    <div className={props.hover && classes.desc}>
                        {(props.abs||props.hover) &&  list?.map((v) => (<p className={classes.feature}>{(list?.length > 1 && " - " || "") + v}</p>))}
                    </div>
                    {props.price_child || role <= RegistrationRole.SPEAKER && !props.price_child && <span className={classes.cost}>
                        {free ? roleTranslate(role, "profile.free", "Участие бесплатное")
                            : roleTranslate(role,"profile.not_free", "Участие платное")}
                    </span>}
                    {!props.onClick && !props.children && <div>
                        <p className={classes.feature}>{roleTranslate(role, "profile.role_later", "Будет доступно позже")}</p>
                    </div>}
                    {props.children && <p className={classes.children}>{props.children}</p>}
                    {props.dates && ((!closeDate || disabled) ? <p className={classes.children}>
                            <TypographyTranslate value={"signup.role_closed" + (openDate ? '_and_date':'')} ruVal="Регистрация закрыта" afterValue={(openDate ? ' ' + openDate : '')}/>
                    </p> : closeDate && <p className={classes.children}>
                        <TypographyTranslate value="signup.role_opened" ruVal={"Регистрация открыта по "} afterValue={' ' + closeDate}/>
                    </p>)}
                </Grid>
            </Grid>
        </div>
    );
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        roleCard: {
            flex: "1 1 220px",
            flexDirection: "row",
            padding: "20px",
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "20px",
            color: "#6F6F6F",
            opacity: 0.5,
            cursor: "pointer",
            overflow:"hidden",
            '&:hover': {
                opacity:1,
                overflow:"visible",
            },
            '&:hover $desc': {
                display: "block",
            },
            '&:hover $subtitle': {
                display: "none",
            },
            [theme.breakpoints.down(600)]: {
                padding: "15px",
            },
            '&:hover $absCard' : {
                opacity:1,
                display:"block",
                height:"auto"
            }
        },
        absCard: {
            display:"none",
            height: "100%",
            width: "calc(100% - 40px)",
            position:"absolute",
            top:0,
            left:0,
            zIndex:100,
            boxShadow: "0px 0px 22px rgba(0, 0, 0, 0.07)",

        },
        title: {
            fontWeight: 600,
            fontSize: "30px",
            lineHeight: "39px",
            marginBottom: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            color: "#13C4A4",
            [theme.breakpoints.down(1600)]: {
                fontSize: "25px",
            },
            [theme.breakpoints.down(1400)]: {
                fontSize: "20px",
            },
        },
        subtitle: {
            maxHeight: "20px",
            fontSize: "20px",
            [theme.breakpoints.down(1600)]: {
                fontSize: "15px",
            },
            [theme.breakpoints.down(1400)]: {
                fontSize: "11px",
            },
        },
        activeRoleCard: {
            opacity: 1,
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
            color: "#fff !important",
            '& $checkContainer' : {
                borderColor: "#fff !important"
            }
        },
        doc1: {
            background: "#dfdddd",
            border: "1px solid #008a71"
        },
        doc2: {
            background: "linear-gradient(0deg, #EC4723 5%, #DA4100 46%, #D55D2A 87%)",
        },
        doc8: {

            background: "linear-gradient(82.35deg, #DA9D00 0%, #FFDB20 50%, #ECCC23 100%)",
        },
        activeTitle: {
            color: "#fff !important"
        },
        small: {
            paddingLeft: "0px",
            '& $title': {
                fontSize: "20px",
                lineHeight: "22px",
            },
            opacity: "1",
        },
        titleMain: {
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
            ["-webkit-background-clip"]: "text",
            ["-webkit-text-fill-color"]: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            fontWeight: 700,
        },
        feature: {
            fontSize: "14px",
            lineHeight: "16px",
            paddingLeft: "20px",
        },
        children: {
            margin: 0,
            paddingLeft: "20px",
        },
        cost: {
            marginTop: "10px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "16px",
            paddingLeft: "20px",
        },
        iconContainer: {
            position:"relative",
            backgroundColor: "#d9d9d9",
            overflow:"hidden",
            paddingBottom: "25%",
            height:0,
            borderRadius: "10px",
            [theme.breakpoints.down(900)]: {
                display: "none"
            },
        },
        icon: {
            width: "100%",
            height: 0,
            position:"absolute",
            '& div:first-child' : {
                // display: "none",
            },
            '& div' : {
                width: "100%",
                paddingBottom: "100%",
                height: "100%",
                borderRadius: "10px",
                backgroundSize:"cover",
            }
        },
        check: {
            position: "absolute",
            top: "5px",
            right: "3px",
            [theme.breakpoints.down(1400)]: {
                width: "10px",
                height: "10px",
            },
        },
        desc: {
            display: "none",
        },
        checkContainer: {
            position:"absolute",
            width: "35px",
            height: "35px",
            border: "2px solid green",
            top: "20px",
            right: "20px",
            [theme.breakpoints.down(1400)]: {
                width: "20px",
                height: "20px",
                right: "10px",
            },
        }
    }),
);
export default RoleCard
