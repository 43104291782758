import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiAdminExpoGet, apiAdminExpoIndex } from "../api/admin-expo-get-list";
import { Expo } from "controllers/admin-expo/interfaces/admin-expo";
import {KdwCondition, makeClearPaginator, Paginator} from "helper/redux";
import { expoLeaderRegistrations } from "../../../registration-expo-route/api";
import { initStateMain, initStateOthers } from "../../../../components/Site/Partners/PartnersBlock/PartnersBlock";
import { IPartner } from "../../../../components/types/partners";
import { EXPONENTS_TYPE } from "../../../../constants/site/common";
import { OFFICIAL_AUTO_PARTNER } from "constants/site/exponents/companiesOnMainPage";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};


export type ExpoFilter = {
    name?: string;
    forum_themes?: [];
    priority_desc?: number;
    state?: number;
    itleader?: number;
    types?: any[];
    per_page?: number;
};

const expoFilterInitial: ExpoFilter = {
    name: "",
    forum_themes: [],
    priority_desc: null,
};

export const slicerAdminExpoIndex = createSlice({
    name: "admin-expo-index",
    initialState: {
        paginator: makeClearPaginator(),
        list: [] as Array<Expo>,
        selectList: [],
        leaderList: [] as Array<Expo>,
        mainPartnersList: [] as Array<IPartner>,
        partnersList: [] as Array<IPartner>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
        filter: expoFilterInitial,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            console.log("onNoResponse");
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<Expo>;
                current_page: number;
                message: string;
                result: boolean;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.current_page,
                first_page_url: action.payload.first_page_url,
                from: action.payload.from,
                last_page: action.payload.last_page,
                last_page_url: action.payload.last_page_url,
                next_page_url: action.payload.next_page_url,
                path: action.payload.path,
                per_page: action.payload.per_page,
                prev_page_url: action.payload.prev_page_url,
                to: action.payload.to,
                total: action.payload.total,
            };
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onListChange: (state, action) => {
            state.list = action.payload.data;
        },
        onLeaderRequestsChange: (state, action) => {
            state.leaderList = action.payload.data;
        },
        onResponseSuccessPartners: state => {
            state.condition.inProgress = false;
        },
        onLoadMainPartners: (state, action) => {
            state.mainPartnersList = Object.values(action.payload);
        },
        onLoadPartners: (state, action) => {
            state.partnersList = Object.values(action.payload);
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.filter);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
        onLoadSelectList: (state, action) => {
            state.selectList = [...action.payload];
        },
        changeExpoAdminListItem: (state, action) => {
            state.list = [
                ...state.list.map(v => {
                    return v.id !== action.payload.id ? v : action.payload;
                }),
            ];
        },
        resetExpoFilter: state => {
            state.filter = expoFilterInitial;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onChange,
    resetExpoFilter,
    onLoadSelectList,
    onListChange,
    onLeaderRequestsChange,
    changeExpoAdminListItem,
    onResponseSuccessPartners,
    onLoadMainPartners,
    onLoadPartners,
} = slicerAdminExpoIndex.actions;

export const asyncAdminExpoIndex = (
    page: number,
    filterParams?: ExpoFilter,
    exportXLS?: boolean,
    callback?: (result: any) => void,
    per_page?: number,
) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiAdminExpoIndex(page, filterParams, exportXLS, per_page).catch(e => {
        console.log(e);
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
        dispatch(onListChange(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncLeaderParticipants = (filterParams?: any, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(onRequest());
    const result = await expoLeaderRegistrations(filterParams).catch(e => {
        console.log(e);
    });
    if (result) {
        dispatch(result.response?.result ? onResponseSuccess(result?.nativeResponse.body) : onResponseFail(result));
        dispatch(onLeaderRequestsChange(result.response));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncExpoSelectList = (filterParams?: ExpoFilter, callback?: (result: any) => void) => async (
    dispatch: any,
    state: any,
) => {
    dispatch(onRequest());
    const response = await apiAdminExpoIndex(1, filterParams, false, -1);
    if (response?.result) dispatch(onLoadSelectList(response.data));
    else dispatch(onNoResponse());
    if (callback) {
        callback(response);
    }
};
export const asyncExpoGet = async (id: number, callback?: (result: KdwCondition) => void) => {
    const condition = await apiAdminExpoGet(id);
    callback && callback(condition);
};

export const asyncPartners = (filterObjArray: any, main?: boolean, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(onRequest());
    const requests = [filterObjArray].map(filter => apiAdminExpoIndex(1, filter, false, -1));
    const result = await Promise.all(requests)
        .then(res => {
            res.forEach((partners, index) => {
                partners?.data?.forEach((partner: any, ind: number) => {
                    partner?.types?.ru?.forEach((type: any) => {
                        let filterType = Number.parseInt(type);
                        if (filterObjArray.types.findIndex((v: any) => v == filterType) == -1) return;
                        const initState =
                            main &&
                            (filterType == EXPONENTS_TYPE.business_program_partner ||
                                filterType == EXPONENTS_TYPE.official_partners)
                                ? initStateMain
                                : initStateOthers;
                        if (initState[`ab${filterType}`]?.split) {
                            initState[`ab${filterType}_${ind}`] = {
                                ...initState[`ab${filterType}`],
                                cards: [partner] || [],
                            };
                        } else {
                            let v = initState[`ab${filterType}`];
                            initState[`ab${filterType}`] = {
                                ...v,
                                cards: [...(Array.isArray(v?.cards) ? v?.cards : [v?.cards]).filter(v => !!v), partner],
                            };
                        }
                    });
                });
            });
            return Object.values(
                main
                    ? {
                          ...initStateMain,
                          //   Когда-нибудь здесь все будет по-человечески, а сейчас это сделано чтобы ТТС добавился в конце (
                          [`ab${EXPONENTS_TYPE.official_auto_partner}`]: {
                              case: EXPONENTS_TYPE.official_auto_partner,
                              heading: "Официальный автомобильный партнер",
                              key: "site.official_auto_partner",
                              cards: [],
                              // cards: OFFICIAL_AUTO_PARTNER,
                              // background: "linear-gradient(137deg, #3D3D3D 14.85%, #212121 76.7%)",
                          },
                      }
                    : initStateOthers,
            );
        })
        .catch(e => {
            console.log(e);
        });
    if (result) {
        dispatch(onResponseSuccessPartners());
        dispatch(
            onLoadMainPartners({
                ...initStateMain,
                //   Когда-нибудь здесь все будет по-человечески, а сейчас это сделано чтобы ТТС добавился в конце (
                [`ab${EXPONENTS_TYPE.official_auto_partner}`]: {
                    case: EXPONENTS_TYPE.official_auto_partner,
                    heading: "Официальный автомобильный партнер",
                    key: "site.official_auto_partner",
                    cards: [],
                    // cards: OFFICIAL_AUTO_PARTNER,
                    // background: "linear-gradient(137deg, #3D3D3D 14.85%, #212121 76.7%)",
                },
            }),
        );
        dispatch(onLoadPartners(initStateOthers));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        return callback(result);
    }
};

export const reselectExpoList = (state: RootState): Array<any> => {
    return state.adminExpo.index.list;
};
export const reselectExpoSelectList = (state: RootState): Array<any> => {
    return state.adminExpo.index.selectList;
};
export const reselectExpoLeaderList = (state: RootState): Array<any> => {
    return state.adminExpo.index.leaderList;
};
export const reselectExpoPaginator = (state: RootState): Paginator => {
    return state.adminExpo.index.paginator;
};
export const reselectExpoCondition = (state: RootState): Response => {
    return state.adminExpo.index.condition;
};
export const reselectMainPartnersList = (state: RootState): Array<IPartner> => {
    return state.adminExpo.index.mainPartnersList;
};
export const reselectPartnersList = (state: RootState): Array<IPartner> => {
    return state.adminExpo.index.partnersList;
};

export const reselectAdminExpoFilter = (state: RootState): ExpoFilter => {
    return state.adminExpo.index.filter;
};

export default slicerAdminExpoIndex.reducer;
