import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/cost
 * @get
 * @post
 */
export const apiPriceGet = async (data: any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/cost?` + new URLSearchParams({...data, per_page:data.per_page || -1}).toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
