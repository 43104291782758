import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import {useParams} from "react-router-dom";
import {RegistrationRole} from "constants/constants";
import {useStyles} from "pages/KdwUserPage";
import FormSpeakerRole from "components/Form/FormSpeakerRole";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";

const makeStyles1 = makeStyles((theme) => ({
    title: {
        marginTop: "20px",
    },
    row:{
        display:"flex",
        gap:"20px",
        alignItems: "center",
        ['& > div:last-child']: {
            cursor: "pointer",
            marginTop: "20px"
        }
    }
}));
function ProfileData(props: any) {
    const { accessor, onChange, validator, onClose, isOpen } = props;
    const params = useParams<{ ru: string }>();
    const localClasses = makeStyles1();
    const classes = useStyles();
    const canEdit = accessor('role') === RegistrationRole.SPEAKER || accessor('role') === RegistrationRole.PARTICIPANT_PREMIUM;
    const [edit,setEdit] = useState(false);
    useEffect(() => {
        setEdit(false);
    }, []);
    return (
        <>

            {canEdit && <div className={localClasses.row}>
                <div className={classes.grayBlockTitle + " " + localClasses.title}>
                    <span>{__translate("profile.profile_data", "Профильные данные")}</span>
                </div>
                {!edit && <div onClick={() => setEdit(true)}>
                    <TypographyTranslate value="participation.edit_profile" ruVal="Изменить"/>
                </div>}
            </div>}
            {canEdit && edit &&
                <div className={classes.formPaper + ' ' + classes.formPaperForm}>
                    <FormSpeakerRole {...{profile:accessor, validator, onChange}}/>
                </div>
            }
        </>
    );
}

export default ProfileData;
