import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiUserGet } from "controllers/admin-user-get/api/admin-user-get/admin-user-get";
import { Constants } from "controllers/constants-route/redux-store/constants/constants";
import { RegistrationAcademicDegree, RegistrationRole } from "constants/constants";
import {TYPE_USER_PROFILE as User} from "../../../profile-route/redux-store/profile/profile";

export type Response = {
    inProgress: boolean;
    index: number | string;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type Type_Proxy_User = {
    report_event_id?: any;
    amount: string;
    report_annotation_file: string;
    report_title: string;
    photo: any;
    forum_themes: any[];
    report_forum_theme: any;
    academic_degree: any;
    academic_degree_other: string;
    source: number;
    online: number;
    cyber: number;
    sector: string;
    need_pay: boolean;
};

export const FILE_STORAGE_ADMIN_GET_User: any = {
    annotation_file: null,
    photo: null,
};

export const slicerAdminUserGet = createSlice({
    name: "admin-user-GET",
    initialState: {
        dirty: {} as any,
        view: null as User,
        enitity: null as User,
        proxyEntity: {
            report_annotation_file: "",
            report_title: "",
            photo: "",
            forum_themes: [] as Array<any>,
            report_forum_theme: null,
            academic_degree: "",
            academic_degree_other: "",
            source: -1,
            sector: "",
            online: 0,
            cyber: 0,
            need_pay: false,
        } as Type_Proxy_User,
        condition: {
            index: 0,
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        on_Fill_AdminUser_GET: state => {
            state.view = {} as User;
            state.proxyEntity = {
                report_annotation_file: "",
                amount: "",
                report_title: "",
                photo: "",
                forum_themes: [],
                report_forum_theme: null,
                academic_degree: "",
                academic_degree_other: "",
                source: -1,
                sector: "",
                online: 0,
                cyber: 0,
                need_pay: false,
            };
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onClear: state => {
            state.view = null;
            state.proxyEntity = {
                amount: "",
                report_annotation_file: "",
                report_title: "",
                photo: "",
                forum_themes: [],
                report_forum_theme: null,
                academic_degree: "",
                academic_degree_other: "",
                source: -1,
                sector: "",
                online: 0,
                cyber: 0,
                need_pay: false,
            };
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onConditionClear: state => {
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: (state, action) => {
            state.view = null;
            state.proxyEntity = {
                amount: "",
                report_annotation_file: "",
                report_title: "",
                photo: "",
                forum_themes: [],
                report_forum_theme: null,
                academic_degree: "",
                academic_degree_other: "",
                source: -1,
                sector: "",
                online: 0,
                cyber: 0,
                need_pay: false,
            };
            state.condition = {
                index: action.payload,
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        on_ResponseFailure: (
            state,
            action: PayloadAction<string> | PayloadAction<{ data: any; errors: any; message: string; result: boolean }>,
        ) => {
            if (typeof action.payload === "string") {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: action.payload,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            } else {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: `User with id: ${state.condition.index} ${action.payload.message}`,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            }
        },
        // message: "responses.controllers.participant.show"
        // result: true
        on_ResponseSuccess: (
            state,
            action: PayloadAction<{
                data: User;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.dirty = {} as any;
            state.view = action.payload.data;
            state.proxyEntity = {
                amount: "",
                report_annotation_file: "",
                report_title: "",
                photo: "",
                forum_themes: [],
                report_forum_theme: null,
                academic_degree: "",
                academic_degree_other: "",
                source: -1,
                sector: "",
                online: 0,
                cyber: 0,
                need_pay: false,
            };
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `User ${state.condition.index}: ${action.payload.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        setAdminUserStateView: (state, action: PayloadAction<{ response: any; proxyContent: Constants }>) => {
            FILE_STORAGE_ADMIN_GET_User.photo = null;
            state.view = action.payload.response.data;
            state.dirty = {} as any;
            state.proxyEntity = {
                amount: action.payload.response.data.recommended_cost || "",
                report_forum_theme: null,
                report_annotation_file: "",
                report_title: "",
                photo: "",
                forum_themes: action.payload.response.data.forum_themes || [],
                need_pay: action.payload.response.data.pay_status === 0,
                academic_degree: action.payload.response.data.academic_degree,
                academic_degree_other: action.payload.response.data.academic_degree_other,
                source: Number(action.payload.proxyContent?.user?.sources?.[action.payload.response.data.source]),
                sector: action.payload.proxyContent?.user?.sectors?.[action.payload.response.data.sector],
                online: Number(action.payload.response.data.online),
                cyber: Number(action.payload.response.data.cyber),
            };
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: action.payload.response.result,
                    data: action.payload.response.data,
                    message: `User ${state.condition.index}: ${action.payload.response.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        on_Change_Proxy_AdminUser_GET: (
            state,
            action: PayloadAction<{ name: string; value: any; files?: any; checked?: boolean }>,
        ) => {
            const { name, value, files, checked } = action.payload;

            switch (name) {
                case "report_title": {
                    state.proxyEntity.report_title = value;
                    break;
                }
                case "report_forum_theme": {
                    state.proxyEntity.report_forum_theme = value.toString();
                    break;
                }
                case "report_event_id": {
                    state.proxyEntity.report_event_id = value?.id;
                    break;
                }
                case "report_annotation_file": {
                    if (action.payload.files) {
                        state.proxyEntity.report_annotation_file = files[0].name;
                        FILE_STORAGE_ADMIN_GET_User.annotation_file = files;
                    } else {
                        state.proxyEntity.report_annotation_file = "";
                        FILE_STORAGE_ADMIN_GET_User.annotation_file = null;
                    }
                    break;
                }
                case "photo": {
                    FILE_STORAGE_ADMIN_GET_User.photo = value;
                    break;
                }
                case "forum_themes": {
                    if (checked == undefined) {
                        state.proxyEntity.forum_themes = value;
                    } else if (checked) {
                        state.proxyEntity.forum_themes.push(value);
                    } else {
                        state.proxyEntity.forum_themes = state.proxyEntity.forum_themes.filter(v => v != value);
                    }
                    break;
                }
                case "academic_degree": {
                    state.proxyEntity.academic_degree = value as string;
                    if (Number(value) !== RegistrationAcademicDegree.OTHER) {
                        state.proxyEntity.academic_degree_other = "";
                    }
                    break;
                }
                case "need_pay": {
                    state.dirty = { ...state.dirty, amount: true };
                    state.proxyEntity.need_pay = checked;
                    break;
                }
                case "amount":
                    state.dirty = { ...state.dirty, amount: true };
                    state.proxyEntity[name] = value;
                    break;
                case "academic_degree_other":
                    state.proxyEntity[name] = value;
            }
        },
        on_Change_AdminUser_GET: (state,
                                  action: PayloadAction<{ name: string; value?: any; checked?: boolean }>) => {
            const { name, value, checked } = action.payload;
            state.dirty = { ...state.dirty, [name]: true };
            switch (name) {
                case "reports": {
                    // @ts-ignore
                    state.view.reports.push({ ...value, state: Number(value.state) });
                    break;
                }
                default:{
                    // @ts-ignore для отправки в основном объекте тела запроса
                    state.view[name] = checked === undefined ? value : checked;
                    break;
                }
            }
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    on_ResponseSuccess,
    on_Fill_AdminUser_GET,
    setAdminUserStateView,
    on_ResponseFailure,
    onNoticeHide,
    onClear,
    on_Change_AdminUser_GET,
    on_Change_Proxy_AdminUser_GET,
} = slicerAdminUserGet.actions;

export const async_GetUser_admin = (userIndex: User["id"], callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest(userIndex));
    const result = await apiUserGet(userIndex).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        const q = getState().constants;
        dispatch(
            result.result
                ? setAdminUserStateView({ response: result, proxyContent: q.view })
                : on_ResponseFailure(result),
        );
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectUserGet = (state: RootState): User | null => {
    return state.userGet.view;
};

export const reselect_Admin_User_Get = (state: RootState): User | null => {
    return state.userGet.view;
};
export const reselect_Admin_User_Get_DIRTY = (state: RootState): any => {
    return state.userGet.dirty;
};

export const reselect_GetUser_admin_proxy = (state: RootState): Type_Proxy_User => {
    return state.userGet.proxyEntity;
};

export const reselectUserGetCondition = (state: RootState): Response => {
    return state.userGet.condition;
};

export default slicerAdminUserGet.reducer;
