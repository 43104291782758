import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import __translate from "helper/locale/locale";

const TypographyTranslate: React.FC<{
    props?: TypographyProps;
    value: string;
    ruVal?: string;
    component?: React.ElementType;
    afterValue?: string;
}> = ({ props = {}, value, ruVal, afterValue = "", component = "span"}) => {
    try {
        return (
            <Typography {...props} component={component} {...{["keyProp"]: value}}>
                {__translate(value, ruVal ? ruVal : value)}
                {afterValue}
            </Typography>
        );
    } catch (e){
        return <></>
    }
};

export default TypographyTranslate;
