import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import {useParams} from "react-router-dom";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import {useStyles} from "pages/KdwUserPage";

const makeStyles1 = makeStyles((theme) => ({
    paper: {
        marginBottom: "40px",
        paddingBottom: "17px",
        paddingTop: "17px",
    },
    cont: {
        [theme.breakpoints.up(900)]: {
            '& div:first-child': {
                flexGrow: 1,
            }
        },
        [theme.breakpoints.down(900)]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap:"10px",
        },}
}));
function BottomBar(props: any) {
    const { accessor, onChange, validator, hideInputs} = props;
    const params = useParams<{ ru: string }>();
    const classes = useStyles();
    const localClasses = makeStyles1();
    const [other, setOther] = React.useState(false);
    return (
        <div className={classes.formPaper + ' '+ classes.formPaperForm  + " " + localClasses.paper + " " + props.className}>
            <Grid container className={localClasses.cont} justify="space-between">
                <Grid item justify={"flex-start"}>
                    {!hideInputs && (props.inputChildren || <KdwRawTextField
                        validator={validator}
                        name={"promo"}
                        onChange={onChange}
                        value={accessor("promo")}
                        label={__translate("signup.promo", 'Введите промокод')}
                    />)}
                </Grid>
                <Grid item justify={"flex-end"} alignItems={"center"} style={{display: "flex", paddingTop: 5}}>{props.children}</Grid>
            </Grid>
        </div>
    );
}

export default BottomBar;
