import React from "react";
// import { KDW_YEAR } from "../constants";

export const ARTICLE_FORMATTING_LINK = {
    desc: {
        ru: "Правила оформления статей",
        en: "Article formatting requirements",
        cn: "",
    },
    link: {
        ru: "/upload/doc/article_formatting_requirements.pdf",
        en: "/upload/doc/article_formatting_requirements_en.pdf",
        cn: "",
    },
};

export const ARTICLE_POLICY_LINK = {
    desc: {
        ru: "Положение о конкурсе научных статей",
        en: "Regulations on the competition of scientific articles",
        cn: "",
    },
    link: {
        ru: "/upload/doc/article_policy.pdf",
        en: "/upload/doc/article_policy.pdf",
        cn: "",
    },
};

export const INVITATION_TO_PUBLICATION_LINK = {
    desc: {
        ru: "Приглашение к публикации статей",
        en: "Invitation to publication",
        cn: "",
    },
    link: {
        ru: "/upload/doc/invitation_to_publication.pdf",
        en: "/upload/doc/invitation_to_publication.pdf",
        cn: "",
    },
};

export const PUBLICATIONS_CONTENT = {
    ru: [
        <div>
            <b>Приглашаем к публикации статей</b>
            <br /> Статьи принимаются в строгом соответствии с тематическими направлениями.
            <br /> Автор должен указать тематическое направление статьи.
        </div>,
        <div>
            <br />
            {/* <b>Статьи принимались до 15 июля {KDW_YEAR} года</b> */}
            <b>Статьи принимаются до 1 июля 2025 года</b>
        </div>,
        <span>
            <b>Все статьи проходят проверку программой «Антиплагиат» и рецензирование.</b>
            <br />
            После отбора экспертной группой статьи будут опубликованы в сборнике материалов форума.
        </span>,
        <span>Возможна публикация в журналах, рецензируемых ВАК и индексируемых в РИНЦ.</span>,
    ],
    en: [
        <div>
            <b>We invite you to publish articles</b>
            <br /> Articles are accepted in strict accordance with thematic areas.
            <br /> The author must indicate the thematic direction of the article.
        </div>,
        <div>
            <br />
            {/* <b>Articles are accepted until July 15 {KDW_YEAR}</b> */}
            <b>Articles are accepted until July 15 2025</b>
        </div>,
        <span>
            <b>All articles are checked by the Anti-Plagiarism program and reviewed.</b>
            <br />
            After selection by the expert group, the articles will be published in the collection of forum materials.
        </span>,
        <span>
            Publication in journals peer-reviewed by the Higher Attestation Commission and indexed in the Russian
            Science Citation Index is possible.
        </span>,
    ],
    cn: [""],
};

export const JOURNAL_LINK = {
    ru: "https://vestnikncbgd.ru/ru/",
    en: "https://vestnikncbgd.ru/en/",
    cn: "",
};
