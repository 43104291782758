import React from "react";
import TypographyTranslate from "../TypographyTranslate/TypographyTranslate";
import {makeStyles} from "@material-ui/core";

const styles = makeStyles((t) => ({
    success: {
        display: "flex",
        marginLeft: 15,
        marginTop: -10,
        color: "#008A71",
    },
}));

const KdwTextFieldGreenBottomLabel: React.FC<any> = ({...props}) => {

    const localClasses = styles();

    return (
        <div className={localClasses.success} id="success">
            {props.children}
        </div>
    )
};
export default KdwTextFieldGreenBottomLabel;
