import React from "react";
import { Button, Grid, GridSize, Typography } from "@material-ui/core";
import __translate from "../../helper/locale/locale";
import { STATE_APPROVED, STATE_EDIT, STATE_REJECTED } from "../../controllers/articles/page/SpeakerReport/SpeakerReport";
import { TYPE_ARTICLE } from "../../controllers/profile-route/redux-store/profile/profile";
import __locale from "../../helper/location/location";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { reselectConstants } from "../../controllers/constants-route/redux-store/constants/constants";

export type WITH_STATUS = {
    status: {
        state: number,
        message: string,
        message_file: {
            name: string;
            file: string;
        };
    };
}
type Props = {
    item: WITH_STATUS;
    xs?: GridSize;
    states: any
};
const ReportStatusBar: React.FC<Props> = ({ xs = 12, item, states }) => {

    const state = item?.status?.state;
    if (state === undefined) return <></>;
    return (
        <>
            <Grid item xs={xs} style={{ marginTop: "1em" }}>
                <Grid
                    container
                    spacing={1}
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Grid item>
                        <Grid container direction="row">
                            <Typography
                                variant="h5"
                                style={{
                                    textTransform: "uppercase",
                                    marginRight: "5px",
                                }}>
                                {__translate("profile.status_title", "Статус").concat(":  ")}
                            </Typography>
                            <Typography
                                style={{
                                    textTransform: "uppercase",
                                    marginRight: "20px",
                                }}>
                                {(states?.[state] || "") + " "}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/*тут была кнопка оплаты*/}
                    </Grid>
                </Grid>
                <Grid container>
                    {item?.status?.message && (
                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                            <Typography style={{ marginRight: "5px" }}>
                                {__translate("profile.comment", "Комментарий").concat(":  " + item.status?.message)}
                                {item?.status?.message_file && (
                                    <a target="_blank" style={{ margin: "1em" }} href={item.status?.message_file.file}>
                                        Файл к комментарию
                                    </a>
                                )}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ReportStatusBar;
