import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux-store";
import {editPromoApi, getCompanyApi, postCompanyApi, postExpoCompany} from "./api";
import {
    makeBaseReduxAction,
    makeClearCondition,
    makeClearPaginator,
    makeConditionReducers,
    Paginator,
} from "helper/redux";
import {Company} from "controllers/companies/redux";



export const slicer_Companies = createSlice({
    name: "companies",
    initialState: {
        paginator: makeClearPaginator(),
        company: {} as any,
        user: {} as any,
        condition: makeClearCondition(),
    },
    reducers: {
        ...makeConditionReducers(),
        onExpoCompanyFormChange: (state, action) => {
            const {name, value, model} = action.payload.target;
            if(typeof value  === typeof ({} as Company)) {
                state.company = {...value, ...state.company};
                state.user = {...value.user, ...state.user}
                return;
            }
            if (model == 'user') {
                state.user[name] = value;
            } else {
                state.company[name] = value;
            }
        },
        onSuccessExpoCompany: (state) => {
            state.user = {};
            state.company = {};
        }
    },
});

export const {
    onRequest,
    onNoticeHide,
    stopLoading,
    onExpoCompanyFormChange,
    onFail,
    clearCondition,
    onSuccessExpoCompany,
} = slicer_Companies.actions;

const baseStateAsync = makeBaseReduxAction(
    (dispatch: any) => {
        dispatch(onRequest());
    },
    (dispatch, r) => {
        if (r) {
            dispatch(onFail(r));
        }
    },
    dispatch => {
        dispatch(stopLoading());
    },
);

export const asyncSaveExpoCompany = (body: any, callback?: (result: any) => void) => {
    return baseStateAsync(postExpoCompany, body, callback, (dispatch, result) => {
        if(result?.response.result) {
            dispatch(onSuccessExpoCompany())
            dispatch(clearCondition())
        }
        // dispatch(onLoadItem(result))
    });
};


export const reselectExpoCompany = (state: RootState) => {
    return state.companies.site.company;
};
export const reselectExpoCompanyUser = (state: RootState) => {
    return state.companies.site.user;
};
export const reselectExpoCompanyCondition = (state: RootState) => {
    return state.companies.site.condition;
};
export const reselectExpoCompaniesPaginator = (state: RootState) => {
    return state.companies.site.paginator;
};

export default slicer_Companies.reducer;
