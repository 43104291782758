import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, IconButton} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import __translate from "helper/locale/locale";

import { makeStyles } from "@material-ui/core/styles";
import LinkButton from "../UI/LinkButton/LinkButton";
import ExponentRequestForm from "./ExponentRequestForm";
import {useDispatch, useSelector} from "react-redux";
import {
    asyncSaveExpoCompany,
    onExpoCompanyFormChange,
    reselectExpoCompany,
    reselectExpoCompanyCondition,
    reselectExpoCompanyUser
} from "controllers/companies/site";
import {useStyles} from "../../../pages/KdwUserPage";
import CloseIcon from "@material-ui/icons/Close";
import DialogCloseButton from "../../Dialog/DialogCloseButton";
import KdwGreenButton from "../../Buttons/KdwGreenButton";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";
import RequestSuccessDialog from "../../../pages/Account/pages/PersonalData/RequestSuccessDialog";

function ExponentRequestDialog(props: any) {
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(true);
    const classes  = useStyles();
    const classesDialog = useStylesDialog();
    const dispatch = useDispatch();
    const company = useSelector(reselectExpoCompany);
    const companyUser = useSelector(reselectExpoCompanyUser);
    const condition = useSelector(reselectExpoCompanyCondition);
    const handleChange = (e: any) => {
        dispatch(onExpoCompanyFormChange(e));
    };
    const [result, setResult] = useState(false);
    const  onClick = () => {
        dispatch(asyncSaveExpoCompany({...company, user: { ...companyUser}}, (r) => {
            setIsOpen(!r.result);
            setResult(r.result);
        }))
    }
    useEffect(() => {
        if (isOpen) handleChange({target:{value:props.type, name:'type'}})
    }, [props.type, isOpen]);

    return (
        <>
            <KdwGreenButton
                containerProps={{}}
                onClick={() => setIsOpen(true)}
                label=""
            >{props.children}</KdwGreenButton>
            <Dialog
                classes={classesDialog}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"md"}>
                <DialogCloseButton onClick={() => setIsOpen(false)}/>
                <DialogTitle id="form-dialog-title">
                    {__translate("site.exponent_form", "Форма участника экспо форума")}
                </DialogTitle>
                <DialogContent style={{ width: "95%", padding: "4px 10px", overflowX:"hidden"  }} className={classes.kdwScroller}>
                    <ExponentRequestForm item={company} user={companyUser} errors={condition.errors} userPrefix="user" onChange={handleChange} onError={(e:any) => setError(e)}/>
                </DialogContent>
                <DialogActions>
                    <KdwGreenButton onClick={onClick} label="" disabled={error} loading={condition.inProgress}>
                        <TypographyTranslate value={"profile.create_request"} ruVal={"Отправить заявку"}/>
                    </KdwGreenButton>
                </DialogActions>
            </Dialog>
            {result && <RequestSuccessDialog onClose={() => setResult(false)} role="_expo_lead"/>}
        </>
    );
}

export default ExponentRequestDialog;

const useStylesDialog = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        flexWrap: "nowrap",
        position: "relative",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            paddingLeft: 0,
            paddingRight: 0,
        },
        paddingLeft: 40,
        paddingRight: 40,
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        alignItems: "center",
        background: "#ffffff",
        borderRadius: "21px",
        padding: theme.spacing(4),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(6),
        },
    },
}));
