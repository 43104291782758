import React from "react";
import KdwRawTextField from "../../Form/Inputs/KdwRawTextField";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";
import FormWithDirtyValidator from "../../Form/FormWithDirtyValidator";
import {useSelector} from "react-redux";
import {reselectConstants} from "../../../controllers/constants-route/redux-store/constants/constants";
import InnTextField from "../../Form/Inputs/InnTextField";
import {Company} from "controllers/companies/redux";
import { EXPONENTS_TYPE } from "../../../constants/site/common";
import EnumSelect from "../../Form/SelectFields/EnumSelect";
import __locale from "../../../helper/location/location";
import { LangEnum } from "../../../controllers/admin-expo/enums";

function ExponentCompanyForm(props: any) {
    const constants = useSelector(reselectConstants);
    return <FormWithDirtyValidator {...props}>
        {({...params}: any) => {
            const {inputProps, item} = params;
            return <>
                <KdwRawTextField {...inputProps}
                                 labelChildren={<TypographyTranslate value="company.type" ruVal="Тип участия:"/>}
                                 name="type">
                    <EnumSelect
                        multiple={false}
                        onChange={params.onChange}
                        value={item.type}
                        name="type"
                        available={[EXPONENTS_TYPE.exponents,EXPONENTS_TYPE.partners]}
                        enums={constants.user.exponent_types}
                    />
                </KdwRawTextField>
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="company.inn" ruVal="ИНН:"/>}>
                    <InnTextField validator={inputProps.validator} field="inn" handleOnChange={inputProps.onChange} value={item.inn}
                        onComplete={(value:Company) => {inputProps.onChange({target: {value}})}}/>
                </KdwRawTextField>
                {__locale() === LangEnum.RU && <KdwRawTextField {...inputProps}
                                  required={true}
                                  labelChildren={<TypographyTranslate value="company.kpp" ruVal="КПП:" />}
                                  name="kpp"
                                  value={item?.kpp || ""} /> || ''}
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="company.name"
                                                                     ruVal="Наименование организации:"/>}
                                 name="name"
                                 value={item?.name || ""}/>
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="company.short_name"
                                                                     ruVal="Короткое наименование организации:"/>}
                                 name="short_name"
                                 value={item?.short_name || ""}/>
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="company.address"
                                                                     ruVal="Юридический адрес:"/>}
                                 name="address"
                                 value={item?.address || ""}/>
            </>;
        }}
    </FormWithDirtyValidator>;
}

export default ExponentCompanyForm