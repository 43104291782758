import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import {useParams} from "react-router-dom";
import {useStyles} from "pages/KdwUserPage";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";
import UserDocForm from "components/Form/UserDocForm";
import profile from "../../../../controllers/profile-route/redux-store/profile/profile";

const makeStyles1 = makeStyles((theme) => ({
    title: {
        marginTop: "20px",
    },
    row:{
        display:"flex",
        gap:"20px",
        alignItems: "center",
        ['& > div:last-child']: {
            cursor: "pointer",
            marginTop: "20px"
        }
    }
}));
function DocumentData(props: any) {
    const { accessor, onChange, validator, onClose, isOpen } = props;
    const params = useParams<{ ru: string }>();
    const localClasses = makeStyles1();
    const classes = useStyles();
    const canEdit = accessor('doc_state') <= 1;
    const [edit,setEdit] = useState(false);
    useEffect(() => {
        setEdit(accessor('doc_state') <= 0);
    }, []);
    return (
        <>

            {canEdit && <div className={localClasses.row}>
                <div className={classes.grayBlockTitle + " " + localClasses.title}>
                    <span>{__translate("profile.doc_data", "Паспортные данные")}</span>
                </div>
                {!edit && <div onClick={() => setEdit(true)}>
                    <TypographyTranslate value="participation.edit_doc" ruVal="Изменить"/>
                </div>}
            </div>}
            {canEdit && edit &&
                <div className={classes.formPaper + ' ' + classes.formPaperForm}>
                    <UserDocForm {...{profile:accessor, validator, onChange, hideTitle:true, onlyDoc:true, required:accessor('doc_state') <= 0}}/>
                </div>
            }
        </>
    );
}

export default DocumentData;
