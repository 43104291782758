import React from "react";
import clsx from "clsx";
import __locale from "helper/location/location";
import { selectByLang } from "components/FormEvent/FormEvent";
import { LangEnum } from "controllers/admin-speaker/enums";
import LinkWrapper from "components/Site/LinkWrapper/LinkWrapper";
import __translate from "helper/locale/locale";
import CommonContainer from "pages/Site/wrappers/CommonContainer/CommonContainer";
import classes from "./PromoBlock.module.css";
import { CONTACT_LINKS } from "constants/site/contactsCache";
import { COMMON_PARTNERS } from "constants/site/exponents/companiesOnMainPage";
import { EXHIBITION_ADDITIONAL } from "constants/site/exhibition";
import kdwbot from "styles/img/kdwbot.png";
import { ReactComponent as ArrowRightSVG } from "styles/results/icon/arrowRight.svg";
import { ReactComponent as MailSVG } from "styles/img/icon/common/mail.svg";
import ExponentRequestDialog from "components/Site/ExponentRequestDialog/ExponentRequestDialog";
import TypographyTranslate from "../../../../TypographyTranslate/TypographyTranslate";
import {EXPONENTS_TYPE} from "../../../../../constants/site/common";

const PromoBlock = () => {
    const lang = __locale();
    const contacts = [
        // {
        //     icon: <Phone />,
        //     contact: CONTACT_LINKS.partners.tel,
        //     prefix: "tel",
        // },
        {
            icon: <MailSVG />,
            contact: CONTACT_LINKS.partners.mail,
            prefix: "mailto",
        },
    ];
    return (
        <div className={classes.promoBlock__wrapper}>
            <CommonContainer className={classes.promoBlock}>
                <div className={classes.promoBlock__header}>
                    <span>{selectByLang(COMMON_PARTNERS.header, lang as LangEnum, "")}</span>
                    <br />
                    <img src={kdwbot} alt={"kdwbot"} />
                </div>
                <div className={classes.promoBlock__privilege}>
                    <span>{selectByLang(COMMON_PARTNERS.privelege_desc, lang as LangEnum, "")}</span>
                    {selectByLang(COMMON_PARTNERS.privelege, lang as LangEnum, "")}
                </div>
            </CommonContainer>
            <div className={classes.promoBlock__moreBlock}>
                <div className={classes.promoBlock__question}>
                    <LinkWrapper
                        link={EXHIBITION_ADDITIONAL.partners_more_info.ru}
                        className={clsx(classes.promoBlock__more, "linkWhite")}>
                        <span>{__translate("site.partnership_packages", "Посмотреть партнерские пакеты")}</span>
                        <ArrowRightSVG />
                    </LinkWrapper>
                    <ExponentRequestDialog type={EXPONENTS_TYPE.partners}><TypographyTranslate value="site.to_partner" ruVal="Стать партнёром"/></ExponentRequestDialog>
                    <div className={classes.promoBlock__contacts}>
                        {contacts.map(({ icon, contact, prefix }, index) => (
                            <div className={"linkWhite"} key={index}>
                                {icon}
                                <a href={`${prefix}:${contact}`}>{contact}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromoBlock;
