import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import {userFilled} from "pages/Account/UserLeftMenu/UserLeftMenu";
import {Redirect, useHistory, useLocation, useParams} from "react-router-dom";
import {
    on_Response_Success as profileEntityUpdate,
    reselect_Profile_condition,
    reselect_Profile_entity,
    TYPE_USER_PROFILE,
} from "controllers/profile-route/redux-store/profile/profile";
import {
    async_Profile_Post,
    onChange,
    reselect_Profile_condition_post,
    reselect_Profile_entity_post,
    reset,
} from "controllers/profile-route-post/redux-store/profile-post/profile-post";
import {useDispatch, useSelector} from "react-redux";
import __translate from "helper/locale/locale";
import Loader from "components/Loader/Loader";
import {RegistrationRole} from "constants/constants";
import {useStyles} from "pages/KdwUserPage";
import {useValidator} from "helper/validation/validator";
import {UserRoutePageParams} from "pages/Account/AccountLayout";
import UserData from "./UserData";
import BottomBar from "./BottomBar";
import KdwGreenButton from "components/Buttons/KdwGreenButton";
import RequestSuccessDialog from "./RequestSuccessDialog";
import ProfileData from "./ProfileData";
import {speakerFilled} from "components/Form/FormSpeakerRole";
import NeedEmailConfirm from "./NeedEmailConfirmDialog";
import DocumentData from "./DocumentData";
import __locale from "../../../../helper/location/location";
import { STATE_ACCEPTED, STATE_ACCEPTED_BY_PROMO, STATE_REQUESTED } from "../Participations/CurrentRolePage";

const UserPersonalData = (props:UserRoutePageParams) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const rParams = useParams<{ ru: string }>();
    //reselect section
    const dataProfile = useSelector(reselect_Profile_entity);
    const dataProfileCondition = useSelector(reselect_Profile_condition);
    const dataProfilePost = useSelector(reselect_Profile_entity_post);
    const conditionProfile = useSelector(reselect_Profile_condition_post);
    props.conditionState?.[1](conditionProfile);
    const errors = conditionProfile.response.errors || {};
    const [dirty, updateDirty] = React.useState<any>({});
    const [openResultRole, setResultRole] = React.useState(0 as any);
    const [confirmedParam, setCP] = useState(new URLSearchParams(useLocation().search).get("confirmed"));
    const [openConfirm, setOpenConfirm] = React.useState(confirmedParam === "email");
    const profile = (property?: string) =>{
        if (property === undefined) return dataProfile;
        return dataProfilePost?.hasOwnProperty(property)
            ? dataProfilePost[property as keyof TYPE_USER_PROFILE]
            : ((dataProfile?.participation_data as any)?.hasOwnProperty(property)
                ? (dataProfile?.participation_data as any)?.[property]
                : dataProfile?.[property as keyof TYPE_USER_PROFILE]
        );}
    const role = profile("role") !== undefined ? profile("role")  : dataProfile?.participation_data?.role;
    const validator = useValidator(dirty, errors, profile);
    const reports = dataProfilePost?.participation_data?.reports;
    const [firstPage, setFirstPage] = React.useState((history.location?.state as any)?.from);
    const serverState = () => (dataProfile?.participation_data?.status?.state & (STATE_ACCEPTED|STATE_REQUESTED)) > 0;
    const params = useParams<{ ru: string }>();
    const needNextStep =  !serverState() || RegistrationRole.SPEAKER == role && !speakerFilled(dataProfile);
    const isPremReg = dataProfile?.role == RegistrationRole.PARTICIPANT_PREMIUM;
    const company = dataProfile?.companies?.find(el => el.user_id === dataProfile?.id);
    const disabled = conditionProfile.inProgress
        || Object.keys(dirty).length == 0 && ((needNextStep &&  speakerFilled(dataProfile)) || serverState());

    React.useEffect(() => {
        setFirstPage(!serverState() &&  firstPage || '')
        if (localStorage.getItem('cpromo')) {
            dispatch(async_Profile_Post({promo: localStorage.getItem('cpromo')} , (r:any) => {
                if (r.result) localStorage.removeItem('cpromo')
            }));
        }
    }, [dataProfile]);

    const handleOnChange = (e: any) => {
        let targets = Array.isArray(e.changes) ? e.changes :[e.target]
        let toAdd = {...dirty};
        targets.forEach((t:any) => {
            toAdd[t.name] = (new Date()).getTime(   );
            dispatch(onChange({
                name: t.name, value: t.hasOwnProperty('checked') ? t.checked : t.value, checked: t.checked,
                currentReports: reports?.length ? reports : dataProfile.participation_data?.reports,
            }));
        })
        updateDirty((toAdd))
    };
    //Перенаправление после первого входа
    if (firstPage === "form_token" && userFilled(dataProfile)) {
        if (needNextStep && !window.location.search) {
            return <Redirect to={`/user/participations?next=1&role=` + role}/>;
        } else if (dataProfile?.role == RegistrationRole.SPEAKER && !reports?.length)
            return <Redirect to={`/user/speaker-data`} />;
        else if (isPremReg && !dataProfile?.participation_data?.pay_order_id) {
            return <Redirect to={`/${__locale()}/payment`} />;
        } return <Redirect to={`/user/participations/current`}/>;
    }

    const  sendDirtyData = (draft = false, mix = null as any, force = false) => {
        
        return new Promise((resolve, reject) => {
            let data = { id: dataProfile?.id, ...mix} as any;
            Object.keys(dirty).forEach(k => {
                data[k] = (dataProfilePost as any)?.[k];
            });
            data.draft = draft ? 1 : 0;
            let oldrole = dataProfile.participation_data?.role;
            let s = serverState();
            if ((!s && draft || s && !draft) && Object.keys(dirty).length == 0 && !force) {
                resolve({});
                if (!draft) history.push( '/user/participations/current');
                return;
            }
            if (data.password) data.password_confirmation = data.password;
            dispatch(async_Profile_Post(data, result => {
                if (result?.result) {
                    dispatch(profileEntityUpdate(result));
                    let form = result.data.participation_data?.form;
                    const rs = (form?.status?.state&(STATE_REQUESTED|STATE_ACCEPTED)) > 0
                    const nodoc = result?.data?.doc_state <= 1
                    if (!draft && !rs) { //если после отправки запроса вернулся черновик
                        setOpenConfirm(true)
                    } else {
                        if (!!((!s || form?.role != oldrole) && rs)) setResultRole(form?.role);
                        else if (rs && !mix?.next && !nodoc) history.push('/user/participations/current');
                    }
                    setFirstPage('');
                    resolve(result);
                } else {
                    //Если есть ошибка отсутсвия паспортных данных
                    let keys = Object.keys(result?.data ||{});
                    if (keys.filter(v => v.split('.')[0] == 'doc').length == keys.length) {
                        history.push('/user/participations?role=' + (data.role || oldrole), {form:data});
                    }
                    reject({error: result});
                }
            }),);
        })
    };
    const onClose = () => {
        setResultRole(false);
        history.push('/user/participations' +(isPremReg ? '' : '/current'));
    };
    const submit = (draft = true) => {
        return new Promise((res, rej) => {
            sendDirtyData(draft && !serverState()).then((result:any) => {
                let filled = userFilled(result.data || profile());
                if (needNextStep && filled) {
                    history.push( '/user/participations?next=1')
                }
                res(result);
            }).catch(console.log)
        })
    };
    return (
        <>
            {!dataProfile || dataProfileCondition.inProgress ? (
                <Loader />
            ) : props.children && props.children({handleOnChange, role, validator, sendDirtyData, disabled, profile, dirty, setCP, setOpenConfirm}) || (
                <Container className={classes.content}>
                    {/*{firstPage && !Number.isInteger(dataProfile?.role) && <UserRolesBar roleValue={role} setRole={(r:any) => {handleOnChange({target:{name:"role", value: r}})}}/>}*/}
                    <UserData accessor={profile} onChange={handleOnChange} validator={validator} />
                    {!firstPage && !needNextStep && <ProfileData accessor={profile} onChange={handleOnChange} validator={validator}/>}
                    {!firstPage && !needNextStep && <DocumentData key={dataProfile?.updated_at} accessor={profile} onChange={handleOnChange} validator={validator}/>}
                    <BottomBar accessor={profile} onChange={handleOnChange} validator={validator}>
                        <KdwGreenButton
                            onClick={submit}
                            disabled={disabled || !Object.keys(dirty).length && !userFilled(profile())}
                            label={needNextStep && userFilled(dataProfile, dataProfilePost)
                                ? __translate("profile.next", "Продолжить")
                                : (
                                    // dataProfile?.participation_data?.role !== role || !Number.isInteger(role)
                                    // ? __translate("profile.create_request", "Отправить заявку")
                                    // :
                                        __translate("system.save", "Сохранить"))
                            }
                        />
                    </BottomBar>
                </Container>
            )}
            {!!openResultRole && <RequestSuccessDialog onClose={onClose} role={openResultRole}/>}
            {openConfirm && (confirmedParam === "email" || !dataProfile?.email_confirmed_at) && <NeedEmailConfirm
                onClose={() => {setOpenConfirm(false)}}
                sendOpen={confirmedParam !== "email"  && !dataProfile?.email_confirmed_at}
                role={profile('role')}
                onConfirmed={(confirmed: any) => {
                    if (confirmed && !needNextStep) {
                        submit(false)?.then(() => setOpenConfirm(false));
                    } else {
                        setOpenConfirm(false);
                    }
            }}/>}
        </>
    );
};
export default UserPersonalData;
