import { RegistrationRole } from "../constants";

export const ROLE_EMPTY = {
    ru: "Выберите категорию участия, для получения информации",
    en: "Select for information",
};
export const ECONOM_FEATURES = {
    ru: [
        "Доступ к выставочной зоне Форума",
        "Электронный сертификат об участии",
        "Предоставление общего трансфера до места проведения Форума (от рекомендованных к проживанию отелей)",
    ],
    en: [
        "Exhibition zone access",
        "Electronic certificate",
        "Providing a shared transfer to the Forum venue (from recommended hotels)",
    ],
};
export const STANDART_FEATURES = {
    ru: [
        "Доступ к выставочной зоне Форума",
        "Доступ к пленарному заседанию Форума",
        "Доступ к деловой программе Форума",
        "Кофе-брейк (3 дня)",
        "Пакет делегата",
        "Электронный сертификат об участии",
        "Предоставление общего трансфера до места проведения Форума (от рекомендованных к проживанию отелей)",
    ],
    en: [
        "Exhibition zone access",
        "Plenary session zone access",
        "Scientific sessions access",
        "Coffee break (3 days)",
        "Delegate package",
        "Electronic certificate",
        "Providing a shared transfer to the Forum venue (from recommended hotels)",
    ],
};

export const PREMIUM_FEATURES = {
    ru: [
        "Возможность выступления на сессиях Форума (по согласованию)",
        "Доступ к выставочной зоне Форума",
        "Доступ к пленарному заседанию Форума",
        "Доступ к деловой программе Форума",
        "Кофе-брейк (3 дня)",
        "Пакет делегата",
        "Доступ в зону питания для почетных гостей",
        "Приглашение на гала-ужин",
        "Возможность бронирования переговорной комнаты (по согласованию)",
        "Электронный сертификат об участии",
        "Предоставление персонального трансфера до места проведения Форума (по согласованию)",
    ],
    en: [
        "Scientific session speaker",
        "Exhibition zone access",
        "Plenary session zone access",
        "Scientific sessions access",
        "Coffee break (3 days)",
        "Delegate package",
        "Honorable guests dinner zone",
        "Gala-dinner",
        "The possibility of booking a meeting room",
        "Electronic certificate",
        "Providing a personal transfer to the Forum venue (request should be sent in advance)",
    ],
};
export const BUSSINESS_FEATURES = {
    ru: [
        "Доступ к выставочной зоне Форума",
        "Доступ к пленарному заседанию Форума",
        "Доступ к деловой программе Форума",
        "Кофе-брейк (3 дня)",
        "Пакет делегата",
        "Электронный сертификат об участии",
        "Предоставление общего трансфера до места проведения Форума (от рекомендованных к проживанию отелей)",
    ],
    en: [
        "Exhibition zone access",
        "Plenary session zone access",
        "Scientific sessions access",
        "Coffee break (3 days)",
        "Delegate package",
        "Electronic certificate",
        "Providing a shared transfer to the Forum venue (from recommended hotels)",
    ],
};
export const SPEAKER_FEATURES = {
    ru: [
        "Возможность выступления на сессиях Форума (по согласованию)",
        "Доступ к выставочной зоне Форума",
        "Доступ к пленарному заседанию Форума",
        "Доступ к деловой программе Форума",
        "Кофе-брейк (3 дня)",
        "Пакет делегата",
        "Электронный сертификат об участии",
        "Предоставление общего трансфера до места проведения Форума (от рекомендованных к проживанию отелей)",
    ],
    en: [
        "Scientific session speaker",
        "Exhibition zone access",
        "Plenary session zone access",
        "Scientific sessions access",
        "Coffee break (3 days)",
        "Delegate package",
        "Electronic certificate",
        "Providing a shared transfer to the Forum venue (from recommended hotels)",
    ],
};
export const MEDIA_FEATURES = {
    ru: [
        "доступ в медиа-центр форума;",
        "доступ ко всем мероприятиям форума, за исключением тех, которые имеют закрытый статус и вход на которые осуществляется по отдельным приглашениям;",
        "информационные материалы для СМИ;",
        "дополнительную информацию в виде комментариев участников форума по согласованию с организаторами форума.",
    ],
    en: [
        "Forum`s media center access;",
        "all Forum`s events access, with the exception of those having a status of «closed» and access by the special invitations;",
        "media information materials;",
        "by the agreement with the Forum`s organizers, Forum`s participants comments, as an additional information.",
    ],
};

export const ROLE_INFO = [
    {
        value: RegistrationRole.PARTICIPANT_ECONOM,
        infoArr: [""],
        infoDesc: {
            ru: (
                <>
                    <b>O р.</b>
                    <ul>
                        {ECONOM_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            en: (
                <>
                    <b>O rub.</b>
                    <ul>
                        {ECONOM_FEATURES.en.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            cn: "",
        },
    },
    {
        value: RegistrationRole.PARTICIPANT,
        infoDesc: {
            ru: (
                <>
                    <b>3 00O р.</b>
                    <br />
                    <b>Только для государственных и бюджетных учреждений</b>
                    <ul>
                        {STANDART_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            en: (
                <>
                    <b>3 00O rub.</b>
                    <br />
                    <b>Only for state companies</b>
                    <ul>
                        {STANDART_FEATURES.en.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            cn: "",
        },
    },
    {
        value: RegistrationRole.PARTICIPANT_PREMIUM,
        infoDesc: {
            ru: (
                <>
                    <b>65 00O р.</b>
                    <ul>
                        {PREMIUM_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            en: (
                <>
                    <b>65 00O rub.</b>
                    <ul>
                        {PREMIUM_FEATURES.en.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            cn: "",
        },
    },
    {
        value: RegistrationRole.PARTICIPANT_BUSINESS,
        infoDesc: {
            ru: (
                <>
                    <b>8 00O р.</b>
                    <ul>
                        {BUSSINESS_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            en: (
                <>
                    <b>8 00O rub.</b>
                    <ul>
                        {BUSSINESS_FEATURES.en.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            cn: "",
        },
    },
    {
        value: RegistrationRole.SPEAKER,
        infoDesc: {
            ru: (
                <>
                    <b>5 50O р.</b>
                    <br />
                    <b>0 р.* Только для государственных и бюджетных учреждений</b>
                    <ul>
                        {SPEAKER_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            en: (
                <>
                    <b>5 50O rub.</b>
                    <br />
                    <b>0 rub.* Only for state companies</b>
                    <ul>
                        {SPEAKER_FEATURES.en.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            cn: "",
        },
    },
    {
        value: RegistrationRole.MEDIA,
        infoDesc: {
            ru: (
                <>
                    <b>Аккредитованные представители СМИ получают:</b>
                    <ul>
                        {MEDIA_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            en: (
                <>
                    <b>Media accredited representatives receive:</b>
                    <ul>
                        {MEDIA_FEATURES.ru.map(v => (
                            <li>{v}</li>
                        ))}
                    </ul>
                </>
            ),
            cn: "",
        },
    },
];
