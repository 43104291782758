import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import {useParams} from "react-router-dom";
import {PARTICIPATION_STATE, RegistrationRole} from "constants/constants";
import {useStyles} from "pages/KdwUserPage";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import CountriesSelect from "components/Form/SelectFields/CountriesSelect";
import CitiesSelect from "components/Form/SelectFields/CitiesSelect";
import FormOrganizatorRole from "components/Form/FormOrganizatorRole";
import EmailRawTextField from "components/Form/Inputs/EmailRawTextField";
import DateTextField from "components/Form/Inputs/DateTextField";
import {speakerCanChangeData} from "components/Form/FormSpeakerRole";
import Grid from "@material-ui/core/Grid";
import {FormControl, InputLabel} from "@material-ui/core";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";
import CropImageUploadButton from "components/FIleUploadButton/ImageUploadButton/CropImageUploadButton";
import EnumSelect from "components/Form/SelectFields/EnumSelect";
import {useSelector} from "react-redux";
import {reselectConstants} from "controllers/constants-route/redux-store/constants/constants";
import TranslatesByKeySelect from "../../../../components/Form/SelectFields/TranslatesByKeySelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import UniversitySelect from "../../../../components/Form/SelectFields/UniversitySelect";
import WorkInfoForm from "../../../../components/Form/WorkInfoForm";
import {fileValueChanger} from "../../../../helper/blob/blob";
import PhotoInfoShowDialog from "../../../../components/Dialog/PhotoInfoShowDialog";

const makeStyles1 = makeStyles((theme) => ({
    title: {
        marginTop: "20px",
    },
}));
function UserData(props: any) {
    const { accessor, onChange, validator, onClose, isOpen} = props;
    const params = useParams<{ ru: string }>();
    const localClasses = makeStyles1();
    const classes = useStyles();
    const isMedia = Number(accessor("role")) === RegistrationRole.MEDIA;
    const isOrg = Number(accessor("role")) === RegistrationRole.ORGANIZER;
    const isSpeak = (Number(accessor("role"))& RegistrationRole.SPEAKER)>0;
    const isPrem = (Number(accessor("role"))& RegistrationRole.PARTICIPANT_PREMIUM)>0;
    const disabled = !speakerCanChangeData(accessor());
    const [photoInputRef, setPhotoRef] = React.useState(null);
    const [cityKey, setCity] = React.useState(0);
    return (
        <>
            <div className={classes.grayBlockTitle + " " + localClasses.title}> <span>{__translate("profile.pesonal_data", "Личные данные")}</span></div>
            <div className={classes.formPaper + ' '+ classes.formPaperForm }>
                <KdwRawTextField
                    required={true}
                    name="surname"
                    label={__translate("signup.surname", "Фамилия")}
                    validator={validator}
                    onChange={onChange}
                    disabled={disabled}
                    value={accessor("surname")}
                />
                <KdwRawTextField
                    required={true}
                    name="name"
                    label={__translate("signup.name", "Имя")}
                    validator={validator}
                    onChange={onChange}
                    disabled={disabled}
                    value={accessor("name")}
                />
                <KdwRawTextField
                    name="lastname"
                    required={accessor('country') == 'RU'}
                    label={__translate("signup.lastname", "Отчество")}
                    validator={validator}
                    onChange={onChange}
                    disabled={disabled}
                    value={accessor("lastname")}
                />
                <KdwRawTextField
                    required={true}
                    label={__translate("signup.birthday", "Дата рождения")}
                >
                    <DateTextField
                        required={true}
                        onChange={onChange}
                        name={"birthday"}
                        value={accessor("birthday")}
                        error={!!validator("birthday")}
                        helperText={validator("birthday")}
                        disabled={disabled}
                        placeholder={__translate("signup.birthday", "Дата рождения")}
                    />
                </KdwRawTextField>
                <KdwRawTextField
                    required={true}
                    label={__translate("signup.country", "Страна")}
                    disabled={disabled}
                    value={accessor("country")}
                >
                    <CountriesSelect
                        onSelect={target => {
                            setCity(cityKey+1);
                            onChange({changes: [
                                    target,
                                    {name:'region', value: ''},
                                    {name:'city', value: ''}
                                ]});
                        }}
                        value={accessor("country")}
                        error={validator("country")}
                        disabled={disabled}
                        required={true}
                        placeholder={__translate("signup.country", "Страна")}
                    />
                </KdwRawTextField>
                <KdwRawTextField
                    label={__translate("signup.city", "Населенный пункт")}
                    value={accessor("city")}
                    key={cityKey}
                    disabled={disabled|| !accessor('country')}
                    required={true}
                >
                    <CitiesSelect
                        onSelect={target => {
                            onChange({target:{name:'region', value: target.value?.region || ""}})
                            target.value =  target?.value?.name ||  target?.value
                            onChange({target})
                        }}
                        onChange={onChange}
                        error={validator("city")||!accessor("city")}
                        name={"city"}
                        value={accessor("city") || ""}
                        country={accessor("country") || ""}
                        region={accessor("region") || true}
                        placeholder={__translate("signup.city", "Населенный пункт")}
                        disabled={disabled || !accessor('country')}
                    />
                </KdwRawTextField>
                {!isSpeak && !isPrem && <WorkInfoForm profile={accessor} validator={validator} onChange={onChange}/>}

                <EmailRawTextField
                    required={true}
                    name="email"
                    label={__translate("login.email", "Email")}
                    validator={validator}
                    onChange={onChange}
                    value={accessor("email")}
                    disabled={disabled}
                />
                <KdwRawTextField
                    required={true}
                    name="phone"
                    label={__translate("signup.phone", "Телефон")}
                    validator={validator}
                    onChange={onChange}
                    value={accessor("phone")}
                    disabled={disabled}
                />

                <KdwRawTextField
                    labelChildren={<Grid container>
                        <Grid item xs={12} onClick={() => photoInputRef?.current?.click()}><InputLabel>{__translate("signup.photo", "Портретное фото")}*</InputLabel></Grid>
                        <Grid item xs={12}><PhotoInfoShowDialog/></Grid>
                    </Grid>}>
                    <>
                        <CropImageUploadButton
                            onChange={(f:any, n:string) => fileValueChanger(f, n, onChange)}
                            value={(accessor("photo") || {}).file}
                            def={"/static/noava.jpg"}
                            disabled={disabled}
                            aspect={1}
                            label={__translate("system.change_photo", "Изменить фото")}
                            name={`photo`}
                            setRef={setPhotoRef}
                        />
                        {validator('dirty').photo !== undefined && !(accessor("photo") || {})?.file &&
                            <FormControl required error={true} component="fieldset" style={{marginLeft:5}}>
                                <FormHelperText>{__translate("signup.photo_required", "Фото обязательно для заполнения")}</FormHelperText>
                            </FormControl>}
                    </>
                </KdwRawTextField>
                {isOrg && <FormOrganizatorRole profile={accessor} validator={validator} onChange={onChange} hideCheckBox={true}/>}

                <KdwRawTextField
                    name="password"
                    label={__translate("signup.password", "Пароль")}
                    validator={validator}
                    onChange={onChange}
                    value={accessor("password")}
                    disabled={disabled}
                />
            </div>
        </>
    );
}

export default UserData;
