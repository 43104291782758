import React, {useEffect, useState} from "react";
import Asynchronous from "../../Asynchronous/Asynchronous";
import __translate from "helper/locale/locale";
import { useDispatch, useSelector } from "react-redux";
import {
    asyncGeolocationCitiesBase,
    reselectGeonamesCitiesCondition,
    reselectGeonamesCitiesList,
    onResponseSuccess,
} from "controllers/geolocation-route/redux-store/cities/cities";
import KdwRawTextField from "../Inputs/KdwRawTextField";

type Props = {
    error?: string;
    disabled?: boolean;
    required?: boolean;
    region?: any;
    errorText?: string;
    onChange: (event: any) => void;
    onSelect: (event: any) => void;
    value: any;
    name?: string;
    country: string;
    placeholder?: string;
};
const CitiesSelect: React.FC<Props> = ({
    error = "",
    errorText = "",
    disabled = false,
    required = false,
    region = null,
    onChange,
    onSelect,
    country,
    name = "city",
    placeholder = "",
    value,
}) => {
    const condition = useSelector(reselectGeonamesCitiesCondition);
    const list = useSelector(reselectGeonamesCitiesList);
    const dispatch = useDispatch();
    const ref = React.useRef<any>();
    const [city, setCity] = useState({name:(value||''), region: ((region === true || !region) ? '' : region)} as any);
    useEffect(() => {
        dispatch(onResponseSuccess([]))
    }, [country])
    return (
        <Asynchronous
            name={name}
            value={value}
            label={!placeholder && __translate("signup.city", "Населенный пункт") || ""}
            placeholder={placeholder && __translate("signup.city", "Населенный пункт")}
            isLoading={condition.inProgress}
            options={list}
            required={required}
            error={error}
            disabled={disabled}
            onOptionClick={(name, value) => {
                setCity(value);
                onSelect({ name, value })
            }}
            {...(!region ? {} : {
                renderOption: (option => (option.name||'') +(option.region ?  ', ' + option.region : '')),
                renderVal: value => (!city || !region) ? value : (city.name + (city.region ? ', ' + city.region : ''))
            })}
            onChange={e => {
                onChange(e);
                setCity(null);
                if ((e.target.value || "") === "") return;
                if (ref.current) clearTimeout(ref.current);
                ref.current = setTimeout(
                    () =>
                        dispatch(
                            asyncGeolocationCitiesBase({
                                city: e.target.value,
                                country_id: country,
                            }),
                        ),
                    350,
                );
            }}
        />
    );
};
export default CitiesSelect;
