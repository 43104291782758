import {helpValidateEmail} from "../email/email";
import __translate from "../locale/locale";
import {helpValidateNumber} from "../phone-number/phone-number";
import __locale from "../location/location";
import { LangEnum } from "../../controllers/admin-expo/enums";
export const helpValidateName: (x1: any) => boolean = (inValue: any): boolean => {
    return !inValue ? false : /^[a-zA-Zа-яА-Я](\s|[\d\w\-а-яА-Я])+$/.test(inValue);
};
export const helpValidateNumberLength: (x1: string, l: string) => boolean = (inValue: string, l: string): boolean => {
    return !inValue ? false : new RegExp("^(\\d){"+l+"}$").test(inValue);
};

export const USER_MIN_OLD = 14;
export const maxBirthday = () => {
    let keyForumDate = "site.forum_date";
    let dynamic = __translate(keyForumDate);
    dynamic = dynamic != keyForumDate ? dynamic.replace('-', '/') : "2024/09/11";
    let date2 = new Date(dynamic);
    date2.setFullYear(date2.getFullYear() - USER_MIN_OLD);
    return date2;
}

export const useValidator = function (dirty: any, errors: any,  accessor: (name:string) => any = (n) => '', prefix = null as any) {
    return (name: string) => {
        switch (name) {
            case 'dirty': return dirty;
            case 'errors': return errors;
        }
        // @ts-ignore
        let changed = false;

        const name2 = name.split('.').reverse()[0]; //Для полей с точкой в названии: "doc.doc_number"
        let changed2 =  name != name2;

        if (!Array.isArray(dirty)) {
            changed = dirty[name] !== undefined
            changed2 = changed2 && (dirty[name2] !== undefined)
            if (changed && errors.time) changed = dirty[name] > errors.time;
            if (changed2 && errors.time) changed2 = dirty[name2] > errors.time;
        } else {
            changed = dirty.indexOf(name) !== -1;
            changed2 = changed2 && (dirty.indexOf(name2) !== -1);
        }
        const v = !changed2 ? accessor(name) : accessor(name2);

        const server = errors?.[name] ? errors?.[name] : (prefix ? errors?.[prefix + '.' + name] : false);
        let msg = "";
        if (server && !(changed || changed2) ) return Array.isArray(server) ? server[0] : server;


        let l = '6';
        if (changed || changed2) switch (name) {
            case "email":
                msg = !helpValidateEmail(v) && __translate("signup.mail_error", "Неверный адрес электронной почты");
                break;
            case "contact_phone":
            case "phone":
                msg = !helpValidateNumber(v) && __translate("signup.phone_error", "Неверный телефон") + " ( +79991234567 )";
                break;
            case "name":
            case "name_en":
            case "surname":
            case "surname_en":
            case "lastname":
                msg = !helpValidateName(v) && __translate("signup." + name + "_error", "Некорректное имя");
                break;
            case "inn":
                msg = __locale() == LangEnum.RU && !helpValidateNumberLength(v, '10') && __translate("signup."+ name2 + "error", ( 'Некорретный ИНН'));
                break;
            //@ts-ignore
            case "doc.doc_number":
                if (accessor('country') !== 'RU') {
                    msg = v !== undefined && !v && __translate("signup."+ name2 + "error", __translate("signup.doc_number_error", 'Некорретный номер'));
                    break;
                }
                l = '4';
            case "doc.doc_number2":
                let val = v;
                if (accessor('country') === 'RU') {
                    val = helpValidateNumberLength(v, l);
                }
                msg = v !== undefined && !val && __translate("signup."+ name2 + "error", __translate("signup.doc_number_error", 'Некорретный номер'));
                break;
            case "proceeds2022":
            case "proceeds":
            case "amount":
                msg = v !==undefined && !helpValidateNumberLength(v, v.length) && __translate("signup.bad_value", "Некорректное значение")
                break;
            case "description":
                msg = v.length > 1000 && __translate("signup.max_length", "Не более 1000 знаков")
                break;
            case "birthday":
                if (!v) break;
                let date = new Date(v.replace('-', '/'));
                let date2 = maxBirthday();
                msg = (date2.getTime() < date.getTime()) &&  __translate("signup.min_user_year", "На дату форума, вам должно быть больше 18 лет")
                break;
            case "password_confirmation":
                msg = accessor('password') !== accessor('password_confirmation')
                    && __translate("signup.password_confirmation", "Пароли не совпадают");
                break;
        }
        return msg || "";
    };
};