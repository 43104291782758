import React from "react";

import { ReactComponent as PhoneSVG } from "../../styles/img/icon/ContactIcons/phone.svg";
import { ReactComponent as MailSVG } from "../../styles/img/icon/common/mail.svg";
import { ReactComponent as TelegramSVG } from "../../styles/results/icon/social/telegram.svg";

export const CONTACT_LINKS = {
    help: {
        tel: "+7 (927) 037 80 61",
        mail: "help@kazandigitalweek.com",
        tg: {
            link: "https://t.me/kdw_help",
            name: "kdw_help",
        },
    },
    expo: {
        tel: "+7 (987) 215 11 00",
        mail: "expo@kazandigitalweek.com",
    },
    partners: {
        tel: "+7 (987) 215 11 00",
        mail: "partner@kazandigitalweek.com",
    },
    media: {
        tel: "+7 (987) 215 11 00",
        mail: "pr@kazandigitalweek.com",
    },
};

export const FORUM_QUESTIONS = {
    contact_theme: {
        ru: "По общим вопросам форума",
        // ru: "По общим вопросам форума",
        en: "For general forum questions",
        cn: "",
    },
    contact_list: {
        ru: (
            <>
                <li>
                    <MailSVG /> <a href={`mailto:${CONTACT_LINKS.help.mail}`}>{CONTACT_LINKS.help.mail}</a>
                </li>
                <li>
                    <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.help.tel}`}>{CONTACT_LINKS.help.tel}</a>
                </li>
                <li>
                    <TelegramSVG width={16} height={20} />{" "}
                    <a href={`${CONTACT_LINKS.help.tg.link}`}>{CONTACT_LINKS.help.tg.name}</a>
                </li>
            </>
        ),
        en: (
            <>
                <li>
                    <MailSVG /> <a href={`mailto:${CONTACT_LINKS.help.mail}`}>{CONTACT_LINKS.help.mail}</a>
                </li>
                <li>
                    <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.help.tel}`}>{CONTACT_LINKS.help.tel}</a>
                </li>
                <li>
                    <TelegramSVG width={16} height={20} />{" "}
                    <a href={`${CONTACT_LINKS.help.tg.link}`}>{CONTACT_LINKS.help.tg.name}</a>
                </li>
            </>
        ),
        cn: "",
    },
    contact_link: "",
    contact_desc: {
        ru: "",
        en: "",
        cn: "",
    },
};

export const CALL_CENTER = {
    contact_theme: {
        ru: "Контакт-центр",
        en: "Call center",
        cn: "",
    },
    contact_list: {
        ru: (
            <>
                <li>
                    <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.help.tel}`}>{CONTACT_LINKS.help.tel}</a>
                </li>
                <li>
                    <TelegramSVG width={16} height={20} />{" "}
                    <a href={`${CONTACT_LINKS.help.tg.link}`}>{CONTACT_LINKS.help.tg.name}</a>
                </li>
            </>
        ),
        en: (
            <>
                <li>
                    <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.help.tel}`}>{CONTACT_LINKS.help.tel}</a>
                </li>
                <li>
                    <TelegramSVG width={16} height={20} />{" "}
                    <a href={`${CONTACT_LINKS.help.tg.link}`}>{CONTACT_LINKS.help.tg.name}</a>
                </li>
            </>
        ),
        cn: "",
    },
    contact_link: "",
    contact_desc: {
        ru: "",
        en: "",
        cn: "",
    },
};

export const CONTACTS_CACHE = [
    FORUM_QUESTIONS,
    {
        contact_theme: {
            ru: "По вопросам участия в выставке",
            en: "For exhibition questions",
            cn: "",
        },
        contact_list: {
            ru: (
                <>
                    <li>
                        <MailSVG /> <a href={`mailto:${CONTACT_LINKS.expo.mail}`}>{CONTACT_LINKS.expo.mail}</a>
                    </li>
                    <li>
                        <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.expo.tel}`}>{CONTACT_LINKS.expo.tel}</a>
                    </li>
                    {/*<li>*/}
                    {/*    <span>*/}
                    {/*        Тел.: <a href="tel:+7 (987) 215 11 00">+7 (987) 215 11 00</a>*/}
                    {/*    </span>*/}
                    {/*</li>*/}
                </>
            ),
            en: (
                <>
                    <li>
                        <MailSVG /> <a href={`mailto:${CONTACT_LINKS.expo.mail}`}>{CONTACT_LINKS.expo.mail}</a>
                    </li>
                    <li>
                        <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.expo.tel}`}>{CONTACT_LINKS.expo.tel}</a>
                    </li>
                    {/*<li>*/}
                    {/*    <span>*/}
                    {/*        Тел.: <a href="tel:+7 (987) 215 11 00">+7 (987) 215 11 00</a>*/}
                    {/*    </span>*/}
                    {/*</li>*/}
                </>
            ),
            cn: "",
        },
        contact_link: "",
        contact_desc: {
            ru: "",
            en: "",
            cn: "",
        },
    },
    {
        contact_theme: {
            ru: "По вопросам партнерства",
            en: "For partnership questions",
            cn: "",
        },
        contact_list: {
            ru: (
                <>
                    <li>
                        <MailSVG /> <a href={`mailto:${CONTACT_LINKS.partners.mail}`}>{CONTACT_LINKS.partners.mail}</a>
                    </li>
                    <li>
                        <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.partners.tel}`}>{CONTACT_LINKS.partners.tel}</a>
                    </li>
                    {/*<li>*/}
                    {/*    <span>*/}
                    {/*        Тел. гор. линии: <a href="tel:+7(927)0378061">+7 (927) 037 80 61</a>*/}
                    {/*    </span>*/}
                    {/*</li>*/}
                </>
            ),
            en: (
                <>
                    <li>
                        <MailSVG /> <a href={`mailto:${CONTACT_LINKS.partners.mail}`}>{CONTACT_LINKS.partners.mail}</a>
                    </li>
                    <li>
                        <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.partners.tel}`}>{CONTACT_LINKS.partners.tel}</a>
                    </li>
                    {/*<li>*/}
                    {/*    <span>*/}
                    {/*        Tel.: <a href="tel:+7 (987) 215 11 00">+7 (987) 215 11 00</a>*/}
                    {/*    </span>*/}
                    {/*</li>*/}
                </>
            ),
            cn: "",
        },
        contact_link: "",
        contact_desc: {
            ru: "",
            en: "",
            cn: "",
        },
    },
    {
        contact_theme: {
            ru: "Контакты для СМИ",
            en: "Media contacts",
            cn: "",
        },
        contact_list: {
            ru: (
                <>
                    <li>
                        <MailSVG /> <a href={`mailto:${CONTACT_LINKS.media.mail}`}>{CONTACT_LINKS.media.mail}</a>
                    </li>
                    <li>
                        <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.media.tel}`}>{CONTACT_LINKS.media.tel}</a>
                    </li>
                </>
            ),
            en: (
                <>
                    <li>
                        <MailSVG /> <a href={`mailto:${CONTACT_LINKS.media.mail}`}>{CONTACT_LINKS.media.mail}</a>
                    </li>
                    <li>
                        <PhoneSVG /> <a href={`tel:${CONTACT_LINKS.media.tel}`}>{CONTACT_LINKS.media.tel}</a>
                    </li>
                </>
            ),
            cn: "",
        },
        contact_link: "/sign-up-media",
        // contact_link: "",
        // contact_desc: {
        //     ru: "Заявка на аккредитацию",
        //     en: "Media Accreditation",
        //     cn: "",
        // },
        contact_desc: {
            ru: "",
            en: "",
            cn: "",
        },
    },
];
