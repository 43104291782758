import React, {useState} from "react";
import {FormControl, FormHelperText, Grid, GridSize, InputLabel, MenuItem, Select} from "@material-ui/core";
import __translate from "../../../helper/locale/locale";
import {asyncFrontContentByLocale} from "../../../controllers/locale/redux-store/locale/locale";
import {LangEnum} from "../../../controllers/admin-expo/enums";
import {useDispatch} from "react-redux";
import {selectByLang} from "../../FormEvent/FormEvent";
import __locale, {__localeStorage} from "../../../helper/location/location";

type Props = {
    error?: string;
    disabled?: boolean;
    onChange: (event: any) => void;
    value: any;
    value_index?: boolean;
    required?: boolean;
    multiple?: boolean;
    xs?: GridSize;
    name: string;
    label?: string;
    key_part: string;
    placeholder?: string;
};

const TranslatesByKeySelect: React.FC<Props> = ({
                                                    error = "",
                                                    disabled = false,
                                                    multiple = false,
                                                    onChange,
                                                    xs = 12,
                                                    name,
                                                    value,
                                                    key_part,
                                                    value_index = false,
                                                    required = false,
                                                    label = null,
                                                    placeholder = null,
                                                }) => {
    const reduxDispatch = useDispatch();
    const lang = __localeStorage();
    const [items, setItems] = React.useState([]);
    const [load, setLoad] = useState(false);
    const [edited, setEdited] = useState(undefined as any);
    React.useEffect(() => {
        fetchPage();
    }, []);

    const fetchPage = () => {
        setLoad(true);
        let l = localStorage.getItem(name +'_select_list');
        if (l) setItems(JSON.parse(l));
        else reduxDispatch(
            asyncFrontContentByLocale(
                LangEnum.RU,
                (response: any) => {
                    setLoad(false);
                    if (response.result) {
                        setItems(response.data || []);
                        if (response.data) localStorage.setItem(name+'_select_list', JSON.stringify(response.data))
                    }
                },
                1,
                {key_part, per_page:-1},
            ),
        );
    };
    if (!load && (!items || items.length == 0)) return <></>;
    const err = !!error || edited !== undefined && (required && !value);
    return (
        <Grid item xs={xs}>
            <FormControl error={err} fullWidth variant="outlined">
                {label && <InputLabel id="translates-select" required={required}>
                    {label}
                </InputLabel> || ''}
                <Select
                    id="translates-select"
                    name={name}
                    disabled={disabled}
                    multiple={multiple}
                    placeholder={placeholder || label}
                    value={!multiple ? value : (Array.isArray(value) ? value : [value].filter(v => !!v))}
                    onClick={() => {setEdited(new Date().getTime());}}
                    onChange={onChange}>
                    {(items || []).map(item => {
                        const disp = selectByLang(item.translates, lang as LangEnum, "");
                        const v = value_index ? item.key.replace(key_part, '') : disp;
                        return (<MenuItem value={v} key={v}>{disp}</MenuItem>);
                    })}
                </Select>
                {err && <FormHelperText error>{error || __translate('profile.required_field')}</FormHelperText>}
            </FormControl>
        </Grid>
    );
};

export default TranslatesByKeySelect;
