import React from "react";
import {Dialog, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import __translate from "../../../../helper/locale/locale";
import KdwGreenButton from "../../../../components/Buttons/KdwGreenButton";
import {KDW_YEAR} from "../../../../constants/constants";

const styles = makeStyles(theme => ({
    root: {
        background: "rgba(244, 244, 244, 0.8)",
        backdropFilter: "blur(2px)",
    },
    paper: {
        alignItems: "center",
        background: "#ffffff",
        borderRadius: "21px",
        padding: theme.spacing(4),
    },
    title: {
        '& .MuiTypography-h6' : {
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
            ["-webkit-background-clip"]: "text",
            ["-webkit-text-fill-color"]: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            fontWeight: 700,
            fontSize: "32px !important",
            lineHeight: "35px !important",
            fontStyle: "normal"
        },
    },
    content: {
        transition: "all 0.5s ease",
        marginLeft: 260,
        [theme.breakpoints.down(958)]: {
            marginLeft: 0,
            padding: "0 1em",
        },
    },
    btn: {
        width: 250,
    }
}));

function RequestSuccessDialog(props: any) {
    const {onClose} = props;

    const classes = styles();
    const infoKey = "signup.info_role" + props.role;
    const info = __translate(infoKey,"");
    return (
        <>
            <Dialog
                classes={classes}
                open={true}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}>
                <DialogTitle id="form-dialog-title" className={classes.title}>{__translate("signup.success", "Успешно!")}</DialogTitle>
                <DialogContent style={{width: "95%", padding: "4px"}}>
                    <p>{__translate("signup.dialog_title","Благодарим Вас за регистрацию на Форум Kazan Digital Weeek - " + KDW_YEAR + " !")}</p>
                    <p>{__translate("signup.text_role" + props.role,"Ваша заявка в роли Участника успешно принята.")}</p>
                    {info && infoKey !== info && <p>{info}</p>}
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px"}}>
                        <KdwGreenButton
                            className={classes.btn}
                            label={__translate("system.ok", "Хорошо")}
                            onClick={() => {onClose && onClose();}}/>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default RequestSuccessDialog;
