import { Button } from "@material-ui/core";
import React, { useState } from "react";
import __translate from "helper/locale/locale";
import KdwGreenButton from "../Buttons/KdwGreenButton";

type Props = {
    handleFile?: (arg0: any) => void;
    setRef?: (arg0: React.MutableRefObject<any>) => void;
    disabled?: boolean;
    green?: boolean;
    accept: string;
    name?: string;
    label?: string;
    value: any;
    inputRef?: any;
    hideActions?: boolean;
};

const FileUploadButton = (props: Props) => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event: any) => {
        hiddenFileInput.current.click();
    };
    if( props.inputRef) props.inputRef.current = handleClick;

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };

    const onDeleteFile = () => {
        hiddenFileInput.current.value = null;
        props.handleFile(null);
    };
    props.setRef && props.setRef(props.value ? null : hiddenFileInput);
    const Btn = (props2:any) => !props.green ? <Button {...props2}/> : <KdwGreenButton {...props2}
                                                                                       containerProps={{}}
                                                                                       size="small"/>
    return (
        <>
            {!props.hideActions &&
                (props.value ? (
                    <Btn
                        className="p-button-outlined p-button-sm"
                        onClick={onDeleteFile}
                        disabled={props?.disabled}
                        label={__translate("system.remove", "Удалить")}>
                        {__translate("system.remove", "Удалить")}
                    </Btn>
                ) : (
                    <Btn
                        className="p-button-outlined p-button-sm"
                        onClick={() => handleClick(null)}
                        disabled={props?.disabled}
                        label={props.label || __translate("system.upload", "Загрузить")}>
                        {props.label || __translate("system.upload", "Загрузить")}
                    </Btn>
                ))}
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                accept={props.accept}
                name={props.name}
            />
        </>
    );
};

export default FileUploadButton;
