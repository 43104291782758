import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import {
    apiCheckCredential, apiCheckInn,
    apiRouteRegistration,
    DataExampleRegistration,
    TypeRequestRegistration,
} from "controllers/registration-route/api/registration/registration";
import { onClear as OnInvoiceClear } from "controllers/invoice-route/redux-store/pay-invoice/pay-invoice";
import { Color } from "@material-ui/lab/Alert";
import { RegistrationAcademicDegree, RegistrationRole } from "constants/constants";
import __locale from "../../../../helper/location/location";
import {onResponseSuccess as loginSuccess} from "../../../token/redux-store/token/token"

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: {
            html: string;
            order: {
                amount: number;
                created_at: number;
                id: number;
                online: string;
                role: string;
                updated_at: number;
                user: null;
                user_id: number;
            };
        } | null;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const FILE_STORAGE: any = {
    annotation_file: null,
    photo: null,
    doc_photo: null,
};

let cond = () => ({
    inProgress: false,
    notice: {
        visibility: false,
        severity: "error" as Color,
    },
    response: {
        result: false,
        data: null as any,
        errors: null as any,
        message: "" as any,
    },
});
export const slicerRegistration = createSlice({
    name: "registration",
    initialState: {
        view: {
            ...DataExampleRegistration,
        } as TypeRequestRegistration,
        middleView: {
            student: false,
        },
        middleware: {
            country: null as any,
            region: null as any,
            city: null as any,
        },
        condition: cond() as Response,
        conditionEmail: cond() as Response,
    },
    reducers: {
        update: (state, action) => {
            state.view = action.payload;
        },
        onRequest: state => {
            state.condition = {
                ...cond(),
                inProgress: true,
            };
        },
        onClear: state => {
            state.view = {
                ...DataExampleRegistration,
            };
        },
        onConditionClear: state => {
            state.condition = {...cond()};
        },
        onResponse: (state, action) => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "error",
                },
                inProgress: false,
                response: action.payload,
            };
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message:
                        ({
                            ru: "Ошибка отправки данных",
                            en: "Data sending error",
                            cn: "數據發送錯誤",
                        } as any)[__locale()] || "No Response",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (
            state,
            action: PayloadAction<{ message: string; errors: Record<string, Array<string>> }>,
        ) => {
            let message = action.payload.message;
            if (action.payload.errors) {
                let e = Object.values(action.payload.errors)[0];
                if (e?.[0]) message = e[0];
            }
            state.condition = {
                inProgress: false,
                response: {
                    data: null,
                    errors: action.payload.errors,
                    result: false,
                    message
                },
                notice: {
                    severity: "error",
                    visibility: !!message,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                message: string;
                result: boolean;
                data: {
                    html: string;
                    order: {
                        amount: number;
                        created_at: number;
                        id: number;
                        online: string;
                        role: string;
                        updated_at: number;
                        user: null;
                        user_id: number;
                    };
                };
            }>,
        ) => {
            // state.view = {
            //     ...DataExampleRegistration,
            // };
            state.middleView.student = false;
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: true,
                    severity: "success",
                },
                response: {
                    result: true,
                    data: action.payload.data,
                    errors: null,
                    message: action.payload.message,
                },
            };
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref =
                nameRoute.length === 0
                    ? state.view
                    : nameRoute.reduce((acc: any, item: string) => {
                          // @ts-ignore
                          return acc[item];
                      }, state.view);

            if (action.payload?.checked !== undefined) {
                ref[fieldName] = action.payload.checked;
            } else {
                if (fieldName.indexOf("file") >= 0 || fieldName.indexOf("photo") >= 0 ) {
                    if (fieldName === "report_annotation_file") {
                        if (action.payload.files) {
                            ref[fieldName] = action.payload.files[0].name;
                        } else {
                            ref[fieldName] = "";
                        }
                        FILE_STORAGE.annotation_file = action.payload.files;
                        delete state.condition.response?.errors?.["report.annotation_file"];
                    }

                    if (fieldName.indexOf("photo") >= 0) {
                        if (action.payload.value) {
                            ref[fieldName] = action.payload.value;
                        } else {
                            ref[fieldName] = "";
                        }
                        FILE_STORAGE[fieldName] = action.payload.value;
                    }

                } else {
                    if (fieldName === "role") {
                        state.middleView.student = Number(action.payload.value) === RegistrationRole.STUDENT;
                        state.view.academic_degree = "";
                        state.view.academic_degree_other = "";
                        state.view.report_annotation_file = "";
                        state.view.report_forum_theme = "";
                        state.view.report_title = "";
                    }
                    if (fieldName === "academic_degree") {
                        ref["academic_degree"] = action.payload.value;
                        if (Number(action.payload.value) !== RegistrationAcademicDegree.OTHER) {
                            ref["academic_degree_other"] = "";
                        }
                    }
                    if (fieldName === "country") {
                        state.view.city = "";
                        state.view.region = "";
                    }
                    if (fieldName === "region") {
                        state.view.city = "";
                    }
                    if (fieldName === "birthday") {
                        ref[fieldName] = action.payload.value.includes(" ")
                            ? action.payload.value.substring(0, action.payload.value.indexOf(" ") + 1)
                            : action.payload.value;
                    }
                    if (fieldName === "day" || fieldName === "month" || fieldName === "month") {
                        ref[fieldName] = action.payload.value;
                        // @ts-ignore
                        if (state.condition.response?.errors?.birthday) {
                            // @ts-ignore
                            delete state.condition.response?.errors?.birthday;
                        }
                    }
                    if (fieldName === "phone") {
                        ref[fieldName] = action.payload.value;
                        if (state.condition.response?.errors?.[fieldName]) {
                            delete state.condition.response?.errors?.[fieldName];
                        }
                    } else {
                        ref[fieldName] = action.payload.value;
                        if (state.condition.response?.errors?.[fieldName]) {
                            delete state.condition.response?.errors?.[fieldName];
                        }
                        if (fieldName === "report_forum_theme") {
                            if (state.condition.response?.errors?.["report.forum_theme"]) {
                                delete state.condition.response?.errors?.["report.forum_theme"];
                            }
                        }
                        if (fieldName === "report_title") {
                            if (state.condition.response?.errors?.["report.title"]) {
                                delete state.condition.response?.errors?.["report.title"];
                            }
                        }
                    }
                }
            }
        },
        onChangeMiddleware: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref =
                nameRoute.length === 0
                    ? state.middleware
                    : nameRoute.reduce((acc: any, item: string) => {
                          // @ts-ignore
                          return acc[item];
                      }, state.middleware);
            ref[fieldName] = action.payload.value;
            if (fieldName === "city" || fieldName === "country" || fieldName === "region") {
                // @ts-ignore
                state.view[fieldName] = action.payload?.value?.name || "";
                if (fieldName === "country") {
                    state.view.region = "";
                    state.view.city = "";
                }
                if (fieldName === "region") {
                    state.view.city = "";
                }
            }
        },
        onMiddleChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref =
                nameRoute.length === 0
                    ? state.middleView
                    : nameRoute.reduce((acc: any, item: string) => {
                          // @ts-ignore
                          return acc[item];
                      }, state.middleView);
            switch (fieldName) {
                case "student": {
                    state.view.work_position = "";
                    state.view.place_of_work = "";
                    state.view.sector = "";
                    break;
                }
            }
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
                if (fieldName === "student") {
                    state.view.role = action.payload.checked
                        ? RegistrationRole.STUDENT
                        : RegistrationRole.PARTICIPANT_ECONOM;
                }
                // @ts-ignore
                delete state.condition.response?.errors?.work_position;
                // @ts-ignore
                delete state.condition.response?.errors?.sector;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
    },
});

export const {
    update,
    onRequest,
    onConditionClear,
    onResponse,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onMiddleChange,
    onChangeMiddleware,
    onNoResponse,
    onNoticeHide,
    onClear,
} = slicerRegistration.actions;

export const asyncRegistration = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(OnInvoiceClear());
    dispatch(onRequest());
    const view = getState().registration.view;
    const qwerty = {};
    const result = await apiRouteRegistration({
        ...view,
        birthday: view.birthday,
        accept_policy: Number(view.accept_policy),
        accept_pers: Number(view.accept_pers),
        subscribe: Number(view.subscribe),
    } as any).catch(e => {
        console.log("e", e);
    });


    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
            if (!result.data?.html) {
                dispatch(loginSuccess(result));
            }
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncCheckCredential = (field:keyof TypeRequestRegistration, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const val = getState().registration.view?.[field as keyof TypeRequestRegistration];
    const result = await apiCheckCredential(val, field).catch(e => {
        console.log("e", e);
    });
    if (!result?.result) {
        dispatch(onResponseFail(result));
    } else {
        dispatch(onResponseSuccess(result));
    }
    callback && callback(result)
};
export const asyncCheckInn = (val:string, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const result = await apiCheckInn(val).catch(e => {
        console.log("e", e);
    });
    if (!result?.result) {
        dispatch(onResponseFail(result));
    } else {
        dispatch(onResponseSuccess(result));
    }
    callback && callback(result)
};

export const reselectRegistration = (state: RootState) => {
    return state.registration.view;
};
export const reselectRegistrationAcceptPolicy = (state: RootState) => {
    return state.registration.view.accept_policy;
};
export const reselectRegistrationMiddle = (state: RootState) => {
    return state.registration.middleView;
};
export const reselectRegistrationMiddleware = (state: RootState) => {
    return state.registration.middleware;
};
export const reselectRegistrationCondition = (state: RootState) => {
    return state.registration.condition;
};

export default slicerRegistration.reducer;
