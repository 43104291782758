import React, {useEffect, useState} from "react";
import Asynchronous from "../../Asynchronous/Asynchronous";
import __translate from "helper/locale/locale";
import { useDispatch, useSelector } from "react-redux";
import {
    asyncGeolocationCitiesBase,
    reselectGeonamesCitiesCondition,
    reselectGeonamesCitiesList,
    onResponseSuccess,
} from "controllers/geolocation-route/redux-store/cities/cities";
import KdwRawTextField from "../Inputs/KdwRawTextField";
import {apiUniversity} from "../../../controllers/registration-route/api/registration/registration";

type Props = {
    error?: string;
    disabled?: boolean;
    required?: boolean;
    errorText?: string;
    onChange: (event: any) => void;
    onSelect: (event: any) => void;
    value: any;
    name?: string;
    country?: string;
    placeholder?: string;
    label?: string;
};
const UniversitySelect: React.FC<Props> = ({
    error = "",
    disabled = false,
    required = false,
    onChange,
    onSelect,
    country = '',
    name = "university",
    placeholder = "",
    label = "",
    value,
}) => {
    const [list, setList] = useState([])
    const dispatch = useDispatch();
    const ref = React.useRef<any>();
    const [univer, setUniver] = useState({name:(value||'')} as any);
    const [load, setLoad] = useState(false);
    useEffect(() => {
        dispatch(onResponseSuccess([]))
    }, [country])
    return (
        <Asynchronous
            name={name}
            value={value}
            label={label}
            placeholder={placeholder || label}
            isLoading={load}
            options={list}
            required={required}
            error={error}
            disabled={disabled}
            onOptionClick={(name, value) => {
                setUniver(value);
                onSelect({ name, value })
            }}
            onChange={e => {
                onChange(e);
                setUniver(null);
                if ((e.target.value || "") === "") return;
                if (ref.current) clearTimeout(ref.current);
                setLoad(true);
                ref.current = setTimeout(
                    async () => {
                        // const result = await apiUniversity(country, e.target.value)
                        // setList((result?.response?.data || []).map((v:any) => ({name:v.title})))
                        setLoad(false)
                    },
                    350);
            }}
        />
    );
};
export default UniversitySelect;
