import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import TypographyTranslate from "../TypographyTranslate/TypographyTranslate";
import {ReactComponent as ArrowRight} from "../../components/appicons/arrow-right.svg";
import {ReactComponent as Pencil} from "../../components/appicons/pencil-underline.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        roleCard: {
            flex: "1 1 220px",
            flexDirection: "row",
            padding: "20px",
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "20px",
            color: "#6F6F6F",
            overflow:"hidden",

        },

        imageContainer: {
            position:"absolute",
            top: "0",
            right: "20px",
            height: "100%",
            display:"flex",
            alignItems: "center",
            justifyContent:"flex-end",
        },
        title: {
            '& span': {
                fontSize:"16px",
            }
        },
        editTitle: {
            '& span': {
                cursor: "pointer",
            },
            marginTop:20,
            gap: 10,
            display: "flex",
            alignItems: "center",
            width: "100%",
            background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
            ["-webkit-background-clip"]: "text",
            ["-webkit-text-fill-color"]: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            fontWeight: 400,
        }
    }),
);

const RoleEditCard = ({suffix = "", ...props}) => {
    const classes = useStyles();
    const className = classes.roleCard;
    const enabled = true;
    return (
        <div className={className}>
            {(!suffix) && <Grid container className={classes.imageContainer}>
                <Pencil/>
            </Grid>}
            <Grid container style={{position:"relative"}} onClick={() => enabled && props?.onClick &&  props?.onClick()}>
                <div  className={classes.title}><TypographyTranslate value={"participation.can_edit_role" + (suffix || "")} ruVal="Вы можете отредактировать поданную вами заявку"/></div>
                {enabled && <div  className={classes.title + ' ' + classes.editTitle}>
                    <TypographyTranslate value={"participation.edit_role"+(suffix || "")} ruVal="Изменить формат участия"/>
                    <ArrowRight/>
                </div>}

            </Grid>
        </div>
    );
};
export default RoleEditCard
