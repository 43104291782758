import React, {useState} from "react";
import __translate from "helper/locale/locale";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import ForumThemesSelect from "components/Form/SelectFields/ForumThemesSelect";
import KdwCheckBox from "components/Form/Inputs/KdwCheckBox";
import {PARTICIPATION_STATE, RegistrationRole} from "constants/constants";
import {Grid} from "@material-ui/core";
import TranslatesByKeySelect from "./SelectFields/TranslatesByKeySelect";
import EnumSelect from "./SelectFields/EnumSelect";
import UniversitySelect from "./SelectFields/UniversitySelect";
import {useSelector} from "react-redux";
import {reselectConstants} from "../../controllers/constants-route/redux-store/constants/constants";
import __locale from "../../helper/location/location";
import {LangEnum} from "../../controllers/admin-speaker/enums";

type Props = {
    profile: (event?: any) => any;
    validator: (event: any) => any;
    onChange: (event: any) => any;
    role?: any;
    row?: boolean;
    disabled?: boolean;
    en_fields?: boolean;
};
const WorkInfoForm: React.FC<Props> = ({
                                           validator, profile, onChange, role = 0, row = true,
                                           disabled = false, en_fields = false
                                       }) => {

    const Component = (row ? KdwRawTextField : Grid) as any;
    let work = `${profile('work_type')}`;
    const lists = useSelector(reselectConstants)
    const needWork = ((profile('role') | profile('roles'))&RegistrationRole.MEDIA)>0
    return (
        <>
        {!needWork && <Component
                label={__translate("signup.work_type", "Трудовая деятельность")}
                disabled={disabled}
                required
            >
                <TranslatesByKeySelect
                    placeholder={__translate("signup.work_type", "Трудовая деятельность")}
                    onChange={(e) => {
                        onChange({
                            changes: [
                                e.target,
                                {name: 'place_of_work', value: ''},
                                {name: 'work_position', value: ''}
                            ]
                        });
                    }}
                    value={profile('work_type')}
                    name="work_type"
                    error={validator('work_type')}
                    key_part="work_type_"
                    value_index={true}
                    required={true}
                />
            </Component>}
            {(work == '2' || needWork) && <>
                <Component
                    label={__translate("signup.org_type", "Организационно-правовая форма")}
                    disabled={disabled}
                    required={true}
                >
                    <EnumSelect
                        name="org_type"
                        placeholder={__translate("signup.org_type", "Организационно-правовая форма")}
                        error={validator("org_type")||!profile('org_type')}
                        onChange={onChange}
                        value={profile('org_type')}
                        multiple={false}
                        enums={lists.org_types}
                        indexValue={false}
                    />
                </Component>
                <Component
                    required={true}
                    name="place_of_work"
                    label={work == '2'
                        ? __translate("signup.place_of_work", "Место работы")
                        : __translate("signup.place_of_education", "Учебное заведение")}
                    validator={validator}
                    onChange={onChange}
                    value={profile('place_of_work')}
                />
                {en_fields && __locale() !== LangEnum.EN && (<Component
                        required
                        validator={validator}
                        onChange={onChange}
                        disabled={disabled}
                        name="place_of_work_en"
                        label={__translate("signup.place_of_work_en", "Место работы на английском")}
                        value={profile("place_of_work_en")}
                    />
                )}
                <Component
                    required={true}
                    name="work_position"
                    label={__translate("signup.work_position", "Должность")}
                    validator={validator}
                    onChange={onChange}
                    value={profile('work_position')}
                />
                {en_fields && __locale() !== LangEnum.EN && (
                    <>
                        <Component
                            required
                            validator={validator}
                            onChange={onChange}
                            name="work_position_en"
                            disabled={disabled}
                            label={__translate("signup.work_position_en", "Должность на английском")}
                            value={profile("work_position_en")}
                        />
                    </>
                )}
            </>}
            {(work == '1') &&
                <Component
                    label={__translate("signup.place_of_education", "Учебное заведение")}
                    disabled={disabled}
                    required={true}
                >
                    <UniversitySelect
                        required={true}
                        name="place_of_work"
                        placeholder={__translate("signup.place_of_education", "Учебное заведение")}
                        error={validator("place_of_work") || !profile("place_of_work") && " "}
                        onChange={onChange}
                        onSelect={target => {
                            target.value = target?.value?.name || target?.value
                            onChange({target})

                        }}
                        value={profile('place_of_work')}
                    />
                </Component>}
        </>
    );
};

export default WorkInfoForm;
