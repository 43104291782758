import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, GridSize } from "@material-ui/core";
import { useStyles } from "pages/KdwUserPage";

type Props = {
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
    errorText?: string;
    onChange: (event: any) => void;
    value: any;
    xs?: GridSize;
    name: string;
    label?: React.ReactNode;
    labelText: string;
};
const KdwCheckBox: React.FC<Props> = ({
    label = null,
    required = false,
    errorText = "",
    disabled = false,
    onChange,
    xs = 12,
    value,
    name,
    labelText,
}) => {
    const classes = useStyles();
    return (
        <>
            <Grid item xs={xs}>
                <FormControl required={required} error={!!errorText} component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={value}
                                    onChange={onChange}
                                    disabled={disabled}
                                    name={name}
                                />
                            }
                            label={
                                label || (
                                    <React.Fragment>
                                        <span>{labelText}{required && '*'}</span>
                                    </React.Fragment>
                                )
                            }
                        />
                    </FormGroup>
                    {!!errorText && <FormHelperText style={{ color: "red" }}>{errorText}</FormHelperText>}
                </FormControl>
            </Grid>
        </>
    );
};

export default KdwCheckBox;
