import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { UserRoutePageParams } from "../../AccountLayout";
import { getFeatures } from "../PersonalData/UserRolesBar";
import __translate from "helper/locale/locale";
import UserPersonalData from "../PersonalData/UserPersonalData";
import { useStyles } from "pages/KdwUserPage";
import { useSelector } from "react-redux";
import { reselect_Profile_entity } from "controllers/profile-route/redux-store/profile/profile";
import { Grid, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RoleEditCard from "components/Cards/RoleEditCard";
import RoleCard from "components/Cards/RoleCard";
import TypographyTranslate from "components/TypographyTranslate/TypographyTranslate";
import { reselectConstants } from "controllers/constants-route/redux-store/constants/constants";
import AddArticleCard from "components/Cards/AddArticleCard";
import { useHistory, useParams } from "react-router-dom";
import { RegistrationRole } from "constants/constants";
import { ReactComponent as Teacher } from "components/appicons/teacher.svg";
import EmailConfirmError from "components/Form/EmailConfirmError";
import BottomBar from "../PersonalData/BottomBar";
import NeedEmailConfirm from "pages/Account/pages/PersonalData/NeedEmailConfirmDialog";
import KdwGreenButton from "components/Buttons/KdwGreenButton";
import __locale from "helper/location/location";
import { STARTUP_CHECKED, STARTUP_EDIT, STARTUP_REJECTED } from "components/Form/StartupForm";

export const STATE_ACCEPTED = 4;
export const STATE_ACCEPTED_BY_PROMO = 8;
export const STATE_REQUESTED = 1;
export const STATE_REJECTED = 2;
const DOC_STATE_REJECTED =2;
export function CurrentRoleCard(props:any) {
    const localClasses = makeStyles(t => ({
        "svg" : {
            width: 40,
            height:40,
            marginLeft:-15,
            "& path" : {
                fillOpacity: "1 !important",
                fill: "rgb(238, 238, 238) !important",
            }
        }
    }))();
    const classes = useStyles();
    const dataProfile = useSelector(reselect_Profile_entity);
    const profile = props.user ||dataProfile;
    const lists = useSelector(reselectConstants);
    const current = profile?.participation_data;
    const rejectedSpeaker = ((current?.role & RegistrationRole.SPEAKER) > 0)  && current?.reports_rejected;
    const startup = current?.startup?.status;
    const getState = () => {
        if (false && rejectedSpeaker)
            return <TypographyTranslate value='profile.speaker_reports_rejected' ruVal='Доклады отклонены'/>
        else if (current?.role == RegistrationRole.PARTICIPANT_PREMIUM  && current?.state_1c)
            return <TypographyTranslate value={'bitrix.c21_state_' + current?.state_1c} ruVal='Свяжитесь с поддержкой'/>
        else if (current?.status?.state === STATE_REQUESTED && ((current?.role & RegistrationRole.STARTUP)>0)
            && (startup?.state === STARTUP_REJECTED || startup?.state == STARTUP_EDIT || startup?.state == STARTUP_CHECKED)) {
                return <TypographyTranslate value={'startup.card_state_' + startup.state} ruVal={'Статус стартапа ' + startup.state}/>
        }
        else {
            let s = current?.status?.state || 0;
            if (profile?.doc_state === DOC_STATE_REJECTED) {
                s = STATE_REJECTED;
            }
            return (lists.user.participations?.[s] || '');
        }
    };
    const currentFeatures = (role: number) => {
        // if (rejectedSpeaker) return [__translate('profile.speaker_change_to_user', 'Ваши доклады отклонены и с 19.09 вы станете участником')];
        return getFeatures(role);
    }
    const accepted = current?.status?.state  === STATE_ACCEPTED;
    const color = accepted && (profile as any)?.badge?.color || null;
    const label = accepted && (profile as any)?.badge?.role || null;
    const ds = profile?.doc_state;
    return <RoleCard role={current?.role || props.role} features={currentFeatures} current={current?.role || props.role} hideCheck={true}
                                                             className={"doc" + (ds > 1 ? ds : '')}
                                                             onClick={props.onClick}
                                                             color={color? '#' + color : null}
                                                             {...(!label ? {} : {label})}
                                                             subtitle_child={!current?.articles?.length ? <></> :
                     <Tooltip enterDelay={500} enterNextDelay={500} placement="bottom-start" title={<TypographyTranslate value="participation.with_article" ruVal="Со статьей"/>}>
                         <Teacher className={localClasses.svg}/>
                     </Tooltip>
                 }
                                                             price_child={<></>}
                                                             hover={true}>
        <Grid container style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
            <TypographyTranslate value="participation.state" ruVal="Статус заявки"/>:
            <div className={classes.whiteChip}>
                {getState()}
            </div>
        </Grid>
    </RoleCard>;
}

const ConfirmBar =(params:any) =>  {
    const localClasses = makeStyles(t => ({
        confirmCard: {
            flex: "1 1 220px",
            flexDirection: "row",
            margin: "20 0 !important",
            [t.breakpoints.up(800)]: {
                // maxWidth: "66.66%",
            },
        },
    }))();
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    return <BottomBar accessor={params?.profile} onChange={()=>{}} validator={()=>{}}
               inputChildren={<EmailConfirmError keySuf="_required" hideResend={true}/>} className={localClasses.confirmCard}>
        <KdwGreenButton label=""
                        buttonProps={{style:{marginLeft:-10}}}
                        onClick={() => setOpenEmailDialog(true)}
        ><TypographyTranslate value="profile.confirm_email" ruVal="Подтвердить"/></KdwGreenButton>
        {openEmailDialog && <NeedEmailConfirm
            sendOpen={true}
            onClose={() => setOpenEmailDialog(false)}
            onConfirmed={(confirmed:Boolean) => {setOpenEmailDialog(false)}}/>}
    </BottomBar>;
}

const CurrentRolePage = (props: UserRoutePageParams) => {
    const classes = useStyles();
    const history = useHistory();
    const dataProfile = useSelector(reselect_Profile_entity);
    const routeParams = useParams<any>();
    const pd = dataProfile?.participation_data;
    const prem = RegistrationRole.PARTICIPANT_PREMIUM;
    const editForm = '/user/participations';
    return (
        <UserPersonalData {...props}>{
            ({...params}: any) => {
                if (!pd?.status?.state && window.location.pathname !== (editForm)
                    && dataProfile?.user_type === 'user'){
                    history.push(editForm+'?next=1')
                }
                return (<Container className={classes.content}>
                    <div className={classes.grayBlockTitle}>
                        <span>{__translate("profile.participation_requests", "Мои заявки")}</span></div>
                    <Grid container style={{gap: "20px", margin: "20px 0"}}>
                        <CurrentRoleCard onClick={()=> history.push(editForm +'?next=2')}/>
                        <RoleEditCard onClick={() => history.push(editForm)}/>
                    </Grid>
                    <Grid container sm={12} spacing={0}>
                        {(params?.role & prem) > 0
                            && pd?.status?.state == STATE_ACCEPTED
                            && (() => {
                                return <RoleEditCard
                                    suffix={(!pd?.reports?.length) ? '.add_report' : '.show_report'}
                                    onClick={() => history.push(  '/user/'
                                        + ((!pd?.reports?.length) ? 'participations?speaker=1&role=' + prem : 'speaker-data'))}
                                />
                            })()}
                    </Grid>
                    {!params?.profile()?.email_confirmed_at && <ConfirmBar/>}
                    <AddArticleCard/>
                </Container>)
            }}
        </UserPersonalData>
    );
};
export default CurrentRolePage;
