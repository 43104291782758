import React from "react";
import KdwRawTextField from "../../Form/Inputs/KdwRawTextField";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";
import FormWithDirtyValidator from "../../Form/FormWithDirtyValidator";

function ExponentCompanyUserForm(props:any)  {
    return <FormWithDirtyValidator {...props}>
        {({...params}:any) => {
            const {inputProps, item} = params;
            return <>
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="signup.surname" ruVal="Фамилия:"/>}
                                 name="surname"
                                 value={item?.surname || ""}
                />
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="signup.name" ruVal="Имя:"/>}
                                 name="name"
                                 value={item?.name || ""}
                />
                <KdwRawTextField {...inputProps}
                                 labelChildren={<TypographyTranslate value="signup.lastname" ruVal="Отчество:"/>}
                                 name="lastname"
                                 value={item?.lastname || ""}
                />
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="signup.email" ruVal="Email:"/>}
                                 name="email"
                                 value={item?.email || ""}
                />
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="signup.phone" ruVal="Телефон:"/>}
                                 name="phone"
                                 value={item?.phone || ""}
                />
                <KdwRawTextField {...inputProps}
                                 required={true}
                                 labelChildren={<TypographyTranslate value="signup.work_position"
                                                                     ruVal="Должность:"/>}
                                 name="work_position"
                                 value={item?.work_position || ""}
                />
            </>;
        }}
    </FormWithDirtyValidator>;
}
export default ExponentCompanyUserForm