import {FormHelperText, Grid, IconButton} from "@material-ui/core";
import UDFileUploadButton from "components/FIleUploadButton/input";
import React, {useState} from "react";
import {PhotoForFileInputImg, PhotoForFileInputImg170} from "./styles";
import ReactCrop, {Crop} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ButtonDialog from "../../Buttons/ButtonDialog";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getFileFromUrl} from "../../../controllers/profile-route-post/api/profile-post/profile-post";
import __translate from "../../../helper/locale/locale";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";

type Props = {
    value: any;
    label?: string,
    onChange: (e: any, name?:string) => void;
    onImageDelete?: () => void;
    disabled?: boolean;
    cropOff?: boolean;
    name?: string;
    def?: any;
    aspect?: number;
    validator?: (field:string) => any;
    setRef?: (arg0: React.MutableRefObject<any>) => void;
};
const styles = makeStyles((t) => ({
    editButton: {
        width: 170,
        display: "flex",
        justifyContent:"center",
        marginLeft:20,
    },
    imgContainer: {
        marginLeft:20,
        '& img': {
            borderRadius: "50%",
            overflow:"hidden",
        },
        '& button': {
            right: "20px",
            top: "-5px",
        }
    },
    crop: {
        position: "relative",
        '& .ReactCrop__crop-selection' :{
            borderWidth:"2px",
        },
        '& .ReactCrop__crop-selection::after' : {
            display: "block",
            border:"2px solid white",
            borderRadius: '50%',
            content:"''",
            position:'absolute',
            width: "100%",
            height: "100%",
            top:0,
            ["-webkit-box-shadow"]: "0 0 0 9999em rgba(0,0,0,.5)",
            boxShadow:" 0 0 0 9999em rgba(0,0,0,.5)"
        }
    }
}));
const FILE_MAX_KB_SIZE = 10;
const CropImageUploadButton = (props: Props) => {
    const { value, disabled = false, aspect, def, validator = (field:string) => '' } = props;
    const onChange = (file:any) => props.onChange(file, props.name || "photo");
    const classes = styles();
    const defCrop = {
        unit: "px", // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: 120,
        height: 120/aspect,
    } as Crop;
    const [crop, setCrop] = React.useState<Crop>(defCrop);
    const imRef = React.useRef(new Image());
    const dialogBtnRef = React.useRef<any>();
    const inputRef = React.useRef<any>();
    const [imgAspect, setimgAspect] = useState(1);
    const changeCrop = async (cr: Crop, save: boolean = false) => {
        setCrop(cr);
        if (!imRef.current || !save) return;
        setFirst(false);
        const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
        ctx.imageSmoothingQuality = "high";
        const scaleX = imRef.current.naturalWidth / imRef.current.width;
        const scaleY = imRef.current.naturalHeight / imRef.current.height;
        const pixelRatio = window.devicePixelRatio;
        canvas.width = Math.floor(cr.width * scaleX * pixelRatio);
        canvas.height = Math.floor(cr.height * scaleY * pixelRatio);
        ctx.scale(pixelRatio, pixelRatio);
        canvas.width = cr.width * scaleX;
        canvas.height = cr.height * scaleY;
        ctx.save();

        ctx.drawImage(
            imRef.current,
            cr.x * scaleY,
            cr.y * scaleY,
            imRef.current.naturalWidth,
            imRef.current.naturalHeight,
            0,
            0,
            imRef.current.naturalWidth,
            imRef.current.naturalHeight,
        );
        ctx.restore();
        const img = canvas.toDataURL("image/png");
        onChange(img);
        getFileFromUrl(img, "df").then((r:any) => {
            if (r?.size <= (FILE_MAX_KB_SIZE * 1024)) {
                setErrors({...errors, 'photo': __translate("profile.photo_min_size"),})
            } else {
                setErrors({...errors, 'photo': null,})
            }
        }).catch((e) => console.log({e}))
    };
    const [openCropDialog, setOpenCropDialog] = React.useState(false);
    const [first, setFirst] = React.useState(false);
    const [errors, setErrors] = useState({} as any);

    const imageClick = () => {
        if (disabled) return;
        if (!value && def) {
            inputRef.current&& inputRef.current()
        }
        else !props.cropOff && dialogBtnRef.current?.click();
    }
    return (
        <>
            <div className={classes.editButton}>
                <UDFileUploadButton
                    disabled={disabled}
                    inputRef={inputRef}
                    label={props?.label}
                    handleFile={v => {
                        if (!value) {setFirst(true);}
                        onChange(v);
                        !props.cropOff && dialogBtnRef.current?.click();
                    }}
                    accept="image/*"
                    name={props.name}
                    hideActions={!!value}
                    value={value}
                    setRef={props.setRef}
                />
            </div>

            {(value||def) && (
                <Grid container alignItems="flex-start" className={classes.imgContainer}>
                    <PhotoForFileInputImg170 src={value||def} onClick={imageClick} />
                    <IconButton
                        size="small"
                        color="secondary"
                        disabled={disabled}
                        onClick={() => {
                            onChange('');
                            setCrop(defCrop);
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            )}

            {(validator("photo") || errors?.photo)&& (
                <FormHelperText error>{(validator("photo") || errors?.photo)}</FormHelperText>
            )}
            <ButtonDialog
                buttonProps={{ style: { display: "none" }, ref: dialogBtnRef }}
                cancelButtonProps={{ onClick: () => {setOpenCropDialog(false); first && changeCrop(crop, first);} }}
                applyButtonChildren={<TypographyTranslate value='system.save' ruVal='Сохранить'/>}
                cancelButtonChildren={<TypographyTranslate value='site.close' ruVal='Закрыть'/>}
                maxWidth={"lg"}
                applyButtonProps={{ onClick: () => changeCrop(crop, true), color: "primary" }}>
                <ReactCrop crop={crop} onChange={cr => changeCrop(cr)} aspect={aspect === undefined ? 3 / 4 : aspect} keepSelection={true} className={classes.crop} >
                    <PhotoForFileInputImg src={value} ref={imRef} />
                </ReactCrop>
            </ButtonDialog>
        </>
    );
};

export default CropImageUploadButton;
