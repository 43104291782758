import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import {apiUser, apiUserDocState, User} from "controllers/admin-users/api/admin-users/admin-users";
import {makeBaseReduxAction, makeClearPaginator, Paginator} from "helper/redux";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerAdminUsers = createSlice({
    name: "admin-users",
    initialState: {
        exporting: false,
        paginator: makeClearPaginator(),
        list: [] as Array<User>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onExport: (state, action) => {
            state.exporting = !!action.payload;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            } else {
                state.condition = {...action.payload, notice: {
                        severity: "error",
                        visibility: true,
                    }};
                console.log(state.condition)
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<User>;
                current_page: number;
                message: string;
                result: boolean;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.current_page,
                first_page_url: action.payload.first_page_url,
                from: action.payload.from,
                last_page: action.payload.last_page,
                last_page_url: action.payload.last_page_url,
                next_page_url: action.payload.next_page_url,
                path: action.payload.path,
                per_page: action.payload.per_page,
                prev_page_url: action.payload.prev_page_url,
                to: action.payload.to,
                total: action.payload.total,
            };
            state.list = action.payload.data;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onExport,
} = slicerAdminUsers.actions;

const baseStateAsync = makeBaseReduxAction((dispatch:any) => {
    dispatch(onRequest());
}, (dispatch, r) => {
    dispatch(onResponseFail(r));
}, (dispatch) => {
    dispatch(onExport(false));
});

export const asyncUser = (
    page: number,
    exportXLS?: boolean,
    callback?: (result: any) => void,
    per_page?: any,
) => async (dispatch: Dispatch, getState: () => RootState) => {
    if (exportXLS) dispatch(onExport(true));
    const __state = getState();
    const filter = { ...__state.filterUsers.filter, per_page: per_page || 5 };
    return baseStateAsync(apiUser, {page, filter, exportXLS}, callback, (dispatch, result) => {
        if (result) {
            dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
        } else {
            dispatch(onNoResponse());
        }
    })(dispatch, getState)
}

export const asyncChangeDoc = (params:any, callback?: (result: any) => void, per_page = 5) => {
    return baseStateAsync(apiUserDocState, params, callback, (dispatch) => {
        dispatch(asyncUser(1, false, ()=>{}, per_page))
    })
}

export const reselectAdminUsers = (state: RootState): Array<User> => {
    return state.adminUsers.list;
};
export const reselectAdminUsersPaginator = (state: RootState): Paginator => {
    return state.adminUsers.paginator;
};
export const reselectAdminUsersCondition = (state: RootState): Response => {
    return state.adminUsers.condition;
};
export const reselectAdminUsersExporting = (state: RootState): boolean => {
    return state.adminUsers.exporting;
};

export default slicerAdminUsers.reducer;
