import React, {useEffect, useState} from "react";
import { Grid, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { useStyles } from "pages/KdwUserPage";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";
import __translate from "../../../helper/locale/locale";

type Props = {
    helperText?: string;
    disabled?: boolean;
    required?: boolean;
    fullWidth?: boolean;
    onChange?: (event: any) => void;
    validator?: (name: string) => any;
    value?: any;
    name?: string;
    label?: string;
    labelChildren?: any;
    type?: string;
    variant?: "outlined" | "standard" | "filled";
    className?: string;
    inputProps?: any;
};
const styles = makeStyles(t => ({
    raw: {
        display: "flex",
        height: "fit-content",
        [t.breakpoints.down(900)]: {
            flexDirection: "column",
        },
    },
    label: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        color: "#6F6F6F",
        "& label": {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "20px",
        },
    },
    cursor: {
        cursor: "pointer !important",
        "& label": {
            cursor: "pointer !important",
        },
    },
}));
export const KdwFormRaw: React.FC<any> = ({ left = null as any, right = null as any }) => {
    const localClasses = styles();
    return (
        <Grid container className={localClasses.raw}>
            <Grid
                item
                md={4}
                lg={3}
                sm={12}
                xs={12}
                className={localClasses.label + " " + (left?.props?.onClick ? localClasses.cursor : "")}>
                {left}
            </Grid>
            <Grid item md={8} lg={9} sm={6} xs={12}>
                {right}
            </Grid>
        </Grid>
    );
};
const KdwRawTextField: React.FC<any> = ({
    helperText = undefined,
    disabled = false,
    required = false,
    fullWidth = true,
    onChange = (e: any) => {},
    name = "date",
    variant = "outlined",
    labelKey = null,
    label = "",
    placeholder = "",
    labelChildren = null,
    value = null,
    type = "text",
    className = undefined,
    validator = (name: string) => false,
    ...props
}) => {
    const [edited, setEdited] = useState(undefined as any);
    const classes = useStyles();
    const lbl = !labelKey ? (label) : __translate(labelKey+ '.' + name);
    const hint = !labelKey ? (placeholder ? placeholder : lbl)
        : __translate(labelKey+ '.' + name + '_hint', __translate(labelKey+ '.' + name));
    const errorText = validator(name);
    const error = !!errorText || edited !== undefined && (required && !value);
    useEffect(() => {
        props.onInputError && props.onInputError({[name]: error}, value)
    }, [error, value]);
    return (
        <KdwFormRaw
            left={
                labelChildren || (
                    <InputLabel>
                        {lbl}
                        {required && "*"}
                    </InputLabel>
                )
            }
            right={
                props.children || (
                    <TextField
                        disabled={disabled}
                        type={type}
                        value={value}
                        name={name}
                        variant={variant}
                        helperText={errorText || helperText}
                        error={error}
                        onChange={(e) => {
                            setEdited(e.target.value);
                            onChange(e);
                        }}
                        autoComplete="off"
                        placeholder={hint}
                        fullWidth={fullWidth}
                        className={classes.input + " " + className}
                        inputProps={props?.inputProps || {}}
                        {...props?.inputProps}
                    />
                )
            }
        />
    );
};
export default KdwRawTextField;
