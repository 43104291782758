import React, {useEffect, useState} from "react";
import __translate from "helper/locale/locale";
import {FormHelperText} from "@material-ui/core";
import DateTextField from "./Inputs/DateTextField";
import Box from "@material-ui/core/Box";
import FileField from "../FIleUploadButton/FileField";
import TranslatesByKeySelect from "./SelectFields/TranslatesByKeySelect";
import KdwRawTextField from "./Inputs/KdwRawTextField";
import __locale from "../../helper/location/location";
import {useValidator} from "../../helper/validation/validator";


function FormWithDirtyValidator(props: any) {

    const [dirty, setDirty] = useState({} as any);
    const [errors, setErrors] = useState({form:false} as any);
    const validator = useValidator([...Object.keys(dirty)], {...props.errors}, (n: string) => props.item?.[n], props.prefix);
    const onChange = (e:any) => {
        let d = {...dirty};
        d[e.target.name] = e.target.value || undefined;
        setDirty(d);
        props.onChange(e);
    };

    useEffect(() => {
        let v = false as any;
        props.onFormError && props.onFormError(v = Object.values(errors).reduce((pv,cv, i) => (pv || cv), false))
    }, [Object.values(errors).filter(v=>!!v).join('') || '',Object.values(dirty).filter(v=>!!v).join('') || '']);

    useEffect(() => {
        setDirty([]);
    }, [JSON.stringify(props.errors)]);

    const inputProps = {validator, onChange, onInputError: (error:any, value:any) => {
        let field = Object.keys(error)[0];
        let isBad = Object.values(error)[0];
        setErrors({...errors, [field]: isBad && !!value ? value : error[field]})
    }};
    return props.children && props.children({...props, inputProps}) || <></>
}

export default FormWithDirtyValidator;
