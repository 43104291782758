import React, {useState} from "react";
import {Button, FormControl, FormHelperText, Grid, GridSize, InputLabel, MenuItem, Select} from "@material-ui/core";
import __translate from "../../../helper/locale/locale";
import { reselect_Constants_ForumThemes } from "../../../controllers/constants-route/redux-store/constants/constants";
import { useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "../../../pages/KdwUserPage";
import KdwGreenButton from "../../Buttons/KdwGreenButton";

type Props = {
    disabled?: boolean;
    errorText?: string;
    onChange: (event: any) => void;
    value: any;
    error?:any;
    xs?: GridSize;
    name: string;
    label?: string;
    placeholder?: string;
    max?: number;
    multiple?: boolean;
    required?: boolean;
    available?: any;
};
const ForumThemesSelect: React.FC<Props> = ({
    errorText = "",
    error = false,
    disabled = false,
    multiple = false,
    required = true,
    onChange,
    xs = 12,
    max = -1,
    name,
    label = __translate('site.forum_theme', 'Направление'),
    placeholder = "",
    value,
    available = [],
}) => {
    const forum_themes = useSelector(reselect_Constants_ForumThemes);
    const v = (Array.isArray(value) ? value : [value])
        .map(v =>  {
            let t = Number.parseInt(v);
            return Number.isInteger(t) ? t : ''
        });
    const val = (multiple ? v : v[0]) as any;
    const classes = useStyles();
    const [show,setShow] = useState(false);
    return (
        <>
            <Grid item xs={xs}>
                <FormControl error={!!errorText} fullWidth variant="outlined">
                    {label && <InputLabel required={required}>
                        {label}
                    </InputLabel>}
                    <Select
                        name={name}
                        multiple={multiple}
                        disabled={disabled}
                        {...(label && {label} || {})}
                        MenuProps={{MenuListProps:{style: multiple ? {paddingBottom: 0} : {}}}}
                        placeholder={placeholder}
                        displayEmpty={multiple}
                        open={show}
                        onOpen={(e) => {
                            setShow(true)
                        }}
                        error={error || !!errorText}
                        onClose={(e) => setShow(false)}
                        renderValue={(value: any) => {
                            if (value === null || (value && value.length == 0))
                                return placeholder || __translate("signup.not_selected", "Не выбранo");
                            if (Array.isArray(value)) return value.map((v: any) => forum_themes?.[v]).join(", ");
                            return forum_themes?.[value];
                        }}
                        value={val??''}
                        onChange={(e) => {
                            if (Array.isArray(e.target.value))
                                e.target.value = e.target.value.filter(v => v !== undefined)
                            onChange(e)
                        }}>
                        {Array.isArray(forum_themes) && forum_themes
                            ?.map((name: string, val: number) => {
                                return (max === -1 || val < max)
                                    && (available.length === 0 || (available.findIndex((w:any) => w === val) >= 0)) ? name : null;
                            })
                            ?.map((name: string, valm: number) => (name && (
                                <MenuItem key={valm} value={valm} style={{paddingLeft:15}}>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={
                                            multiple
                                                ? val.findIndex((v: any) => valm == v) > -1
                                                : valm === Number.parseInt(`${val}`)
                                        }
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ) || ''))?.concat(!multiple ? [] : [
                                <KdwGreenButton
                                    containerProps={{}}
                                    buttonProps={{style:{ margin: "0px", width:"100%", borderRadius:4}}}
                                    className={classes.button}
                                    label={__translate("form.select_close", "Сохранить")}
                                    onClick={() => {
                                        setShow(false)
                                    }}/>
                            ])}?
                    </Select>
                    {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
                </FormControl>
            </Grid>
        </>
    );
};

export default ForumThemesSelect;
