import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {maxBirthday} from "../../../helper/validation/validator";
import {format} from "date-fns";

type Props = {
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    onChange: (event: any) => void;
    value: any;
    name?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
};
const DateTextField: React.FC<Props> = ({
    error = false,
    helperText = "",
    disabled = false,
    onChange,
    name = "date",
    label = "",
    placeholder = null,
    value,
    required,
}) => {
    let date = name == 'birthday' ? maxBirthday() : null;
    const [edited, setEdited] = useState(undefined as any);
    const err = !!error || !!helperText || edited !== undefined && (required && !value);
    return (
        <>
            <TextField
                required={required}
                id={"date" + name}
                name={name}
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={date ? {max: format(date, "yyyy-MM-dd"),} : {}}
                error={err}
                helperText={helperText}
                label={label}
                placeholder={label || placeholder}
                onChange={e => {
                    setEdited(true);
                    let v = e.target.value
                        ?.split("-")
                        .map(v => v.substr(0, 4))
                        .join("-");
                    onChange({ target: { name, value: v } });
                }}
                value={value?.split(" ")[0]}
                variant="outlined"
                fullWidth
                disabled={disabled}
                autoComplete="chrome-off"
            />
        </>
    );
};
export default DateTextField;
