import React, {useState} from "react";
import {InputLabel, makeStyles} from "@material-ui/core";
import KdwRawTextField from "./KdwRawTextField";
import {useSelector} from "react-redux";
import {reselect_Profile_entity} from "controllers/profile-route/redux-store/profile/profile";
import NeedEmailConfirm from "pages/Account/pages/PersonalData/NeedEmailConfirmDialog";
import EmailConfirmError from "../EmailConfirmError";
import Check from "components/appicons/Check";
import KdwTextFieldGreenBottomLabel from "../KdwTextFieldGreenBottomLabel";
import TypographyTranslate from "../../TypographyTranslate/TypographyTranslate";

type Props = {
    error?: string;
    disabled?: boolean;
    required?: boolean;
    fullWidth?: boolean;
    onChange?: (event: any) => void;
    validator?: (name: string) => any;
    value?: any;
    name?: string;
    label?: string;
    type?: string;
    variant?: "outlined" | "standard" | "filled";
    className?: string;
    inputProps?: any;
};
const styles = makeStyles((t) => ({
    error: {
        color: "#FABB06 !important",
        cursor: "pointer"
    },
    success: {},
    email: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: "#FABB06"
        },
    }
}));
const EmailRawTextField: React.FC<Props> = ({...props}) => {
    const localClasses = styles();
    const profile = useSelector(reselect_Profile_entity);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const confirmValidator = (n: string) => {
        return (<EmailConfirmError onSend={(r: any) => setOpenConfirm(r.result)}/>);
    };
    const confirmedEmail = profile.email_confirmed_at && profile?.email === props.value;
    return (
        <>
            <KdwRawTextField {...props}
                             labelChildren={!profile.email_confirmed_at ? (
                                 <InputLabel
                                     className={localClasses.error}>{props.label}{props.required && '*'}
                                 </InputLabel>
                             ) : (
                                 <InputLabel
                                     className={localClasses.success}>{props.label}{props.required && '*'}</InputLabel>
                             )}
                             inputProps={{
                                 className: !profile.email_confirmed_at ? localClasses.email : props.className,
                                 InputProps: {
                                     endAdornment: (
                                         confirmedEmail ? <Check  style={{color: "#008A71"}}/> : null
                                     ),
                                 }
                             }}
                             validator={!profile.email_confirmed_at ? confirmValidator : props.validator}
            />

            {confirmedEmail &&
                <KdwRawTextField value="" name="" label=""><KdwTextFieldGreenBottomLabel><TypographyTranslate value={"profile.email_confirmed"} ruVal="Почта подтверждена."/></KdwTextFieldGreenBottomLabel></KdwRawTextField> }
            {openConfirm && <NeedEmailConfirm onClose={() => {
                setOpenConfirm(false)
            }} role=""/>}
        </>
    );
};
export default EmailRawTextField;
