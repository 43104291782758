import React from "react";
import { Checkbox, FormControl, FormHelperText, Grid, GridSize, InputLabel, MenuItem, Select } from "@material-ui/core";
import __translate from "../../../helper/locale/locale";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "../../../pages/KdwUserPage";

type Props = {
    error?: boolean;
    disabled?: boolean;
    errorText?: string;
    onChange: (event: any) => void;
    value: any;
    xs?: GridSize;
    name: string;
    label?: string;
    placeholder?: string;
    max?: number;
    min?: number;
    multiple?: boolean;
    required?: boolean;
    indexValue?: boolean;
    enums?: any;
    available?: any;
    except?: any;
    binary?: boolean;
};
const EnumSelect: React.FC<Props> = ({
    error = false,
    errorText = "",
    disabled = false,
    multiple = false,
    binary = false,
    indexValue = true,
    required = true,
    onChange,
    xs = 12,
    max = -1,
    min = -1,
    available = [],
    except = [],
    name,
    label = "",
    placeholder = "",
    enums = [],
    value,
}) => {
    let number = null;
    let val = multiple && !binary
        ? (Array.isArray(value)
            ? value.map(v => indexValue ? Number.parseInt(v) : v)
            : [])
        : ((indexValue && Number.isInteger(number = Number.parseInt(value)) ? number : value));
    if (binary && multiple) {
        let t = 1;
        val = [];
        while (t <= value) {
            if ((t & value) == t) val.push(t);
            t*=2;
        }
    }
    const classes = useStyles();
    const keys = Object.keys(enums).map((v) => Number.parseInt(v));
    return (
        <>
            <Grid item xs={xs}>
                <FormControl error={(error||!!errorText)} fullWidth variant="outlined">
                    {label && <InputLabel required={required} id="demo-simple-select-label">
                        {label}
                    </InputLabel>}
                    <Select
                        labelId="demo-simple-select-label-1"
                        id="demo-simple-select-1"
                        name={name}
                        multiple={multiple}
                        disabled={disabled}
                        placeholder={placeholder || label}
                        renderValue={(value: any) => {
                            if (value === "" || val === "" || val === null)
                                return __translate("signup.not_selected", "Не выбранo");
                            if (Array.isArray(value)) return !indexValue ? value : value.map((v: any) => enums[v]).join(", ");
                            return indexValue && enums?.[value] || value;
                        }}
                        value={val}
                        onChange={onChange}>
                        {keys
                            .filter((val: number) => {
                                return (max === -1 || val < max) && (min === -1 || val > min)
                                    && (available.length === 0 || (available.findIndex((w:any) => w === val) >= 0))
                                    && (except.length === 0 || (except.findIndex((w:any) => w === val) == -1));
                            })
                            .map((key: any, i: number) => (
                                <MenuItem key={i} value={indexValue ? key : enums[key]}>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={
                                            multiple
                                                ? val.findIndex((v: any) => (indexValue ? key : enums[key]) == v) > -1
                                                : (indexValue
                                                    ? Number.parseInt((key as any)) === Number.parseInt(`${val}`)
                                                    : val === enums[key])
                                        }
                                    />
                                    <ListItemText primary={enums[key]} />
                                </MenuItem>
                            ))}
                    </Select>
                    {(error||!!errorText) && <FormHelperText>{errorText}</FormHelperText>}
                </FormControl>
            </Grid>
        </>
    );
};

export default EnumSelect;
