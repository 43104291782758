import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/profile
 * @get
 */

export const apiRouteProfilePost = async (data: any) => {
    const formData = new FormData();
    const ft = data.forum_themes;
    Object.keys(data).forEach((k: any) => {
        if (data[k] !== undefined && data[k] !== "undefined") {
            if (k.indexOf('doc_') === 0) formData.append('doc['+k+']', data[k]);
            else formData.append(k, data[k]);
        }
    });
    data.role !== null && data.role !== undefined && formData.append("role", data.role);
    if (data.online === false || data.online === true) formData.append("online", "" + (data.online ? 1 : 0));
    data.birthday && formData.append("birthday", data.birthday.split(" ")?.[0]);
    data.hasOwnProperty("academic_degree") &&
        data.academic_degree !== null &&
        data.academic_degree !== undefined &&
        formData.append("academic_degree", data.academic_degree);
    data.academic_degree_other && formData.append("academic_degree_other", data.academic_degree_other || "");
    data.hasOwnProperty("subscribe") && formData.append("subscribe", "" + (data.subscribe ? 1 : 0));
    data.hasOwnProperty("is_curator") && formData.append("is_curator", "" + (data.is_curator ? 1 : 0));
    data.hasOwnProperty("curator_role") && formData.append("curator_role", "" + (data.curator_role));
    data.hasOwnProperty("forum_themes") &&
    (Array.isArray(ft) ? ft : [ft]).forEach((theme: number) => {
            formData.delete("forum_themes");
            formData.append(`forum_themes[]`, `${theme}`);
        });
    if (data.hasOwnProperty("photo")) {
        const photo = !data.photo ? null : await getFileFromUrl(data?.photo?.file, data?.photo?.name);
        formData.append("photo", photo);
    }
    if (data.hasOwnProperty("doc_photo")) {
        const photo = !data.doc_photo ? null : await getFileFromUrl(data?.doc_photo?.file, data?.doc_photo?.name);
        if (photo) formData.append("doc[photo]", photo);
    }
    if (data.hasOwnProperty("covid_file")) {
        const file = !data.covid_file ? null : await getFileFromUrl(data?.covid_file?.file, data?.covid_file?.name);
        formData.append("covid_file", file);
    }
    if (data.hasOwnProperty("quest")) {
        data.quest.forEach((v:any, i:number) => {
            formData.delete("quest");
            if (v.answers) v.answers.forEach((w:string, j:number) => w.length> 0 && formData.append(`quest[${v.question}][${j}]`, `${w}`));
            else if (v.answers !== undefined) formData.append(`quest[${v.question}]`,'null')
        });
    }

    if (data.reports) {
        data.reports?.[data.reports.length - 1]?.hasOwnProperty("id") &&
            formData.append("report[id]", data.reports?.[data.reports.length - 1].id);
        formData.append("report[title]", data.reports?.[data.reports.length - 1].title);
        formData.append("report[accept]", data.reports?.[data.reports.length - 1].accept);
        formData.append(
            "report[accept_presentation_publish]",
            data.reports?.[data.reports.length - 1].accept_presentation,
        );
        formData.append("report[forum_theme]", data.reports?.[data.reports.length - 1].forum_theme);
        let file = await getFileFromUrl(
            data.reports?.[data.reports.length - 1]?.annotation_file?.file,
            data.reports?.[data.reports.length - 1]?.annotation_file?.name,
        );
        file && formData.append("report[annotation_file]", file);
        file = await getFileFromUrl(
            data.reports?.[data.reports.length - 1]?.presentation_file?.file,
            data.reports?.[data.reports.length - 1]?.presentation_file?.name,
        );
        file && formData.append("report[presentation_file]", file);
    }
    const response = await kyRequest.post(`${BACKEND_HOST}/profile`, {
        timeout: 120000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });

    try {
        return responseCallback(response, false, true);
    } catch (e: any) {
        if (!e.response) e.response = response;
        throw e;
    }
};

export const getFileFromUrl = async (url: string, name: string) => {
    if (url && name) {
        const logoBlob = await fetch(url).then(d => d.blob())
        const logoFile = new File([logoBlob], name);
        return logoFile;
    }
    return "";
};
