import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiPriceGet } from "controllers/admin-price-get/api/admin-price-get/admin-price-get";
import {makeClearPaginator, Paginator} from "helper/redux";

export type Response = {
    inProgress: boolean;
    index: number | string;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};


export const slicerAdminPriceGet = createSlice({
    name: "priceGet",
    initialState: {
        paginator: makeClearPaginator(),
        view: null,
        condition: {
            index: 0,
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onClear: state => {
            state.view = null;
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onConditionClear: state => {
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: (state, action) => {
            state.condition = {
                index: action.payload,
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (
            state,
            action: PayloadAction<string> | PayloadAction<{ data: any; errors: any; message: string; result: boolean }>,
        ) => {
            if (typeof action.payload === "string") {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: action.payload,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            } else {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: `${action.payload.message}`,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            }
        },
        // message: "responses.controllers.participant.show"
        // result: true
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: any;
                message: string;
                result: boolean;
                current_page: number;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.current_page,
                first_page_url: action.payload.first_page_url,
                from: action.payload.from,
                last_page: action.payload.last_page,
                last_page_url: action.payload.last_page_url,
                next_page_url: action.payload.next_page_url,
                path: action.payload.path,
                per_page: action.payload.per_page,
                prev_page_url: action.payload.prev_page_url,
                to: action.payload.to,
                total: action.payload.total,
            };
            state.view = action.payload.data;
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `Price ${state.condition.index}: ${action.payload.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onClear,
} = slicerAdminPriceGet.actions;

export const asyncPriceGet = (data: any, callback?: (result: any) => void) => async (dispatch: any) => {
    // dispatch(onRequest());
    const result = await apiPriceGet(data).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectPriceGet = (state: any): any | null => {
    return state.priceGet.view;
};

export const reselectAdminPricePaginator = (state: RootState): Paginator => {
    return state.priceGet.paginator;
};

export const reselectAdminPriceCondition = (state: RootState): Response => {
    return state.priceGet.condition;
};

export default slicerAdminPriceGet.reducer;
