import React from "react"
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useStyles} from "pages/KdwUserPage";

function DialogCloseButton(props:any) {
    const classes = useStyles();
    return <>

        <IconButton onClick={props.onClick} className={classes.dialogCloseButton}>
            <CloseIcon />
        </IconButton>
    </>
}
export  default DialogCloseButton