// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from "cross-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import __locale from "../../helper/location/location";
import {LangEnum} from "../../controllers/admin-speaker/enums";
import __translate from "../../helper/locale/locale";


type Props = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onOptionClick: (name: string, value: any) => void;
    renderOption?: (option: any) => string;
    renderVal?: (option: any) => string;
    isLoading: boolean;
    options: Array<any>;
    value: any;
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    error?: string;
    info?: string;
    onClose?: any;
    placeholder?: string;
};

const Asynchronous: React.FC<Props> = ({
    onChange,
    onOptionClick,
    isLoading,
    options,
    value,
    label,
    name,
    disabled = false,
    required = false,
    multiple = false,
    error = "",
    info = undefined,
    placeholder = "",
    onClose,
    renderOption = option => option.name || (__locale() === LangEnum.RU ? "Не выбрано" : "No selected"),
    renderVal = val => val,
}) => {
    const [open, setOpen] = React.useState(false);
    return (
        <Autocomplete
            id="asynchronous-city-request"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={(e) => {
                setOpen(false);
                if (onClose) {
                    onClose(e);
                }
            }}
            defaultValue={value === '' ? undefined : value}
            getOptionSelected={(option: any) => {
                return multiple ? value?.findIndex((v: any) => v.id+'' === option?.id+'') !== -1 : (option?.value === value || option.name === value);
            }}
            loadingText={(__translate('async_select_loading', 'Загрузка'))}
            noOptionsText={__translate(name + '_no', __translate('no_option', 'Нет совпадений'))}
            fullWidth={true}
            disabled={disabled}
            multiple={multiple}
            getOptionLabel={renderOption}
            options={options}
            onChange={(e, option) => onOptionClick(name, option)}
            loading={isLoading}
            renderInput={(params: any) => {
                return (
                    <TextField
                        required={required}
                        disabled={disabled}
                        // InputLabelProps={{ ...params.InputLabelProps }}
                        fullWidth={true}
                        onChange={onChange}
                        label={(!placeholder && label) || ""}
                        placeholder={placeholder || label}
                        variant="outlined"
                        error={!!error}
                        helperText={info && !error ? info : error}
                        name={name}
                        inputProps={{
                            ...params.inputProps,
                            value: multiple ? "" : renderVal(value),
                            autocomplete: "chrome-off",
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                );
            }}
        />
    );
};

export default Asynchronous;
