import {BACKEND_HOST, kyRequest, prepareResponse} from "api/api";


export const getCompanyApi = async (params?: any) => {
    let url = `${BACKEND_HOST}/company/${params?.id || ''}`;
    const response = await kyRequest.post(url, {
        json: {...params, ['_method']:"GET"},
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return prepareResponse(response);
}
export const postExpoCompany = async (data: any) => {
    let formdata = new FormData()
    Object.keys({ ...(data.user||{}) }).forEach(key => {
        let v = data.user?.[key];
        formdata.append('user[' + key + ']', v)
    })
    setCompanyParams(data, formdata);
    const response = await kyRequest.post(`${BACKEND_HOST}/company/lead`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body:formdata
    });
    return prepareResponse(response);
}

function setCompanyParams(data: any, formdata: FormData) {
    Object.keys({...data}).forEach(key => {
        let v = undefined;
        switch (key) {
            case 'logo':
            case 'user':
            case 'ogrn':
            case 'expo_place':
                break
            case "name":
            case "short_name":
            case "inn":
            case "kpp":
            case "address":
            default:
                v = data[key];
        }
        if (v !== undefined) formdata.append(key, v as any);
    });
}

export const postCompanyApi = async (data: any,) => {
    let formdata = new FormData()
    setCompanyParams(data, formdata);
    if (data?.id) formdata.append('_method', 'PUT')
    const response = await kyRequest.post(`${BACKEND_HOST}/company/${data.id || ''}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body:formdata
    });
    return prepareResponse(response);
};

export const editPromoApi = async (data:any) => {
    let formdata = new FormData()
    Object.keys(data).forEach(key => {
         formdata.append(key, data[key]);
    });
    const response = await kyRequest.post(`${BACKEND_HOST}/company/${data.id}/promo/change`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body:formdata
    });
    return prepareResponse(response)
}