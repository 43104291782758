import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {TypeRequestRegistration} from "controllers/registration-route/api/registration/registration";
import __translate from "helper/locale/locale";
import VisitsSelect from "components/Form/SelectFields/VisitsSelect";
import TranslatesByKeySelect from "components/Form/SelectFields/TranslatesByKeySelect";
import {useHistory} from "react-router-dom";
import {useValidator} from "helper/validation/validator";
import {useSelector} from "react-redux";
import {reselectRegistrationCondition} from "controllers/registration-route/redux-store/registration-slicer/registration-slicer";
import {VISIT_TYPE_HACKATHON} from "controllers/visits/redux-store/visits/visits";
import KdwCheckBox from "components/Form/Inputs/KdwCheckBox";

type Props = {
    fields: TypeRequestRegistration;
    onChange: any;
    errors?: any;
    validator?: any;
    onRequestRegistration?: any;
};

export const UserFields: React.FC<any> = ({fields, onChange, prefix, condition, disabled,
                                              inputs = ['surname', 'name', 'email', 'phone'], transKey = "signup"}) => {
    const [dirty, setDirty] = useState([]);
    const [errors, setErrors] = useState({} as any);
    useEffect(() => {
        if (!condition?.errors) setErrors({})
        else {
            let e = {} as any;
            Object.keys(condition.errors).forEach((k) => {
                if (k.indexOf(prefix) !== -1) e[k.replace(prefix, '')] = condition.errors[k];
            })
            setErrors(e);
        }
        setDirty([])
    }, [condition.inProgress])

    const validator2 = useValidator(dirty, errors, (n: string) => fields?.[n as keyof typeof fields]);
    const changeWithDirty = (e: any) => {
        dirty.indexOf(e?.target?.name) == -1 && setDirty([...dirty, e?.target?.name]);
        onChange && onChange(e);
    };
    return <>
        <br/>
        {inputs.map((name:string, i:number) => (<Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={disabled}
                id={name + i}
                key={name + i}
                name={name}
                label={__translate(transKey + "." + name)}
                helperText={(!errors[name] || dirty.indexOf(name) === -1) && validator2(name)}
                error={!!((!errors[name] || dirty.indexOf(name) === -1) && validator2(name))}
                value={fields[name]}
                onChange={changeWithDirty}
                variant="outlined"
                fullWidth
                autoComplete="chrome-off"
            />
        </Grid>))}
        {/*<Grid item xs={12} sm={12}>*/}
        {/*    <DateTextField*/}
        {/*        required*/}
        {/*        onChange={changeWithDirty}*/}
        {/*        error={!!validator2("birthday")}*/}
        {/*        helperText={validator2("birthday")}*/}
        {/*        name={"birthday"}*/}
        {/*        value={fields?.birthday}*/}
        {/*        label={__translate("signup.birthday", "Дата рождения")}*/}
        {/*    />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} sm={12}>*/}
        {/*    <TranslatesByKeySelect*/}
        {/*        onChange={(e) => fields['hackathon_role']}*/}
        {/*        value={fields['hackathon_role']}*/}
        {/*        key_part="hackathon_roles"*/}
        {/*        name="hackathon_role"/>*/}
        {/*</Grid>*/}
    </>
}

const HackathonUsers: React.FC<Props> = ({fields, onChange, errors = null, onRequestRegistration = null, validator = null}) => {
    const [users, setUsers] = useState([])
    const [command, setCommand] = useState(false)
    const history = useHistory();
    const hackathon = (history.location?.state as any)?.visit;
    React.useEffect(() => {
        onChange({target: {name: "hackathon_id", value: hackathon?.id}})
        onChange({target: {name: "hackathon_role", value: "Капитан"}})
    }, [hackathon?.id]);
    const onChangeUser = (e: any, i: any) => {
        const tmp = users.map((v2, i2) => i == i2 ? {...v2, [e.target.name]: e.target.value} : v2);
        onChange({target: {name: 'team', value: tmp}})
        return setUsers([...tmp])
    };
    const hid = hackathon?.id;
    const BU_HACKATON_ID = 33;
    const condition = useSelector(reselectRegistrationCondition);
    return (
        <div>
            <Grid container spacing={3}>
                <>
                    <VisitsSelect
                        disabled={true}
                        onChange={onChange}
                        label={__translate("signup.hackathon_title", "Наименование хакатона")}
                        value={fields.hackathon_id || hid}
                        name={"hackathon_id"}
                        type={VISIT_TYPE_HACKATHON}
                        error={validator("hackathon_id")}
                    />

                    <Grid item xs={12} sm={12}>
                        <KdwCheckBox
                            onChange={(e) => setCommand(e.target.checked)}
                            value={command}
                            name="team"
                            labelText={__translate("signhup.no_team", "У меня нет команды, хочу объединиться с другими")}
                        />
                    </Grid>
                    {!command && <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            name="hackathon_team"
                            label={__translate("signup.hackathon_team", "Наименование команды")}
                            helperText={validator("hackathon_team")}
                            error={!!validator("hackathon_team")}
                            value={fields.hackathon_team}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            autoComplete="chrome-off"
                        />
                    </Grid>}
                    {hid === BU_HACKATON_ID && <>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="social_link"
                                // required
                                label={__translate("signup.social_link", "Ссылка на профиль ВК")}
                                helperText={validator("social_link")}
                                error={!!validator("social_link")}
                                value={fields.social_link}
                                onChange={onChange}
                                variant="outlined"
                                fullWidth
                                autoComplete="chrome-off"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                name="hackathon_source"
                                label={__translate("signup.hackathon_source", "Откуда вы о нас узнали")}
                                helperText={validator("hackathon_source")}
                                error={!!validator("hackathon_source")}
                                value={fields.hackathon_source}
                                onChange={onChange}
                                variant="outlined"
                                fullWidth
                                autoComplete="chrome-off"
                            />
                        </Grid>
                    </>}

                    {<TranslatesByKeySelect
                        onChange={onChange}
                        value={fields['hackathon_role']}
                        name="hackathon_role"
                        key_part={"hackathon_roles_" + hid + "_"}
                        label={__translate("signup.hackathon_role_" + hid, "Количество участников")}
                    />}

                    <TranslatesByKeySelect
                        onChange={onChange}
                        value={fields['hackathon_task']}
                        key_part={"hackathon_tasks_" + hid + "_"}
                        label={__translate("signup.hackathon_task_" + hid, "Задача")}
                        name="hackathon_task"/>

                    {<TranslatesByKeySelect
                        onChange={onChange}
                        value={fields['hackathon_tgconnect']}
                        name="hackathon_tgconnect"
                        key_part={"hackathon_tgconnect_" + hid + "_"}
                        label={__translate("signup.hackathon_tgconnect_" + hid, "Присоединился к Telegram-каналу")}
                    />}
                    {!command ? users.map((v, i) => <>
                        {/*{__translate("signup.participant", "Участник") + " " + (i + 2)}*/}
                        {__translate("signup.convoy", "Сопровождающий")}
                        <UserFields fields={v}
                                    condition={condition}
                                    key={'team.' + i}
                                    prefix={'team.' + i + '.'}
                                    onChange={(e: any) => onChangeUser(e, i)}/>
                    </>) : null}
                    {/*{!command && users.length < 4 && <KdwGreenButton onClick={() => setUsers([...users, {}])}*/}
                    {/*                                                 label={__translate("signup.add_hackathon_user", "Добавить участника")}/>}*/}
                    {!command && users.length < 1 &&
                    <KdwCheckBox
                        onChange={() => users.length === 0 && setUsers([...users, {}])}
                        value={users.length > 0}
                        name={"nam"}
                        labelText={__translate("signup.command_with_children", "В комманде есть несовершеннолетние")}
                    />}
                </>

            </Grid>
        </div>
    );
};

export default HackathonUsers;
