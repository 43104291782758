import React, {useState} from "react";
import __translate from "helper/locale/locale";
import KdwRawTextField from "components/Form/Inputs/KdwRawTextField";
import ForumThemesSelect from "components/Form/SelectFields/ForumThemesSelect";
import KdwCheckBox from "components/Form/Inputs/KdwCheckBox";
import {MANAGER_ROLES, PARTICIPATION_STATE, RegistrationRole} from "constants/constants";
import {Grid} from "@material-ui/core";
import EnumSelect from "./SelectFields/EnumSelect";
import {useSelector} from "react-redux";
import {reselectConstants} from "../../controllers/constants-route/redux-store/constants/constants";

type Props = {
    profile: (event?: any) => any;
    validator: (event: any) => any;
    onChange: (event: any) => any;
    role?: any;
    row?: boolean;
    hideCheckBox?: boolean;
};
const FormOrganizatorRole: React.FC<Props> = ({
                                                  validator,
                                                  profile,
                                                  onChange,
                                                  role = 0,
                                                  row = true,
                                                  hideCheckBox = false
                                              }) => {
    const pd = profile()?.participation_data;
    const disabled = pd?.status?.state > PARTICIPATION_STATE.CANCELED &&
        ((pd?.role | role) & RegistrationRole.ORGANIZER) > 0;
    const Component = (row ? KdwRawTextField : Grid) as any;
    const value = profile('is_curator') !== undefined ? profile('is_curator') : profile()?.options?.is_curator;
    const constants = useSelector(reselectConstants);
    return (
        <>
            {!hideCheckBox && <Component
                label={__translate("signup.org_curator", "Ответственный организатор")}>
                <KdwCheckBox
                    name="is_curator"
                    value={value}
                    onChange={onChange}
                    labelText={__translate("signup.is_curator", "Я отвественный куратор",)}
                />
            </Component>}
            {!!value && <>
                <Component>
                    <EnumSelect
                        multiple={false}
                        onChange={onChange}
                        value={profile('curator_role') || MANAGER_ROLES.REPORTS_EDITOR}
                        label={__translate("signup.curator_role")}
                        name={"curator_role"}
                        available={[MANAGER_ROLES.REPORTS_EDITOR, MANAGER_ROLES.STARTUP_EDITOR, MANAGER_ROLES.EXPO_EDITOR]}
                        enums={constants.manager.roles}
                    />
                </Component>
                {(profile('curator_role') !== MANAGER_ROLES.STARTUP_EDITOR) && <Component
                    label={__translate("signup.curator_forum_theme", "Ответственное направление")}>
                    <ForumThemesSelect
                        required={false}
                        value={profile('forum_themes')}
                        onChange={onChange}
                        name="forum_themes"
                        label=""
                        available={[0,1,2,3,4,5,6,7,8,9,12,13,14,16]}
                        max={10}
                        disabled={disabled}
                        multiple={true}
                        placeholder={__translate(`signup.curator_forum_theme`, "Ответсвенное направление")}
                        errorText={validator("forum_theme")}
                    />
                </Component>}

            </>}
        </>
    );
};

export default FormOrganizatorRole;
