import {subMonths} from "date-fns";

export const KDW_YEAR = subMonths(new Date(), 2).getFullYear();
export const KDW_D = 17;
export const KDW_M = 9;
// "Участник", "Докладчик", "СМИ", "Студент"
export const RegistrationRole = {
    PARTICIPANT_ECONOM: 1,
    PARTICIPANT: 2,
    PARTICIPANT_PREMIUM: 4,
    PARTICIPANT_BUSINESS: 8,
    SPEAKER: 16,
    MEDIA: 32,
    STUDENT: 64,
    VOLUNTEER: 128,
    ORGANIZER: 256,
    EXPONENT: 512,
    STARTUP: 1024,
    HACKATHON: 65536,
};
export const MANAGER_ROLES = {
    REPORTS_EDITOR: 0,
    FORUM_THEME_EDITOR: 1,
    USER_ROLE_MANAGER: 2,
    ALL_REPORTS_MANAGER: 4,
    VISIT_USERS_MANAGER: 8,
    MVD_ROLE: 16,
    STARTUP_EDITOR: 32,
    EXPO_EDITOR: 64,
    ARTICLE_MANAGER: 128,
    ALL_ARTICLE_MANAGER: 384,
    SUPPORT: 512,
};
export const PARTICIPATION_STATE = {
    DRAFT: 0,
    REQUESTED: 1,
    CANCELED: 2,
    ACCEPTED: 4,
};
// "Без степени", "Кандидат наук", "Доктор наук"
export const RegistrationAcademicDegree = {
    OTHER: 0,
    NO_DEGREE: 1,
    CANDIDATE: 2,
    DOCTOR: 3,
};

export const themeColors: Array<string> = [
    "#fff79a",
    "#9fd08a",
    "#d4cfa3",
    "#99c0e1",
    "#f7acbb",
    "#a697c8",
    "#6bcff6",
    "#b1cb96",
    "#f9a75f",
    "#55bc7a",
    "#28bebf",
];

export const getColorByThemeIndex = (themeIndex: any, opacity?: string) => {
    if (themeIndex !== null && themeColors[Number(themeIndex)]) {
        if (opacity) {
            return themeColors[themeIndex].replace("0.5", opacity);
        }
        return themeColors[themeIndex];
    }
    return `rgba(255, 255, 255, ${opacity ? opacity : "0"})`;
};

// Company lk link

export const PARENT_COMPANY_LK_LINKS = [
    {key: 'company.company', num: 0, title: 'Компания'},
    {key: 'company.employees', num: 1, title: 'Сотрудники компании'},
    {key: 'company.children', num: 2, title: 'Дочерние компании'},
    {key: 'company.catalog', num: 3, title: 'Онлайн каталог'},
    // {key: 'company_lk-itleader', num: 4, title: 'Ит Лидер'},
]

export const CHILD_COMPANY_LK_LINKS = [
    {key: 'company.company', num: 0, title: 'Компания'},
    {key: 'company.employees', num: 1, title: 'Сотрудники компании'},
    {key: 'company.catalog', num: 3, title: 'Онлайн каталог'},
    // {key: 'company_lk-itleader', num: 4, title: 'Ит Лидер'},
]
